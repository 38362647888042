import api from "./base-api"

export const solitaireStateApiV1 = api.injectEndpoints({
    endpoints: (build) => ({
        getStateV1SC: build.query({
            query: (userId = "") => `v1/solitaire/states/${userId}`
        })
    }),
    overrideExisting: false
})

export const {
    useGetStateV1SCQuery
} = solitaireStateApiV1

const solitaireStateApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getStateV2SC: build.query({
            query: ({deviceId, stateId}) => `v2/solitaire/states/${deviceId}/${stateId}`
        }),
        getStatesByFacebookIdV2SC: build.query({
            query: (facebookId = "") => `v2/solitaire/states/facebook/${facebookId}`
        }),
        createStateV2SC: build.mutation({
            query: (data) => {
                const {userId, game, ...body} = data


                return {
                    url: `v2/solitaire/states/${game}/${userId}`,
                    method: "POST",
                    body: body.body
                }
            },
            invalidatesTags: ['UserStates']
        }),
        advancedSearchSC: build.mutation({
            query: (body = {}) => ({
                url: `v2/solitaire/states/advancedSearch`,
                method: "POST",
                body: body
            }),
        }),
    }),
    overrideExisting: false
})

export const {
    useGetStateV2SCQuery,
    useGetStatesByFacebookIdV2SCQuery,
    useCreateStateV2SCMutation,
    useAdvancedSearchSCMutation
} = solitaireStateApiV2
