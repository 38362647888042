import * as React from 'react'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Box, DialogActions, DialogContent, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

export default function UsersListWithLinksDialog(props) {
    const {onClose, open, data, game} = props;

    const handleClose = () => {
        onClose(true);
    };

    const handleConfirm = (event) => {
        onClose(true)
    };

    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
                <Typography gutterBottom
                            variant="h5"
                            component="div">
                    Список id пользователей
                </Typography>
            </DialogTitle>
            <DialogContent>
                {data.map((item, index) =>
                    <Box>
                        <Link to={`/${game}/${item}/states`}>{item}</Link>
                    </Box>
                )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="warning"
                    onClick={handleConfirm}>
                    {"Ок"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

UsersListWithLinksDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    game: PropTypes.string.isRequired,
};
