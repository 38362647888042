import {combineReducers} from 'redux';
import UserSession from '../reducers//session';
import SourceMode from '../reducers//mode';
import CommonData from '../reducers//commonData';

const reducers = {
    UserSession,
    SourceMode,
    CommonData
};

export default combineReducers({...reducers});
