export default {

    /**
     * Node server host
     */
    host: process.env.NODE_HOST || 'localhost',

    /**
     * Node server port
     */
    port: process.env.PORT || '8080',

    /**
     * Back-end server base url
     */
    apiBaseUrl: 'http://localhost:8080/',

    /**
     * FE api url
     */
    apiFeBaseUrl: 'http://localhost:8080/',

    /**
     * Site base url
     */
    siteBaseUrl: 'http://localhost:3000',

    /**
     * Google recaptcha key
     */
    recaptchaSiteKey: '',

    /**
     * Default error string for backend request without answer
     */
    connectErrorMessage: 'Connect error, try again later or contact the service administrator.',

    /**
     * Default connect timeout, ms
     */
    connectTimeout: 20000,

    /**
     * Default error string for backend request with returned status >= 500
     */
    serverErrorMessage: 'Server error, try again later or contact the service administrator.',

    /**
     * Default meta content
     */
    app: {
        title: '',
        titleTemplate: '%s',
        meta: [
            {
                name: 'description',
                content: ''
            },
            {
                name: 'keywords',
                content: ''
            },
            {
                name: 'theme-color',
                content: '#FFFFFF'
            },
            {
                name: 'apple-mobile-web-app-capable',
                content: 'yes'
            },
            {
                name: 'apple-mobile-web-app-status-bar-style',
                content: 'default'
            }
        ]
    }
};
