import {createContext, useEffect, useState} from "react";
import {Box, Slide, Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";

export const SnackBarContext = createContext()
const AUTO_DISMISS = 3000

export function SnackBarProvider({children}) {
    const [alerts, setAlerts] = useState([])

    const activeAlertIds = alerts.join(',')

    useEffect(() => {
        if (activeAlertIds.length > 0) {
            const timer = setTimeout(() => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)), AUTO_DISMISS)
            return () => clearTimeout(timer)
        }
    }, [activeAlertIds])

    const addAlert = (alert) => setAlerts((alerts) => [alert, ...alerts])
    const value = {addAlert}

    return (
        <SnackBarContext.Provider value={value}>
            {children}
            {alerts.map((alert, index) =>
                <Snackbar
                    open={true} key={index} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                    <Alert variant="filled"  severity={alert.type} >
                        {alert.text}
                    </Alert>
                </Snackbar>)}
        </SnackBarContext.Provider>
    )
}