import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

const typeList = [
    'CONSUMABLE', 'UNLIMITED'
]

const CreateUpdateModal = (props) => {
    return (
        <>
            <DialogTitle>
                {props.charge.id ? "Update charge " + props.charge.name : "Create a new charge"}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} pt={1}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                                fullWidth
                                label="Type"
                                id={'type'}
                                value={props.charge.type ? typeList[typeList.indexOf(props.charge.type.toUpperCase())] : 0}
                                onChange={(e) => props.setCharge({
                                    ...props.charge,
                                    "type": e.target.value
                                })}
                            >
                                {typeList.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            sx={{m: 0}}
                            label="Id"
                            value={props.charge.id}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "id": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            sx={{m: 0}}

                            label="Name"
                            value={props.charge.name}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "name": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.charge.amount}
                                    onChange={(e) => props.setCharge({
                                        ...props.charge,
                                        "amount": e.target.checked
                                    })}
                                    name="Amount"
                                    color="primary"
                                />
                            }
                            label="Amount"
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="contained"
                        onClick={props.handleChargeAction}
                >
                    Save
                </Button>

                <Button variant="outlined"
                        onClick={props.handleCancel}
                >
                    Cancel
                </Button>
            </DialogActions>


        </>
    );
}
export default CreateUpdateModal;