import {useGetPurchaseV1CMQuery} from "../../core/api/clockmaker-analytical-data-api";
import Loading from "../../components/Common/Loading";
import {DataGrid} from "@mui/x-data-grid";

const columns = [

    {
        label: 'timestamp',
        field: 'timestamp',
        width: 200
    },
    {
        label: 'category',
        field: 'category',
        width: 100
    },
    {
        label: 'categoryName',
        field: 'categoryName',
        width: 200
    },
    {
        label: 'source',
        field: 'source',
        width: 100
    },
    {
        label: 'items',
        field: 'items',
        width: 400,
        renderCell: (params) => {
            if(params.row?.items == null) return <></>
            return <>{params.row?.items.split("\\n").map(item => <>{item}, </>)}</>
        },
    },
    {
        label: 'payload',
        field: 'payload',
        width: 200
    },

]

const PurchaseCM = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        error
    } = useGetPurchaseV1CMQuery({userId:props.deviceId,payload:props.payload!=undefined?props.payload:0})

    let payments = ""

    if (typeof data === "object") {

        payments = <DataGrid
            getRowId={(row) => row.id}
            rows={data}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        payments = "no data"
    }

    return (
        <Loading
            isLoading={isLoading||isFetching}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {payments}

        </Loading>
    )
}

export default PurchaseCM