import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog, DialogActions, DialogContent,
    Grid,
    Table,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import Loading from "../../components/Common/Loading";
import SimpleTable from "../../components/Common/Elements";
import {useGetChatCMQuery} from "../../core/api/clockmaker-clans-api";
import {format} from "date-fns";
import DialogTitle from "@mui/material/DialogTitle";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import Editor from "@monaco-editor/react";
import {stringify} from "lossless-json";


const ClanHistory = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetChatCMQuery(props.userId)


    const [detailsDialog, setDetailsDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(false)

    return (
        <Loading
            isLoading={isLoading}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {data!=undefined && data.hasOwnProperty('response') &&
                <Grid container spacing={2}>

                    <Grid item sm={12}>
                        <Card>
                            <CardContent>

                                <Typography sx={{fontSize: 16}}>
                                    History
                                </Typography>

                            </CardContent>
                            <Table>
                                <TableRow>
                                    <TableCell>clanID</TableCell>
                                    <TableCell>type</TableCell>
                                    <TableCell>userID</TableCell>
                                    <TableCell>updatedAt</TableCell>
                                    <TableCell>Custom Data</TableCell>
                                </TableRow>
                                {data.response.history.map(item => (
                                    <TableRow>
                                        <TableCell>{item.clanId}</TableCell>
                                        <TableCell>{item.type}</TableCell>
                                        <TableCell>{item.userId}</TableCell>
                                        <TableCell>
                                            {format(item.updatedAt, "dd.MM.yyyy HH:mm:ss")}
                                        </TableCell>
                                        <TableCell>
                                            <Button variant={"outlined"} onClick={()=>{
                                                setSelectedItem(item)
                                                setDetailsDialog(true)
                                            }}>details</Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                }
                            </Table>
                        </Card>
                    </Grid>
                </Grid>
            }

            {detailsDialog&&<Dialog
                size={'xl'} width={"800px"}
                fullWidth={false}
                maxWidth={"500px"}
                open={detailsDialog}>
                <DialogTitle>
                    Details
                </DialogTitle>
                <DialogContent>

                    <Editor height="300px"
                            width={"600px"}
                            defaultLanguage="json"
                            value={stringify(selectedItem.customData, null, 2)}
                    />
                </DialogContent>
                <DialogActions>

                    <Button
                        color="secondary"
                        onClick={() => {
                            setDetailsDialog(false)
                        }}>
                        Close
                    </Button>


                </DialogActions>
            </Dialog>}
        </Loading>
    )
}

export default ClanHistory