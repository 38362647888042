const configs = {
    "prod": {
        keycloak: {
            "realm": "master",
            "url": "https://auth.ita.belka-games.com/",
            "ssl-required": "none",
            "resource": "hermes",
            "public-client": true,
            "clientId": "hermes"
        },
        baseUrl: "https://hermes-back.ita.belka-games.com/api/"
    },
    "staging": {
        keycloak: {
            "realm": "master",
            "url": "https://auth.ita.belka-games.com/",
            "ssl-required": "none",
            "resource": "hermes-dev",
            "public-client": true,
            "clientId": "hermes-dev"
        },
        baseUrl: "https://hermes2-back.stage.belka-games.com/api/"
    },
    "local": {
        keycloak: {
            "realm": "master",
            "url": "http://localhost:9877/auth/",
            "ssl-required": "none",
            "resource": "hermes-dev",
            "public-client": true,
            "clientId": "hermes-dev"
        },
        baseUrl: "http://localhost:8080/api/"
    }
}


let mode = "staging";
switch (document.location.host) {
    case "hermes2.stage.belka-games.com":
        break;
    case "hermes2.belka-games.com":
        mode = "prod"
        break;
    case "localhost:3000":
    default:
        mode = "local"
        break;
}

const selectedConfig = configs[mode];

export const getEnvironment = () => {
    switch (document.location.host) {
        case "localhost:3000": {
            return "local"
        }
        case "hermes2-back.stage.belka-games.com": {
            return "development"
        }
        default:
            return "production"
    }
}


const exportConfig = {
    ...selectedConfig,
    allConfigs: configs,
};

export default exportConfig;
