import api from "./base-api"

const tohPrefabApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getPrefabV2TOH: build.query({
            query: (id = "") => `v2/toh/prefabs/${id}`,
            providesTags: (result, error, arg) => {
                return result
                    ? [{type: 'PrefabItemTOH', id:result.id}, 'PrefabItemTOH']
                    : ['PrefabItemTOH']
            }
        }),
        getPrefabStringV2TOH: build.query({
            query: (id = "") => `v2/toh/prefabs/${id}/string`,
            providesTags: (result, error, arg) => {
                return result
                    ? [{type: 'PrefabItemTOH', id:result.id}, 'PrefabItemTOH']
                    : ['PrefabItemTOH']
            }
        }),
        getPrefabsListV2TOH: build.query({
            query: (id = "") => `v2/toh/prefabs`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsTOH']
        }),
        getPrefabsInfosV2TOH: build.query({
            query: (id = "") => `v2/toh/prefabs/infos`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsTOH']
        }),
        getPrefabsInfoListV2TOH: build.query({
            query: (id = "") => `v2/toh/prefabs/info`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsTOHInfo']
        }),
        getPrefabsMetaTOH: build.query({
            query: (id = "") => `v2/toh/prefabs/metas`,
            providesTags: ['PrefabsMetasTOH']
        }),
        getPrefabsMeta: build.query({
            query: (game = "") => `v2/${game}/prefabs/metas`,
            providesTags: ['PrefabsMetasTOH']
        }),
        createPrefabV2TOH: build.mutation({
            query: (body = {}) => ({
                url: "v2/toh/prefabs",
                method: "POST",
                body: body.data,
                env: body.env
            }),
            invalidatesTags: ['PrefabsTOH','PrefabsMetasTOH']
        }),
        deletePrefabV2TOH: build.mutation({
            query: (id = "") => ({
                url: `v2/toh/prefabs/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['PrefabsTOH','PrefabsTOHInfo']

        }),
        updatePrefabV2TOH: build.mutation({
            query: (data = {}) => ({
                url: `v2/toh/prefabs/${data.id}`,
                method: "PUT",
                body: data.body
            }),
            invalidatesTags: ['PrefabItemTOH','PrefabsTOHInfo']

        })
    }),
    overrideExisting: false
})

export const {
    useGetPrefabV2TOHQuery,
    useGetPrefabsMetaTOHQuery,
    useGetPrefabsMetaQuery,
    useGetPrefabStringV2TOHQuery,
    useGetPrefabsListV2TOHQuery,
    useGetPrefabsInfoListV2TOHQuery,
    useGetPrefabsInfosV2TOHQuery,
    useCreatePrefabV2TOHMutation,
    useDeletePrefabV2TOHMutation,
    useUpdatePrefabV2TOHMutation
} = tohPrefabApiV2
