import {useGetPaymentsV1CMQuery} from "../../core/api/clockmaker-analytical-data-api";
import Loading from "../../components/Common/Loading";
import {DataGrid} from "@mui/x-data-grid";
import {useGetPaymentsV1TOHQuery} from "../../core/api/toh-analytical-data-api";

const columns = [
    {
        label: 'Currency',
        field: 'currency',
        flex:1,
    },
    {
        label: 'UsdCents',
        field: 'price',
        flex:1,
    },
    {
        label: 'Transaction ID',
        field: 'transactionId',
        flex:1,
    },
    {
        label: 'Transaction Time',
        field: 'transactionTime',
        flex:1,
    },
    {
        label: 'Store ID',
        field: 'storeId',
        flex:1,
    },
    {
        label: 'Category',
        field: 'category',
        flex:1,

    },
    {
        label: 'Item',
        field: 'item',
        flex:1,

    },
]

const PaymentsTOH = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        error
    } = useGetPaymentsV1TOHQuery(props.deviceId)

    let payments = ""

    if (typeof data === "object") {

        payments = <DataGrid
            getRowId={(row) => row.transactionId+row.transactionTime}
            rows={data}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        payments = "no data"
    }

    return (
        <Loading
            isLoading={isLoading||isFetching}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {payments}

        </Loading>
    )
}

export default PaymentsTOH