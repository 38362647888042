import Loading from "../../../components/Common/Loading";
import Prefabs from "../Prefabs";
import {
    useDeletePrefabV2CMMutation,
    useGetPrefabsInfoListV2CMQuery, useGetPrefabsInfosV2CMQuery,
    useUpdatePrefabV2CMMutation
} from "../../../core/api/clockmaker-prefab-api";
import {useCreateStateV2CMMutation} from "../../../core/api/cloclmaker-state-api";

function PrefabsCm(props) {

    const [updateUserState, updateUserStateResponse] = useCreateStateV2CMMutation();
    const [updatePrefab, updatePrefabResponse] = useUpdatePrefabV2CMMutation();
    const [deletePrefab, deletePrefabResponse] = useDeletePrefabV2CMMutation();
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
        isFetching
    } = useGetPrefabsInfoListV2CMQuery();

    return (
        <>
            <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}
            >
                <Prefabs prefabsListReady={isSuccess}
                         prefabsList={data}
                         updateUserState={updateUserState}
                         updateUserStateResponse={updateUserStateResponse}
                         updatePrefab={updatePrefab}
                         updatePrefabResponse={updatePrefabResponse}
                         deletePrefab={deletePrefab}
                         deletePrefabResponse={deletePrefabResponse}
                         refetch={refetch}
                         game={props.game}
                />
            </Loading>

        </>
    );

}

export default PrefabsCm;
