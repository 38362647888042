import {Button, Card, CardContent, CardHeader, Grid, Table, TableCell, TableRow, Typography} from "@mui/material";
import Loading from "../../components/Common/Loading";
import SimpleTable from "../../components/Common/Elements";
import {useGetChatCMQuery} from "../../core/api/clockmaker-clans-api";
import {format} from "date-fns";


const ClanChat = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetChatCMQuery(props.userId)



    return (
        <Loading
            isLoading={isLoading}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {data!=undefined && data.hasOwnProperty('response') &&
                <Grid container spacing={2}>

                    <Grid item sm={12}>
                        <Card>
                            <CardContent>

                                <Typography sx={{fontSize: 16}}>
                                    Chat
                                </Typography>

                            </CardContent>
                            <Table>
                                <TableRow>
                                    <TableCell>userID</TableCell>
                                    <TableCell>updatedAt</TableCell>
                                    <TableCell>Custom Data</TableCell>
                                    <TableCell>Deleted</TableCell>
                                </TableRow>
                                {data.response.history.filter(item => item.type=='TEXT').map(item => (
                                    <TableRow>
                                        <TableCell>{item.userId}</TableCell>
                                        <TableCell>
                                            {format(item.updatedAt, "dd.MM.yyyy HH:mm:ss")}
                                        </TableCell>
                                        <TableCell>
                                            {item.customData.text}
                                        </TableCell>
                                        <TableCell>
                                            {item.deleted==false?'':'deleted'}
                                        </TableCell>
                                    </TableRow>
                                ))
                                }
                            </Table>
                        </Card>
                    </Grid>
                </Grid>
            }
        </Loading>
    )
}

export default ClanChat