import {useGetPrefabV2Query} from "../../../core/api/bermuda-prefab-api";
import PrefabsItem from "../PrefabsItem";

function PrefabsBAItem({selectedItem,setSelectedItem}) {
    return (
        <PrefabsItem
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            useQueryHook={useGetPrefabV2Query} // Pass the specific query hook
        />
    );

}

export default PrefabsBAItem;
