import {Button, IconButton, ListItemButton, Table, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import Loading from "../../components/Common/Loading";

export default function AdvancedSynchroTable({synchroHook, params}) {
    const [advancedSearch, advancedSearchResponse] = synchroHook();

    useEffect(() => {
        advancedSearch(params)
    }, [params])


    return (
        <Loading isLoading={advancedSearchResponse.isLoading} error={advancedSearchResponse.error} isError={advancedSearchResponse.isError}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            #
                        </TableCell>
                        <TableCell>
                            State ID
                        </TableCell>
                        <TableCell>
                            Vendor ID
                        </TableCell>
                        <TableCell>
                            Extra ID
                        </TableCell>
                        <TableCell>
                            Save ID
                        </TableCell>
                        <TableCell>
                            Date of last change
                        </TableCell>
                        <TableCell>
                            Priority
                        </TableCell>

                    </TableRow>

                </TableHead>
                {advancedSearchResponse.data && advancedSearchResponse.data.map((item, index) => {
                    return (
                        <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.stateID}</TableCell>
                            <TableCell>{item.vendorID}</TableCell>
                            <TableCell>{Object.keys(item.extraID).map(key => {
                                    if(item.extraID[key] == '' || item.extraID[key]==null) return false
                                    return <div><b>{key}</b>: {item.extraID[key]}</div>
                                }
                            )}</TableCell>
                            <TableCell>{item.saveID}</TableCell>
                            <TableCell>{item.date}</TableCell>
                            <TableCell>{item.priority}</TableCell>

                        </TableRow>
                    )
                })}
            </Table>
        </Loading>
    )
}