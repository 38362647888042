import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Box, Tab, Tabs,} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import Loading from "../../components/Common/Loading";
import PaymentsCM from "../Payments/cm";
import ConsumablesCM from "../Consumables/cm";
import {stringify, parse} from "lossless-json";
import {getDeviceIdAbsentWarning} from "../../core/utils/constants";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import InAppCM from "../Payments/InAppCM";
import TechInfoCM from "../TechInfo/techInfoCM";
import Levels from "../UserStates/cm/Levels";
import PaymentsTOH from "../Payments/toh";
import BattlesTOH from "./tohBattles";
import SpendTOH from "./toh/SpendTOH";
import PurchaseTOH from "./toh/PurchaseTOH";
import GroupSpendTOH from "./toh/GroupSpendTOH";


function AnalyticsTOH(props) {
    const {action, tab: tabParam, subtab: subTabParam, game} = useParams()
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId
    const stateId = CommonData.stateId

    const link_prefix = '/' + game + '/' + action + '/' + deviceId + '/'
    const link_postfix = '?vendorId='+stateId

    const tab = tabParam || ''
    const subTab = subTabParam || 'houses';
    const ingameSubTab = subTabParam || 'main';
    const dispatch = useDispatch();
    const deviceIdIsEmpty = deviceId == null || deviceId == '' || typeof deviceId == 'object';

    const version = window.localStorage.getItem('isProd') == 'true' ? 'v2' : 'v2';



    const [tabIndex, setTabIndex] = useState(tab);
    const [subTabIndex, setSubTabIndex] = useState(subTab);
    const [ingameSubTabIndex, setIngameSubTabIndex] = useState(ingameSubTab);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const handleSubTabChange = (event, newTabIndex) => {
        setSubTabIndex(newTabIndex);
    };

    const handleIngameSubTabChange = (event, newTabIndex) => {
        setIngameSubTabIndex(newTabIndex);
    };

    useEffect(() => {

    }, [deviceId, dispatch]);

    let status = ""

    let content = ""
    let panel1 = ""
    let panel2 = ""

    let charges = ""

    if (deviceIdIsEmpty) {
        return (
            <>
                <div>{getDeviceIdAbsentWarning()}</div>
            </>
        );
    }


    if(!deviceIdIsEmpty) {
        content = <TabContext value={tabIndex}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">

                    <Tab label="Payments" value="payments" component={Link} to={link_prefix + "payments" + link_postfix}/>
                    <Tab label="Battles" value="battles" component={Link} to={link_prefix + "battles" + link_postfix}/>
                    <Tab label="Spend Items" value="spend" component={Link} to={link_prefix + "spend" + link_postfix}/>
                    <Tab label="Purchase Items" value="purchase" component={Link} to={link_prefix + "purchase" + link_postfix}/>
                    <Tab label="Summary Items Grouped" value="groups" component={Link} to={link_prefix + "groups" + link_postfix}/>

                </Tabs>
            </Box>

            <TabPanel value="">
                Choose tab
            </TabPanel>

            <TabPanel value="payments">
                <PaymentsTOH deviceId={deviceId}/>
            </TabPanel>

            <TabPanel value="battles">
                <BattlesTOH deviceId={deviceId}/>
            </TabPanel>

            <TabPanel value="spend">
                <SpendTOH deviceId={deviceId}/>
            </TabPanel>
            <TabPanel value="purchase">
                <PurchaseTOH deviceId={deviceId}/>
            </TabPanel>
            <TabPanel value="groups">
                <GroupSpendTOH deviceId={deviceId}/>
            </TabPanel>

        </TabContext>
    }


    return (
        <>
            <div>{content}</div>
        </>
    );

}

export default AnalyticsTOH
