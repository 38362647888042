import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Box, Tab, Tabs,} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import PfLocationsTable from "./ba/PfLocationsTable";
import RawDataEdit from "./ba/RawDataEdit";
import Loading from "../../components/Common/Loading";
import Events from "./sc/Events";

import {useGetStateV2SCQuery} from "../../core/api/solitaire-state-api";
import ConsumablesSC from "../Consumables/sc";
import PaymentsSC from "../Payments/sc";
import {stringify, parse} from "lossless-json";
import {getDeviceIdAbsentWarning} from "../../core/utils/constants";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";


function UserStatesSC(props) {
    const {action, tab: tabParam, subtab: subTabParam, game} = useParams()
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId
    const stateId = CommonData.stateId
    const link_prefix = '/' + game + '/' + action + '/' + deviceId + '/'
    const tab = tabParam || 'update'
    const subTab = subTabParam || 'houses';
    const ingameSubTab = subTabParam || 'main';
    const dispatch = useDispatch();
    const deviceIdIsEmpty = deviceId == null || deviceId == '' || typeof deviceId == 'object';

    const {
        data: obj,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetStateV2SCQuery({deviceId, stateId})


    const [tabIndex, setTabIndex] = useState(tab);
    const [subTabIndex, setSubTabIndex] = useState(subTab);
    const [ingameSubTabIndex, setIngameSubTabIndex] = useState(ingameSubTab);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const handleSubTabChange = (event, newTabIndex) => {
        setSubTabIndex(newTabIndex);
    };

    const handleIngameSubTabChange = (event, newTabIndex) => {
        setIngameSubTabIndex(newTabIndex);
    };

    useEffect(() => {

    }, [deviceId, dispatch]);

    let status = ""

    let content = ""
    let panel1 = ""
    let panel2 = ""

    let charges = ""

    if (deviceIdIsEmpty) {
        return (
            <>
                    <div>{getDeviceIdAbsentWarning()}</div>
            </>
        );
    }

    if (typeof obj === "object" && obj.hasOwnProperty('data')) {

        if(typeof obj.data === "object" && obj.data.hasOwnProperty('error')) {
            content = obj.data.error

        } else if(typeof obj.data === "object") {
            const data = parse(atob(obj.value))


            if (data.hasOwnProperty('progress'))
                panel2 = <PfLocationsTable locations={data.progress.locations}/>
            content = <TabContext value={tabIndex}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Update" value="update" component={Link} to={link_prefix + "update"}/>
                        <Tab label="Progress" value="progress" component={Link} to={link_prefix + "progress"}/>
                        <Tab label="Balance items" value="balance_items" component={Link}
                             to={link_prefix + "balance_items"}/>
                        <Tab label="Payments" value="payments" component={Link} to={link_prefix + "payments"}/>

                    </Tabs>
                </Box>

                <TabPanel value="progress">
                    <TabContext value={subTabIndex}>
                        <Box className={"subtabs"}>
                            <Tabs value={subTabIndex} onChange={handleSubTabChange} aria-label="basic tabs example">
                                <Tab label="Events" value="events" component={Link} to={link_prefix + "progress/events"}/>
                            </Tabs>

                            <TabPanel value="events">
                            <Events tournaments = {data.LCProfile?.Tournament.Data} twenties = {data.LCProfile?.TwentiesTimePressing.Data}/>
                        </TabPanel>

                        </Box>
                    </TabContext>
                </TabPanel>

                <TabPanel value="update">
                    <RawDataEdit data={data}></RawDataEdit>
                </TabPanel>
                <TabPanel value="balance_items">
                    <ConsumablesSC deviceId={deviceId}/>
                </TabPanel>
                <TabPanel value="payments">
                    <PaymentsSC deviceId={deviceId}/>
                </TabPanel>
            </TabContext>

        } else {
            content = obj.data
        }
    } else if (deviceIdIsEmpty) {
        content = getDeviceIdAbsentWarning();
    }
    else {
        content = obj?.error
    }

    return (
        <>
            <Loading isError={isError} isLoading={isLoading} error={error}>
                <div>{status}</div>
                <div>{content}</div>
            </Loading>


        </>
    );

}

export default UserStatesSC
