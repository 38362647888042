import {
    Box,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import SimpleTable from "../../../components/Common/Elements";
import ListJson from "../ListJson";
import TableJson from "../TableJson";
import SimBuildings from "./SimBuildings";
import {useGetGuidMapTohQuery} from "../../../core/api/toh-charge-api";


export default function CitiesTable({cities}) {

    const {
        data: guidMap,
    } = useGetGuidMapTohQuery()


    const getHeroName = (id) => {
        if(!guidMap.hasOwnProperty('Hero')) return id
        const h = guidMap.Hero.filter(item => item.guid==id)
        if(h.length == 0) return id
        return h[0].name

    };

    return (
        <>
            <Card sx={{mb:2}}>

                <Grid container spacing={2}>
                    <Grid sm={3} item>
                        <Typography sx={{pl:2, pt:2}} fontSize={24}>Current</Typography>
                        <TableJson item={cities} fields={["CityId","Stated","Completed"]} />
                    </Grid>
                </Grid>

            </Card>
            {Object.keys(cities['Cities']).map(key => (
                <>
                    <Typography sx={{pl:0, pt:1, pb:1}} variant="h4">{key}</Typography>

                    <Grid container spacing={2} >
                        <Grid item>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Info
                                    </Typography>
                                    <TableJson item={cities['Cities'][key]} fields={["Completed","LatestQuest"]} />

                                </CardContent>

                            </Card>

                        </Grid>
                        <Grid item>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Battle
                                    </Typography>
                                    {cities['Cities'][key].hasOwnProperty('Battle') &&
                                        <Table>
                                            <TableBody>
                                                {Object.keys(cities['Cities'][key]['Battle']['PreferredHeroRoster']).map((_key, value) => (
                                                    <TableRow>
                                                        <TableCell>{_key}</TableCell>
                                                        <TableCell>{getHeroName(cities['Cities'][key]['Battle']['PreferredHeroRoster'][_key])}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    }


                                </CardContent>

                            </Card>
                        </Grid>
                        <Grid item sm={12}>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        SIM: Buildings
                                    </Typography>
                                    {cities['Cities'][key].hasOwnProperty('Sim') && <SimBuildings buildings={cities['Cities'][key]['Sim']['Buildings']} />}

                                </CardContent>

                            </Card>
                        </Grid>
                    </Grid>
                </>
            ))}
        </>
    );
}