import {Button, IconButton, ListItemButton, Table, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";

export default function AdvancedSearchTable({advancedSearchResponse, closeDialog, modalView}) {
    const {game, action} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CommonData = useSelector((state) => state.data.CommonData)


    const handleChooseState = (stateID, vendorID, directPath = action) => {
        dispatch({
            type: "SET_DEVICE_ID",
            payload: {deviceId: stateID},
        });
        dispatch({
            type: "SET_STATE_ID",
            payload: {stateId: vendorID},
        })
        navigate("/" + game + "/" + directPath + "/" + stateID + "/?vendorId=" + vendorID)

        if(closeDialog != undefined) {
            closeDialog();
        }
    }
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        #
                    </TableCell>
                    <TableCell>
                        State ID
                    </TableCell>
                    <TableCell>
                        Vendor ID
                    </TableCell>
                    <TableCell>
                        Extra ID
                    </TableCell>
                    <TableCell>
                        Save ID
                    </TableCell>
                    <TableCell>
                        Date of last change
                    </TableCell>
                    <TableCell>
                        Priority
                    </TableCell>
                    {!modalView && <TableCell>
                        to dashboard
                    </TableCell>}
                    {!modalView && <TableCell>
                        to state
                    </TableCell>}
                    {modalView && <TableCell>
                        Choose state
                    </TableCell>}
                </TableRow>

            </TableHead>
            {advancedSearchResponse.data && advancedSearchResponse.data.map((item, index) => {
                return (
                    <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.stateID}</TableCell>
                        <TableCell>{item.vendorID}</TableCell>
                        <TableCell>{Object.keys(item.extraID).map(key => {
                                if(item.extraID[key] == '' || item.extraID[key]==null) return false
                                return <div><b>{key}</b>: {item.extraID[key]}</div>
                            }
                        )}</TableCell>
                        <TableCell>{item.saveID}</TableCell>
                        <TableCell>{item.date}</TableCell>
                        <TableCell>{item.priority}</TableCell>
                        {!modalView && <TableCell>
                            <Tooltip title="Go to dashboard">
                                <IconButton>
                                    <OpenInNewIcon onClick={() => {
                                        handleChooseState(item.stateID, item.vendorID, "dashboard");

                                    }}/>
                                </IconButton>
                            </Tooltip>

                        </TableCell>}
                        {!modalView && <TableCell>
                            <Tooltip title="Go to state">
                                <IconButton>
                                    <OpenInNewIcon onClick={() => {
                                        handleChooseState(item.stateID, item.vendorID, "state");
                                    }}/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>}
                        {modalView && <TableCell>
                            <Button
                                size={'small'}
                                variant={'outlined'}
                                onClick={() => {
                                handleChooseState(item.stateID, item.vendorID);
                            }}>Choose state
                            </Button>
                        </TableCell>}
                    </TableRow>
                )
            })}
        </Table>
    )
}