import React, {useState} from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import Loading from "../../components/Common/Loading";
import {
    useCreateWhitelistV2Mutation,
    useDeleteWhitelistV2Mutation,
    useGetWhitelistsV2Query
} from "../../core/api/whitelist-api";
import DialogTitle from "@mui/material/DialogTitle";
import {useParams} from "react-router-dom";


export default function WhiteListManagement(props) {
    const {game} = useParams();

    const [whitelist, setWhitelist] = useState({});
    const [createMode, setCreateMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);


    const [createWhitelist] = useCreateWhitelistV2Mutation();
    const [deleteWhitelist] = useDeleteWhitelistV2Mutation();

    const handleCancel = () => {
        setCreateMode(false);
        setDeleteMode(false);
    }

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetWhitelistsV2Query(game)

    const handleWhitelistCreateDialog = () => {
        setWhitelist({
            whitelistDeviceId: "",
            whitelistDeviceName: ""
        })
        setCreateMode(true);
    };

    const handleWhitelistCreate = () => {
        createWhitelist({'whitelist': whitelist, 'game': game});
        handleCancel();
    }

    const handleDeleteWhitelistDialog = (item) => {
        setWhitelist(item);
        setDeleteMode(true);
    };

    const handleDeleteWhitelist = () => {
        deleteWhitelist({'whitelist': whitelist.id, 'game': game})
        handleCancel();
    }

    return (
        <>
            <h1>Whitelist Management</h1>

            <Button variant="contained"
                    onClick={() => handleWhitelistCreateDialog()}
            >
                Add device id
            </Button>
            <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}>
                <TableContainer component={Paper}>
                    <Table aria-label="charge table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Whitelist device name</TableCell>
                                <TableCell>Whitelist device id</TableCell>
                                <TableCell>Actions</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isSuccess && data.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.whitelistDeviceName}</TableCell>
                                    <TableCell>{item.whitelistDeviceId}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleDeleteWhitelistDialog(item)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Loading>
            <Dialog
                open={createMode}
                onClose={handleCancel}>
                <DialogTitle>
                    Add a new whitelist device id
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Whitelist device name"
                                value={whitelist.whitelistDeviceName}
                                onChange={(e) => setWhitelist({
                                    ...whitelist,
                                    "whitelistDeviceName": e.target.value
                                })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Whitelist device id"
                                value={whitelist.whitelistDeviceId}
                                onChange={(e) => setWhitelist({
                                    ...whitelist,
                                    "whitelistDeviceId": e.target.value
                                })}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            onClick={handleWhitelistCreate}
                    >
                        Save
                    </Button>
                    <Button variant="outlined"
                            onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteMode}
                onClose={handleCancel}>
                <Box sx={{margin: 5}}>
                    <h2>Are you sure you want to delete {whitelist?.whitelistDeviceId}?</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={3}>
                            <Button variant="contained"
                                    onClick={handleDeleteWhitelist}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid item sm={3}>
                            <Button variant="outlined"
                                    onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
}