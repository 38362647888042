import {useGetConsumablesV1CMQuery} from "../../core/api/clockmaker-analytical-data-api";
import {
    Box, Card,
    CardActionArea,
    CardActions, CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Loading from "../../components/Common/Loading";
import {DataGrid} from "@mui/x-data-grid";
import {useState} from "react";
import {format, subDays} from "date-fns";

const columns = [
    {
        label: 'Item',
        field: 'itemName',
        width: 140
    },
    {
        label: 'Amount',
        field: 'amount',
        width: 140
    },
    {
        label: 'Reason name',
        field: 'reasonName',
        width: 270
    },
    {
        label: 'Timestamp',
        field: 'timestamp',
        width: 270,
        renderCell: (params) =>
            <div>{format(Date.parse(params.row.timestamp), "dd.MM.yyyy HH:mm:ss")}</div>
    },
    {
        label: 'Offer name',
        field: 'offerName',
        width: 300
    }
]

const ConsumablesCM = (props) => {

    const [currentAmount, setCurrentAmount] = useState("");
    const [filterText, setFilterText] = useState("");
    const [minusDays, setMinusDays] = useState(1);
    const [depth, setDepth] = useState({
        value: 1, displayName: 'For one day', type: "time"
    });

    const depthVariants = [
        {value: 50, displayName: "50 latest itms", type: "amount"},
        {value: 100, displayName: "100 latest items", type: "amount"},
        {value: 200, displayName: "200 latest items", type: "amount"},
        {value: 1, displayName: 'All for one day', type: "time"},
        {value: 7, displayName: 'All for one week', type: "time"},
        {value: 14, displayName: 'All for two weeks', type: "time"},
        {value: 21, displayName: 'All for three weeks', type: "time"},
        {value: 28, displayName: 'All for four weeks', type: "time"},
    ]

    const [fromDate, setFromDate] = useState( format(subDays(new Date(), 30), 'yyyy-MM-dd'));
    const [toDate, setToDate] = useState( format(new Date(), 'yyyy-MM-dd') );

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        isFetching
    } = useGetConsumablesV1CMQuery({"userId": props.deviceId, "limit": currentAmount,
        "from": fromDate,
        "to": toDate
    })

    function filterData() {
        let newData = [...data.rows]
        return newData.filter(item =>
            item.itemName.toLowerCase().includes(filterText.toLowerCase())
            || item.reasonName.toLowerCase().includes(filterText.toLowerCase())
            || item.offerName.toLowerCase().includes(filterText.toLowerCase())
            || item.hasOwnProperty("amount") && item.amount != null && item.amount.toString().includes(filterText.toLowerCase())
        );
    }

    let consumables = ""

    if (typeof data === "object") {
        consumables = <DataGrid
            getRowId={(row) => row.id}
            rows={filterData()}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        consumables = "no data"
    }

    const handleChange = (event) => {
        const selectedVariant = depthVariants.find(
            (variant) => variant.value === event.target.value
        );
        if (selectedVariant.type === 'amount') {
            setMinusDays("");
            setCurrentAmount(selectedVariant.value);
        } else {
            setCurrentAmount("");
            setMinusDays(selectedVariant.value);
        }
        setDepth(selectedVariant);
    };

    return (
        <>
            <Card>
                <CardActions>
                    <Grid container spacing={2}  sx={{pt:2}}>
                        <Grid item>
                            <Box display="flex" justifyContent="flex-end">
                                <TextField
                                    label="Filter"
                                    onChange={(e) => setFilterText(e.target.value)}>
                                </TextField>
                            </Box>
                        </Grid>

                        <Grid item>
                            <TextField
                                label="From Date"
                                type="date"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                label="To Date"
                                type="date"
                                value={toDate}
                                onChange={handleToDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardActions>

                    <Loading
                        isLoading={isLoading||isFetching}
                        isError={isError}
                        error={error}
                        isSuccess={isSuccess}
                    >

                        {consumables}

                    </Loading>
            </Card>
        </>
    )
}

export default ConsumablesCM