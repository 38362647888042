import {Button, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
export const isExists = (value) => value !== undefined && value !== null
export const isString = (value) => isExists(value) && typeof value === "string"

export const isNumber = (value) => hasText(value) && !isNaN(value)

export const hasText = (value) => isString(value) && value.trim().length > 0

export const containsIgnoreCase = (
    string,
    searchString
) => isString(string) && isString(searchString) && string.toLowerCase().includes(searchString.toLowerCase())

export const hasProperty = (
    object,
    key
) => isExists(object) && hasText(key) && typeof object === "object" && object.hasOwnProperty(key)

export const getPropertyOrDefault = (
    object,
    key,
    defaultValue = {}
) => hasProperty(object, key) ? object[key] : defaultValue

export const formatArray = (
    value,
    defaultValue = "no data"
) => Array.isArray(value) ? value.join(", ") : value

export const formatDate = (
    value,
    defaultValue = "no data",
    locale = "ru-RU"
) => hasText(value) ? new Date(value).toLocaleString(locale) : defaultValue

export const formatJSON = (
    value,
    defaultValue = "no data",
    spaces = 2
) => isExists(value) ? JSON.stringify(value, null, spaces) : defaultValue

export const timeUlives = (timestamp) => {
    let ulive = 0;
    let nowTime = Math.trunc(new Date().getTime() / 1000);

    if (timestamp < 10000000000 && timestamp !== 0) {
        console.log("nowTime: " + nowTime + " timestamp: " + timestamp);
        if (nowTime < timestamp) {
            ulive = timestamp - nowTime;
        } else {
            ulive = 0;
        }
    } else if (timestamp === 0) {
        ulive = 0;

    }
    return ulive + " sec";
};

export const paymentUserStatus = (money) => {
    if (money === 0) return "Not paying"
    if (money <= 5) return "Minnow"
    if (money <= 20) return "Dolphin"
    if (money <= 50) return "Big Dolphin"
    if (money <= 130) return "Whale"
    return "Extra Whale"
}

export const calculateFunkyPaymentSegment = (money) => {
    if (money === 0) return "Not paying"
    if (money <= 7) return "Minnow"
    if (money <= 80) return "Dolphin"
    if (money <= 500) return "Whale"
    return "Extra Whale"
}

export const countDisenchantedBuildings = (buildings) => {
    let count = 0;
    buildings.map(building => {
        if (building.State === "Disenchanted") {
            count++
        }
    });
    return count;
};
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getSearchHistory = (game, mode) => {
    let gameSearchHistoryKey = getSearchHistoryKey(game, mode);
    const searchHistory = localStorage.getItem(gameSearchHistoryKey);
    return searchHistory ? searchHistory.split(',').reverse() : [];
};

export const getSearchHistoryVendorId = (game, mode) => {
    let gameSearchHistoryKey = getSearchHistoryKeyVendorID(game, mode);
    const searchHistory = localStorage.getItem(gameSearchHistoryKey);
    return searchHistory ? searchHistory.split(',').reverse() : [];
};

export const getSearchHistorySynchroId = (game, mode) => {
    let gameSearchHistoryKey = getSearchHistoryKeySynchroID(game, mode);
    const searchHistory = localStorage.getItem(gameSearchHistoryKey);
    return searchHistory ? searchHistory.split(',').reverse() : [];
};

export const putSearchHistoryForKey = (synchroId, game, mode, getKeyFunction) => {
    const MAX_SEARCH_HISTORY_LENGTH = 5;
    let gameSearchHistoryKey = getKeyFunction(game, mode);
    const saved = localStorage.getItem(gameSearchHistoryKey);

    if (saved) {
        let searchHistorySaved = saved.split(',');
        if (!searchHistorySaved.includes(synchroId)) {
            if (searchHistorySaved.length >= MAX_SEARCH_HISTORY_LENGTH) {
                searchHistorySaved.push(searchHistorySaved.shift());
                searchHistorySaved[MAX_SEARCH_HISTORY_LENGTH - 1] = synchroId;
            } else {
                searchHistorySaved.push(synchroId);
            }
            localStorage.setItem(gameSearchHistoryKey, searchHistorySaved);
        }
    } else {
        let searchHistory = [];
        searchHistory.push(synchroId);
        localStorage.setItem(gameSearchHistoryKey, searchHistory);
    }
}

export const getSearchHistoryKey = (game, mode) => {
    switch (game) {
        case "ba":
            return mode.isStaging ? "searchHistoryBA" : "searchHistoryBAProd";
        case "cm":
            return mode.isStaging ? "searchHistoryCM" : "searchHistoryCMProd";
        case "sc":
            return mode.isStaging ? "searchHistorySC" : "searchHistorySCProd";
        default:
            return "";
    }
}

export const getSearchHistoryKeyVendorID = (game, mode) => {
    switch (game) {
        case "ba":
            return mode.isStaging ? "searchHistoryBAVendorID" : "searchHistoryBAProdVendorID";
        case "cm":
            return mode.isStaging ? "searchHistoryCMVendorID" : "searchHistoryCMProdVendorID";
        case "sc":
            return mode.isStaging ? "searchHistorySCVendorID" : "searchHistorySCProdVendorID";
        default:
            return "VendorID";
    }
}
export const getSearchHistoryKeySynchroID = (game, mode) => {
    switch (game) {
        case "ba":
            return mode.isStaging ? "searchHistoryBASynchroID" : "searchHistoryBAProdSynchroID";
        case "cm":
            return mode.isStaging ? "searchHistoryCMSynchroID" : "searchHistoryCMProdSynchroID";
        case "sc":
            return mode.isStaging ? "searchHistorySCSynchroID" : "searchHistorySCProdSynchroID";
        default:
            return "SynchroID";
    }
}

export const ItemsValidation = [
    {
        field: "ulive",
        name: "Unlimited Live",
        type: "time",
        validation: 86400
    },
    {
        field: "ublast",
        name: "Unlimited Blast",
        type: "time",
        validation: 86400
    },
    {
        field: "ustar",
        name: "Unlimited Star",
        type: "time",
        validation: 86400
    },
    {
        field: "ulightning",
        name: "Unlimited Lightning",
        type: "time",
        validation: 86400
    }
];

export const CMItems = {
    "Main": {
        "ruby": "Ruby",
        "live": "Live"
    },
    "Trick": {
        "hummer": "Hummer",
        "bomb": "Bomb",
        "color": "Color",
        "glove": "Glove"
    },
    "Bonus": {
        "blast": "Blast",
        "star": "Star",
        "lightning": "Lightning"

    },
    "Unlimited": {
        "ulive": "Unlimited Live",
        "ublast": "Unlimited Blast",
        "ustar": "Unlimited Star",
        "ulightning": "Unlimited Lightning"
    },
    "Mates": {
        "mate_1": "Cat",
        "mate_2": "Dog",
        "mate_3": "Parrot",
        "mate_4": "Monkey"
    },
    "UnityMates": {
        "cat": "Cat",
        "dog": "Dog",
        "parrot": "Parrot",
        "monkey": "Monkey"
    },
    "Puzzles": {"puzzlePiece": "Puzzle piece"},
    "Collections": {
        "element_1": "Браслет",
        "element_2": "Брошь",
        "element_3": "Перчатки",
        "element_4": "Котелок",
        "element_5": "Трость",
        "element_6": "Запонки",
        "element_7": "Бабочка",
        "element_8": "Часы",
        "element_9": "Рогатка",
        "element_10": "Шляпа непоседы",
        "element_11": "Шорты с подтяжками",
        "element_12": "Родниковая вода",
        "element_13": "Горящее чучело",
        "element_14": "Воздушный змей",
        "element_15": "Шапочка",
        "element_16": "Звонок",
        "element_17": "Подушка с вензелем",
        "element_18": "Шляпа всадника",
        "element_19": "Знак шерифа",
        "element_20": "Ковбойские сапоги",
        "element_21": "Медуза",
        "element_22": "Коралл",
        "element_23": "Морская звезда",
        "element_24": "Осенние листья",
        "element_25": "Корзина с яблоками",
        "element_26": "Теплый шарф",
        "element_27": "Пончик-вампир",
        "element_28": "Печенька-зомби",
        "element_29": "Сладкое привидение",
        "item_house_1": "Пинцет",
        "item_house_2": "Магический шар",
        "item_house_3": "Вязаная шапка",
        "item_house_4": "Седло",
        "item_house_5": "Барабан",
        "item_house_6": "Кофе",
        "item_active_1_1": "Газета",
        "item_active_1_2": "Свеча",
        "item_active_1_3": "Сачок",
        "item_active_1_4": "Плюмаж",
        "item_active_1_5": "Сигнальная труба",
        "item_active_1_6": "Корица",
        "item_active_2_1": "Корица",
        "item_active_2_2": "Шаль",
        "item_active_2_3": "Котелок с ухой",
        "item_active_2_4": "Карамельное яблоко",
        "item_active_2_5": "Флажок",
        "item_active_2_6": "Кекс",
        "item_active_3_1": "Конверт",
        "item_active_3_2": "Гребень",
        "item_active_3_3": "Крючок",
        "item_active_3_4": "Бубенчик",
        "item_active_3_5": "Сигнальная ракета",
        "item_active_3_6": "Бадьян",
        "collection_1": "Альбом с марками",
        "collection_2": "Карты Таро",
        "collection_3": "Рыболовный ящик",
        "collection_4": "Игрушечная карусель",
        "collection_5": "Сигнальная пушка",
        "collection_6": "Кофемолка",
        "set_1": "Сама элегантность",
        "set_2": "Благородный джентльмен",
        "set_3": "Лондонский франт",
        "set_4": "Озорной непоседа",
        "set_5": "Амулет весны",
        "set_6": "Несессер",
        "set_7": "Герой прерий",
        "set_8": "Пляжная сумка",
        "set_9": "Осенний букет",
        "set_10": "Ведерко конфет",
    },
    "Other actions": {
        "ADD_BP_SCORE": "Add BP score",
        "ADD_BP_GOLD": "Add BP gold",
        "SET_PUZZLES": "Set completed puzzles",
        "SET_HOUSE": "Unlock house"
    }
}

export const findItemName = (idName) => {
    let name = "";
    Object.values(CMItems).map(item => {
        if (item[idName] !== undefined) {
            return name = item[idName]
        }
    });
    return name
};

export const timestampToDate = (timestamp) => {
    let date;
    if (timestamp < 10000000000 && timestamp !== 0) {
        date = new Date(Number.parseInt(timestamp) * 1000);
    } else if (timestamp === 0) {
        date = 0;
        return date;
    } else {
        date = new Date(Number.parseInt(timestamp))
    }
    // return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() + " " + date.getHours() + ":"+ date.getMinutes()  + ":" + date.getSeconds()
    return date.toString()
};
export const getLastSession = (sessions) => {
    const sessionsArray = sessions.split(",");
    return sessionsArray[sessionsArray.length - 2].toString();
};
export const getItemByName = (items, itemName) => {
    let value;
    items.forEach(item => {
        if (item.Id === itemName) {
            value = item
        }
    });
    return value
};


export const HOUSES = ["Дом дядюшки (Uncle's House)", "Городской мост (Town bridge)", "Дом рыбака (Fisherman's House)", "Конюшня (Stables)", "Сторожевая башня (Watch Tower)",
    "Мельница (The Mill)", "Пекарня (The Bakery)", "Домик старика Сэма (Old Sam's House)", "Обсерватория (Observatory)", "Книжный магазин (Bookshop)",
    "Особняк Гаррисонов (The Harrison's Mansion)", "Домики горожан (Townsfolk's Houses)", "Пожарная часть (Fire station)", "Мэрия (Town Hall)", "Антикварная лавка (Antique Shop)",
    "Трактир \"У Луизы\" (Louise's Inn)", "Прачечная (Laundry)", "Театр (Theatre)", "Домик писателя (The Writer's House)", "Школа (School)", "Дом профессора Джонса (Proffesor Jone's House)",
    "Гостиница (Hotel)", "Городской банк (Town Bank)", "Дом кузнеца (Blacksmith's House)", "Фонтан (Fountain)", "Часовая башня (Clock Tower)", "Паром", "Таверна",
    "Причал", "Верфь", "Склад", "Торговая гильдия", "Магазин", "Цирюльник", "Музыкальный театр", "Мастерская портного", "Фабрика", "Школа", "Аптека", "Пожарная Часть",
    "Госпиталь", "Часовая мастерская", "Библиотека", "Оранжерея", "Университет", "Маяк", "Часовой форт", "Мэрия", "Особняк", "Музей", "Банк", "Башня Часовщика"];

export const MATERIALS = new Map([["material_1", "Коробок спичек"],
    ["material_2", "Отмычки"],
    ["material_3", "Запись"],
    ["material_4", "Камень"],
    ["material_5", "Багор"],
    ["material_6", "Лампа"],
    ["material_7", "Ведро с водой"],
    ["material_8", "Факел"],
    ["material_9", "Рыба"],
    ["material_10", "Подкова"],
    ["material_11", "Колесо"],
    ["material_12", "Метла"],
    ["material_13", "Доски"],
    ["material_14", "Механизм"],
    ["material_15", "Подзорная труба"],
    ["material_16", "Мешок с мукой"],
    ["material_17", "Молоток и гвозди"],
    ["material_18", "Шестеренка"],
    ["material_19", "Скалка"],
    ["material_20", "Хлеб"],
    ["material_21", "Крендель"],
    ["material_22", "Перо и чернила"],
    ["material_23", "Швабра"],
    ["material_24", "Гаечный ключ и болты"],
    ["material_25", "Метеорит"],
    ["material_26", "Астролябия"],
    ["material_27", "Лупа"],
    ["material_28", "Книга"],
    ["material_29", "Подсвечник"],
    ["material_30", "Колокольчик"],
    ["material_31", "Шляпа"],
    ["material_32", "Монета"],
    ["material_33", "Печать"],
    ["material_34", "Чайник"],
    ["material_35", "Медные водопроводные трубы"],
    ["material_36", "Антикварные часы"],
    ["material_37", "Пожарный топор"],
    ["material_38", "Перчатки [пожарные рукавицы]"],
    ["material_39", "Пожарный шланг"],
    ["material_40", "Ключи"],
    ["material_41", "Очки"],
    ["material_42", "Монета"],
    ["material_43", "Сундук"],
    ["material_44", "Счёты"],
    ["material_45", "Моноколь на цепочке"],
    ["material_46", "Настольный светильник"],
    ["material_47", "Белые перчатки"],
    ["material_48", "Табурет"],
    ["material_49", "Платок/салфетка"],
    ["material_50", "Клетчатый плед"],
    ["material_51", "Пуговица"],
    ["material_52", "Театральный бинокль"],
    ["material_53", "Веер"],
    ["material_54", "Элегантная маска"],
    ["material_55", "Энциклопедия"],
    ["material_56", "Флюгер"],
    ["material_57", "Ваза"],
    ["material_58", "Глобус"],
    ["material_59", "Азбука"],
    ["material_60", "Чернильница"],
    ["material_61", "Картина"],
    ["material_62", "Перчатки"],
    ["material_63", "Кубок"],
    ["material_64", "Подушка"],
    ["material_65", "Зонтик"],
    ["material_66", "Вешалка"],
    ["material_67", "Замок"],
    ["material_68", "Вексель"],
    ["material_69", "Шкатулка"],
    ["material_70", "Гвозди"],
    ["material_71", "Часть ограды"],
    ["material_72", "Третья часть механизма"],
    ["material_73", "Статуэтка"],
    ["material_74", "Ракушка"],
    ["material_75", "Четвертая часть механизма"],
    ["material_76", "Бронзовый ключ"],
    ["material_77", "Серебряный ключ"],
    ["material_78", "Золотой ключ"],
    ["material_79", "Припасы"],
    ["material_80", "Лампа"],
    ["material_81", "Бутылка"],
    ["material_82", "Чай"],
    ["material_83", "Щетка"],
    ["material_84", "Боченок"],
    ["material_85", "Инструменты"],
    ["material_86", "Бочка с водой"],
    ["material_87", "Веревка"],
    ["material_88", "Доски"],
    ["material_89", "Гвозди"],
    ["material_90", "Краска"],
    ["material_91", "Сундук с товарами"],
    ["material_92", "Банка кофе"],
    ["material_93", "Компас"],
    ["material_94", "Весы"],
    ["material_95", "Винтажный телефон"],
    ["material_96", "Кольцо с ключами"],
    ["material_97", "Лампочка"],
    ["material_98", "Винтажный фотоаппарат"],
    ["material_99", "Монеты"],
    ["material_100", "Ножницы"],
    ["material_101", "Полотенце"],
    ["material_102", "Зеркало"],
    ["material_103", "Музыкальная шкатулка"],
    ["material_104", "Камертон"],
    ["material_105", "Метроном"],
    ["material_106", "Иголка и нитка"],
    ["material_107", "Линейка"],
    ["material_108", "Ткань"],
    ["material_109", "Механизм"],
    ["material_110", "Батарея"],
    ["material_111", "Защитные очки"],
    ["material_112", "Мел"],
    ["material_113", "Яблоко"],
    ["material_114", "Тетрадь"],
    ["material_115", "Склянки"],
    ["material_116", "Аптечка"],
    ["material_117", "Горелка"],
    ["material_118", "Шлем"],
    ["material_119", "Лестница"],
    ["material_120", "Телеграф"],
    ["material_121", "Фонендоскоп"],
    ["material_122", "Антисептик"],
    ["material_123", "Докторский чемоданчик"],
    ["material_124", "Инструменты часовщика"],
    ["material_125", "Набор шестеренок"],
    ["material_126", "Карманные часы"],
    ["material_127", "Звездный атлас"],
    ["material_128", "Стопка книг"],
    ["material_129", "Древний свиток"],
    ["material_130", "Лилия"],
    ["material_131", "Стекло"],
    ["material_132", "Садовые кусачки"],
    ["material_133", "Астрономические часы"],
    ["material_134", "Оптический механизм"],
    ["material_135", "Граммофон"],
    ["material_136", "Отражатель маяка"],
    ["material_137", "Зарисовка города"],
    ["material_138", "Телеграфная лента"],
    ["material_139", "Сложный механизм"],
    ["material_140", "Порох"],
    ["material_141", "Пушечное ядро"],
    ["material_142", "Универсальный ключ"],
    ["material_143", "Печать"],
    ["material_144", "Печатная машинка"],
    ["material_145", "Старинные часы"],
    ["material_146", "Картина"],
    ["material_147", "Трость"],
    ["material_148", "Экзотическая маска"],
    ["material_149", "Алмаз"],
    ["material_150", "Осколок фрески"],
    ["material_151", "Слиток золота"],
    ["material_152", "Книга учета"],
    ["material_153", "Драгоценности"],
    ["material_154", "Бесконечная аномалия"],
    ["material_155", "Пространственная аномалия"],
    ["material_156", "Временная аномалия"]]);
export const CONSUMABLES = {
    "consumable": {
        "coin": {
            "desc": "Выполняйте заказы, чтобы заработать больше монет!",
            "earn": "Выполняйте заказы, чтобы заработать больше монет!",
            "name": "Монетка"
        },
        "crabhat": {
            "name": "Бочка"
        },
        "dec_arnold_surfer": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Уникальный декор для твоей фермы",
            "name": "Статуя Арнольда"
        },
        "dec_bicycle": {
            "name": "Велосипед"
        },
        "dec_bird_bath": {
            "name": "Фонтан"
        },
        "dec_easter_egg": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Попадается в подарках",
            "name": "Пасхальное яйцо"
        },
        "dec_fireworks_small": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Попадается в подарках",
            "name": "Фейерверк"
        },
        "dec_gazebo": {
            "name": "Беседка"
        },
        "dec_independence_chicken": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Уникальный декор для твоей фермы",
            "name": "Цыпленок Сэм"
        },
        "dec_independence_lantern": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Уникальный декор для твоей фермы",
            "name": "Фонарь"
        },
        "dec_independence_spinner": {
            "name": "Спиннер"
        },
        "dec_lupinus_flower_bed": {
            "name": "Цветочная клумба"
        },
        "dec_park_wheel": {
            "desc": "Некоторое время приносит монеты",
            "name": "Колесо обозрения"
        },
        "dec_patrick_banner": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Попадается в подарках",
            "name": "Флагшток"
        },
        "dec_patrick_hanger": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Попадается в подарках",
            "name": "Вешалка"
        },
        "dec_patrick_table": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Попадается в подарках",
            "name": "Клеверный столик"
        },
        "dec_rhodiola": {
            "name": "Родиола"
        },
        "dec_rhododendron_reward": {
            "name": "Азалия"
        },
        "dec_serf": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Уникальный декор для твоей фермы",
            "name": "Серф"
        },
        "dec_sleigh": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Попадается в подарках",
            "name": "Сани"
        },
        "dec_streetlight": {
            "desc": "Уникальный декор для твоей фермы",
            "earn": "Попадается в подарках",
            "name": "Фонарь"
        },
        "dec_trophy_anchor": {
            "expedition": "Уникальный трофей с острова сокровищ",
            "name": "Якорь \"Летучего голландца\""
        },
        "dec_trophy_pearl": {
            "expedition": "Уникальный трофей с острова сокровищ",
            "name": "Жемчужная скульптура"
        },
        "dec_trophy_vase": {
            "expedition": "Уникальный трофей с острова сокровищ",
            "name": "Старинная ваза с \"Пекода\""
        },
        "dec_trophy_wheel": {
            "expedition": "Уникальный трофей с острова сокровищ",
            "name": "Штурвал \"Покорителя зари\""
        },
        "dec_tulip_flower_bed": {
            "name": "Цветник"
        },
        "dec_waterfall": {
            "name": "Водопад"
        },
        "decor_polynesia_faceboard": {
            "name": "Серф"
        },
        "decor_polynesia_head": {
            "name": "Пышная клумба"
        },
        "decor_polynesia_pointer": {
            "name": "Указатель"
        },
        "diamond": {
            "desc": "Игровая валюта для покупок всего-всего в игре",
            "earn": "Попадается в подарках",
            "name": "Алмазы"
        },
        "energy": {
            "earn": "Используется для расчистки пути во время экспедиций",
            "name": "Энергетик"
        },
        "expedition_chest_big": {
            "expedition": "Он наполнен полезными вещами",
            "name": "Сундучок"
        },
        "expedition_chest_middle": {
            "expedition": "Он наполнен полезными вещами",
            "name": "Сундучок"
        },
        "expedition_chest_small": {
            "expedition": "Он наполнен полезными вещами",
            "name": "Сундучок"
        },
        "f_apple": {
            "earn": "Растeт на яблоне",
            "name": "Яблоко"
        },
        "f_avocado": {
            "earn": "Растет на дереве авокадо",
            "name": "Авокадо"
        },
        "f_banana": {
            "earn": "Растeт на банановом дереве",
            "name": "Банан"
        },
        "f_cacao": {
            "earn": "Растeт на шоколадном дереве",
            "name": "Какао"
        },
        "f_coconut": {
            "earn": "Растет на Кокосовой пальме",
            "name": "Кокос"
        },
        "f_coffee": {
            "earn": "Растeт на кофейном дереве",
            "name": "Кофе"
        },
        "f_lemon": {
            "earn": "Растeт на лимонном дереве",
            "name": "Лимон"
        },
        "f_mulberry": {
            "earn": "Соберите ягоды с куста перед тем, как спилить его",
            "name": "Шелковица"
        },
        "f_rubber": {
            "earn": "Растет на Каучуковом дереве",
            "name": "Каучук"
        },
        "i_amethyst": {
            "earn": "Исследуйте шахту, чтобы найти Аметисты",
            "name": "Аметист"
        },
        "i_anturium": {
            "earn": "Попадается из Антуриума",
            "name": "Цветок Антуриума"
        },
        "i_apple_cake": {
            "earn": "Производится в Пекарне",
            "name": "Штрудель"
        },
        "i_apple_jam": {
            "earn": "Производится в Погребке",
            "name": "Яблочный джем"
        },
        "i_apple_juice": {
            "earn": "Производится в Соковыжималке",
            "name": "Яблочный сок"
        },
        "i_bag": {
            "earn": "Производится в Мастерской",
            "name": "Шерстяная сумка"
        },
        "i_banana_chips": {
            "earn": "Производится в Фастфуде",
            "name": "Банановые чипсы"
        },
        "i_banana_icecream": {
            "earn": "Производится в Ларьке с мороженым",
            "name": "Банановое мороженое"
        },
        "i_banana_smoothie": {
            "earn": "Производится в Смузи-баре",
            "name": "Банановый смузи"
        },
        "i_blue_ball_decorations": {
            "earn": "Производится в Мастерской декора",
            "name": "Синий шар"
        },
        "i_blue_flowers_location": {
            "earn": "Попадается в Синих цветах",
            "name": "Синий цветок"
        },
        "i_blueberry_smoothie": {
            "earn": "Производится в Смузи-баре",
            "name": "Черничный смузи"
        },
        "i_branch": {
            "name": "Ветка"
        },
        "i_bread": {
            "earn": "Производится в Пекарне",
            "name": "Хлеб"
        },
        "i_brick": {
            "earn": "Можно найти в подарках на пляже и в сундуках",
            "name": "Кирпич"
        },
        "i_burger_avocado": {
            "earn": "Производится в Закусочной",
            "name": "Зеленый бургер"
        },
        "i_butter": {
            "earn": "Производится на Молокозаводе",
            "name": "Масло"
        },
        "i_cacao_milk": {
            "earn": "Производится в Кофейне",
            "name": "Какао с молоком"
        },
        "i_cacao_powder": {
            "earn": "Производится на Мельнице",
            "name": "Какао порошок"
        },
        "i_cake_avocado": {
            "earn": "Производится в Тортной",
            "name": "Торт с авокадо"
        },
        "i_cake_carrot": {
            "earn": "Производится в Тортной",
            "name": "Морковный торт"
        },
        "i_cake_coconut": {
            "earn": "Производится в Тортной",
            "name": "Кокосовый торт"
        },
        "i_cake_coffee": {
            "earn": "Производится в Тортной",
            "name": "Мокка торт"
        },
        "i_canned_mulberry": {
            "earn": "Производится в Погребке",
            "name": "Джем из шелковицы"
        },
        "i_canned_onion": {
            "earn": "Производится в Погребке",
            "name": "Маринованный лук"
        },
        "i_canned_pepper": {
            "earn": "Производится в Погребке",
            "name": "Чили в сиропе"
        },
        "i_canned_tomato": {
            "earn": "Производится в Погребке",
            "name": "Помидорчики"
        },
        "i_carrot_juice": {
            "earn": "Производится в Соковыжималке",
            "name": "Морковный сок"
        },
        "i_cheese": {
            "earn": "Производится на Молокозаводе",
            "name": "Сыр"
        },
        "i_chicken_feed": {
            "desc": "Накормите кур, чтобы получить яйца",
            "earn": "Производится в Фабрике корма",
            "name": "Куриный корм"
        },
        "i_choco_egg": {
            "earn": "Производится в Кондитерской",
            "name": "Шоколадное Яйцо"
        },
        "i_choco_tea": {
            "earn": "Производится в Кондитерской",
            "name": "Зеленый шоколад"
        },
        "i_chocolate": {
            "earn": "Производится в Кондитерской",
            "name": "Шоколад"
        },
        "i_chocolate_milky": {
            "earn": "Производится в Кондитерской",
            "name": "Молочный шоколад"
        },
        "i_clover_syrup": {
            "earn": "Производится в Соковыжималке",
            "name": "Сироп"
        },
        "i_coconut_creme": {
            "earn": "Производится на Мельнице",
            "name": "Кокосовый крем"
        },
        "i_coffee": {
            "earn": "Производится в Кофейне",
            "name": "Американо"
        },
        "i_coffee_latte": {
            "earn": "Производится в Кофейне",
            "name": "Латте"
        },
        "i_coin": {
            "name": "Старая монета"
        },
        "i_colibri_bush_flower": {
            "earn": "Попадается в Орхидее",
            "name": "Цветы Орхидеи"
        },
        "i_compass": {
            "earn": "Выполняйте заказы в тоннеле, чтобы раздобыть Компас",
            "name": "Компас"
        },
        "i_cone": {
            "name": "Колышек"
        },
        "i_cookie": {
            "earn": "Производится в Пекарне",
            "name": "Печенье"
        },
        "i_cooking_oil": {
            "earn": "Производится в Соковыжималке",
            "name": "Подсолнечное масло"
        },
        "i_corndog": {
            "earn": "Производится в Фастфуде",
            "name": "Корн-дог"
        },
        "i_cos_creme": {
            "earn": "Производится в Магазине Косметики",
            "name": "Кокосовое масло"
        },
        "i_cos_pomade": {
            "earn": "Производится в Магазине Косметики",
            "name": "Красная помада"
        },
        "i_cos_rouge": {
            "earn": "Производится в Магазине Косметики",
            "name": "Румяна"
        },
        "i_cos_shampoo": {
            "earn": "Производится в Магазине Косметики",
            "name": "Шампунь"
        },
        "i_cow_feed": {
            "desc": "Накормите коров, чтобы произвести молоко",
            "earn": "Производится в Фабрике корма",
            "name": "Корм коров"
        },
        "i_crab": {
            "earn": "Исследуйте океан на корабле, чтобы найти краба",
            "expedition": "Ингредиент для некоторых продуктов",
            "name": "Краб"
        },
        "i_cream": {
            "earn": "Производится на Молокозаводе",
            "name": "Сливки"
        },
        "i_donut_berliner": {
            "earn": "Производится в Пончиковой",
            "name": "Берлинер"
        },
        "i_donut_blue": {
            "earn": "Производится в Пончиковой",
            "name": "Ягодный эклер"
        },
        "i_donut_chili": {
            "earn": "Производится в Пончиковой",
            "name": "Чили пончик"
        },
        "i_donut_choco": {
            "earn": "Производится в Пончиковой",
            "name": "Шоколадный пончик"
        },
        "i_drill": {
            "desc": "Ищите еe в подарках на Катере, или в Сундучках",
            "earn": "Ищите еe в подарках на Катере, или в Сундучках",
            "name": "Дрель"
        },
        "i_dumplings_classics": {
            "earn": "Производятся в Пельменной",
            "name": "Вареники с творогом"
        },
        "i_dumplings_green": {
            "earn": "Производятся в Пельменной",
            "name": "Овощные пельмени"
        },
        "i_dumplings_khinkali": {
            "earn": "Производятся в Пельменной",
            "name": "Хинкали"
        },
        "i_dumplings_ravioli": {
            "earn": "Производятся в Пельменной",
            "name": "Равиоли"
        },
        "i_dynamite": {
            "earn": "Ищите его в Морских Сундуках, или в Сундучках на острове",
            "name": "Динамит"
        },
        "i_dynamite_2": {
            "earn": "Ищите еe в Морских Сундуках, или в Сундучках на острове",
            "name": "Пороховая бочка"
        },
        "i_earring": {
            "earn": "Производятся в Ювелирной",
            "name": "Серьги"
        },
        "i_eggs": {
            "earn": "Производятся в Курятнике",
            "name": "Яйца"
        },
        "i_energy_pack_1": {
            "name": "Напиток энергии"
        },
        "i_energy_pack_10": {
            "name": "Варенье энергии"
        },
        "i_energy_pack_2": {
            "name": "Варенье энергии"
        },
        "i_energy_pack_3": {
            "name": "Тeплый компот"
        },
        "i_energy_pack_4": {
            "name": "Термос"
        },
        "i_energy_pack_41": {
            "name": "Бутылочка энергии"
        },
        "i_energy_pack_42": {
            "name": "Чаша энергии"
        },
        "i_energy_pack_5": {
            "name": "Энергетик"
        },
        "i_energy_pack_51": {
            "name": "Чашка энергии"
        },
        "i_energy_pack_52": {
            "name": "Чайник энергии"
        },
        "i_energy_pack_6": {
            "name": "Энергетик"
        },
        "i_energy_pack_9": {
            "name": "Напиток энергии"
        },
        "i_energy_pack_easter_1": {
            "name": "Бутылочка энергии"
        },
        "i_energy_pack_easter_2": {
            "name": "Стакан энергии"
        },
        "i_energy_pack_park_big": {
            "name": "Коктейль с мороженым"
        },
        "i_energy_pack_park_candy": {
            "name": "Сахарная вата"
        },
        "i_energy_pack_park_small": {
            "name": "Бабл Ти"
        },
        "i_energy_pack_patrick_1": {
            "name": "Бутылочка энергии"
        },
        "i_energy_pack_patrick_2": {
            "name": "Банка энергии"
        },
        "i_energy_pack_patrick_3": {
            "name": "Грибной суп"
        },
        "i_energy_pack_spring_1": {
            "name": "Энергетический чай"
        },
        "i_energy_pack_spring_2": {
            "name": "Энергетический кофе"
        },
        "i_energy_pack_tibet_1": {
            "name": "Чаша энергии"
        },
        "i_energy_pack_tibet_2": {
            "name": "Бутылочка энергии"
        },
        "i_falafel": {
            "earn": "Производится в Фастфуде",
            "name": "Фалафель"
        },
        "i_feather": {
            "earn": "Производится в загоне Фламинго",
            "name": "Перо"
        },
        "i_fireworks_decorations": {
            "earn": "Производится в мастерской фейерверков",
            "name": "Фейерверк"
        },
        "i_flamingo_feed": {
            "desc": "Кормите Фламинго, чтобы получать Перья",
            "earn": "Производится в Фабрике корма",
            "name": "Корм для Фламинго"
        },
        "i_flour": {
            "earn": "Производится на Мельнице",
            "name": "Мука"
        },
        "i_french_fries": {
            "earn": "Производится в Фастфуде",
            "name": "Картофель фри"
        },
        "i_fruit_ice": {
            "earn": "Производится в Ларьке с мороженым",
            "name": "Фруктовый лед"
        },
        "i_fruit_soda": {
            "earn": "Производится в Лимонадной лавке",
            "name": "Черничный морс"
        },
        "i_gingerbread": {
            "earn": "Производится в пекарне пряников",
            "name": "Пряник"
        },
        "i_gold": {
            "earn": "Исследуйте шахту, чтобы найти Золото",
            "name": "Золото"
        },
        "i_gold_bar": {
            "earn": "Производится в Плавильне",
            "name": "Золотой слиток"
        },
        "i_grill_potato": {
            "earn": "Готовится на Гриле",
            "name": "Печеный картофель"
        },
        "i_grill_pumpkin": {
            "earn": "Готовится на Гриле",
            "name": "Тыква на гриле"
        },
        "i_grill_shrimp": {
            "earn": "Готовится на Гриле",
            "name": "Копченые креветки"
        },
        "i_guarana_drink": {
            "earn": "Производится в Лимонадной лавке",
            "name": "Кола"
        },
        "i_guarana_powder": {
            "earn": "Производится на Мельнице",
            "name": "Порошок гуараны"
        },
        "i_gum_banana_guarana": {
            "earn": "Производится на Фабрике жвачки",
            "name": "Жвачка Фанки"
        },
        "i_gum_grape": {
            "earn": "Производится на Фабрике Жвачки",
            "name": "Виноградная Жвачка"
        },
        "i_gum_green": {
            "earn": "Производится на Фабрике Жвачки",
            "name": "Пряная Жвачка"
        },
        "i_gunpowder": {
            "earn": "Производится на пороховой фабрике",
            "name": "Порох"
        },
        "i_halloween_branch": {
            "earn": "Попадается в кустах",
            "name": "Ветка"
        },
        "i_halloween_candle": {
            "earn": "Попадается в деревьях со свечами",
            "name": "Свечка"
        },
        "i_halloween_hat": {
            "earn": "Может быть найдено в деревьях с фонариками",
            "name": "Шляпа Ведьмы"
        },
        "i_halloween_horror_wood": {
            "earn": "Попадается в Злых деревьях с лицами",
            "name": "Бревна Джека"
        },
        "i_halloween_moon_stone": {
            "earn": "Попадается в камнях с лицами",
            "name": "Камень-черепушка"
        },
        "i_halloween_potion": {
            "earn": "Может быть найдено в красных цветах на кладбище",
            "name": "Зелье"
        },
        "i_halloween_stone": {
            "earn": "Попадается в горных породах",
            "name": "Гранит"
        },
        "i_halloween_wood": {
            "earn": "Попадается в простых деревьях",
            "name": "Полено"
        },
        "i_hammer": {
            "earn": "Ищите его в подарках на Катере, или в Сундучках",
            "name": "Молоток"
        },
        "i_hat_dark": {
            "earn": "Производится в Шляпной",
            "name": "Фетровая шляпа"
        },
        "i_hat_pink": {
            "earn": "Производится в Шляпной",
            "name": "Элегантная шляпа"
        },
        "i_hawaii_coctail_1": {
            "earn": "Производится в Тропическом баре",
            "name": "Синий коктейль"
        },
        "i_hawaii_coctail_2": {
            "earn": "Производится в Тропическом баре",
            "name": "Желтый коктейль"
        },
        "i_hawaii_coctail_3": {
            "earn": "Производится в Тропическом баре",
            "name": "Красный коктейль"
        },
        "i_hawaii_energy_big": {
            "name": "Коктейль с мороженым"
        },
        "i_hawaii_energy_small": {
            "name": "Бабл Ти"
        },
        "i_hay": {
            "earn": "Растет на земле",
            "name": "Трава"
        },
        "i_honey": {
            "earn": "Производится в Улье",
            "name": "Мед"
        },
        "i_icecream": {
            "earn": "Производится в Ларьке с мороженым",
            "name": "Мороженое"
        },
        "i_icecream_tea": {
            "earn": "Производится в Ларьке с мороженым",
            "name": "Чайное мороженое"
        },
        "i_independence_bear": {
            "earn": "Производится в Лавке сувениров",
            "name": "Мишка"
        },
        "i_independence_cap": {
            "earn": "Производится в Лавке сувениров",
            "name": "Кепка"
        },
        "i_independence_energy_big": {
            "name": "Энергетический чай"
        },
        "i_independence_energy_small": {
            "name": "Энергетический кофе"
        },
        "i_independence_flag": {
            "earn": "Производится в Лавке сувениров",
            "name": "Флаг"
        },
        "i_independence_tools": {
            "earn": "Производятся в хижине Хенкока",
            "name": "Инструменты"
        },
        "i_iron": {
            "earn": "Исследуйте шахту, чтобы найти Железо",
            "name": "Железо"
        },
        "i_iron_bar": {
            "earn": "Производится в Плавильне",
            "name": "Железный слиток"
        },
        "i_jelly_choco": {
            "earn": "Производится на Фабрике Желе",
            "name": "Шоколадное желе"
        },
        "i_jelly_grape": {
            "earn": "Производится на Фабрике Желе",
            "name": "Ягодное желе"
        },
        "i_ketchup": {
            "earn": "Производится на Мельнице",
            "name": "Кетчуп"
        },
        "i_key": {
            "desc": "Помогает вам открывать сундуки",
            "earn": "Попадаются в подарках",
            "name": "Ключ"
        },
        "i_lama_feed": {
            "desc": "Накормите лам, чтобы произвести шерсть",
            "earn": "Производится в Фабрике корма",
            "name": "Корм лам"
        },
        "i_lemonade": {
            "earn": "Производится в Лимонадной лавке",
            "name": "Лимонад"
        },
        "i_lighthouse_part": {
            "earn": "Спрятаны на острове",
            "name": "Части маяка"
        },
        "i_lighthouse_part_2": {
            "earn": "Спрятаны на острове",
            "name": "Части маяка"
        },
        "i_location_air_balloon": {
            "earn": "Продолжай поиск на локации",
            "name": "Воздушный шарик"
        },
        "i_location_aloe": {
            "earn": "Попадается в кустах алоэ",
            "name": "Лист алоэ"
        },
        "i_location_amanita": {
            "earn": "Попадается в Мухоморах",
            "name": "Мухомор"
        },
        "i_location_bamboo": {
            "earn": "Попадается в зарослях бамбука",
            "name": "Бамбук"
        },
        "i_location_baowood": {
            "earn": "Попадается в гигантских деревьях",
            "name": "Древесина крупного дерева"
        },
        "i_location_big_flower": {
            "name": "Фотографии Редких цветов"
        },
        "i_location_blue_lupin": {
            "earn": "Попадается в Фиолетовом люпине",
            "name": "Цветок Фиолетового люпина"
        },
        "i_location_blue_tulip": {
            "earn": "Растет в Цветущих садах",
            "name": "Синий тюльпан"
        },
        "i_location_carambola": {
            "earn": "Растет на дереве Карамбола",
            "name": "Карамбола"
        },
        "i_location_china_key_1": {
            "earn": "Находится у Мистера Ливея",
            "name": "Древний ключ"
        },
        "i_location_china_key_2": {
            "earn": "Находится в Великом Дворце",
            "name": "Древний ключ"
        },
        "i_location_china_materials_1": {
            "earn": "Попадается у Мистера Ливея",
            "name": "Строительные материалы"
        },
        "i_location_china_materials_2": {
            "earn": "Попадается у Мистера Ливея",
            "name": "Кровельные материалы"
        },
        "i_location_china_panda_female": {
            "earn": "Найди и сфотографируй маму-панду",
            "name": "Фотография мамы-панды"
        },
        "i_location_china_panda_kid": {
            "earn": "Найди и сфотографируй малыша-панду",
            "name": "Фотография малыша-панды"
        },
        "i_location_china_panda_male": {
            "earn": "Найди и сфотографируй папу-панду",
            "name": "Фотография папы-панды"
        },
        "i_location_china_scroll": {
            "earn": "Попадается в Домике панд",
            "name": "Древний свиток"
        },
        "i_location_cinnamon": {
            "earn": "Попадается в Коричном дереве",
            "name": "Корица"
        },
        "i_location_citrus": {
            "earn": "Попадается в Цитроне",
            "name": "Плод Цитрона"
        },
        "i_location_clay": {
            "earn": "Попадается в глиняных кочках и в карьере",
            "name": "Глина"
        },
        "i_location_climbing_equipment": {
            "earn": "Можно получить в Верстаке",
            "name": "Горное снаряжение"
        },
        "i_location_coreopsis": {
            "earn": "Попадается в Цветке кореопсиса",
            "name": "Цветок кореопсиса"
        },
        "i_location_dragonfruit": {
            "earn": "Попадается в кактусах питайя",
            "name": "Плод питайя"
        },
        "i_location_dynamite": {
            "desc": "Помогает расчищать локации в экспедициях",
            "earn": "Попадается в подарках",
            "name": "Бомба"
        },
        "i_location_ear": {
            "earn": "Попадается в Душистом колоске",
            "name": "Колосок"
        },
        "i_location_easter_ben": {
            "earn": "Найди и сфотографируй Кролика",
            "name": "Фотография Белого кролика"
        },
        "i_location_easter_clock": {
            "earn": "Попадается у Белого кролика",
            "name": "Гипнотические часы"
        },
        "i_location_easter_egg_1": {
            "earn": "Попадается в лабиринте",
            "name": "Пасхальное яйцо"
        },
        "i_location_easter_egg_2": {
            "earn": "Попадается в лабиринте",
            "name": "Пасхальное яйцо"
        },
        "i_location_easter_egg_3": {
            "earn": "Попадается в лабиринте",
            "name": "Пасхальное яйцо"
        },
        "i_location_easter_egg_4": {
            "earn": "Попадается в лабиринте",
            "name": "Пасхальное яйцо"
        },
        "i_location_easter_egg_5": {
            "earn": "Попадается в лабиринте",
            "name": "Пасхальное яйцо"
        },
        "i_location_easter_egg_6": {
            "earn": "Попадается в лабиринте",
            "name": "Пасхальное яйцо"
        },
        "i_location_easter_mushroom": {
            "earn": "Попадается у Белого кролика",
            "name": "Праздничный мухомор"
        },
        "i_location_flower_penny": {
            "earn": "Можно получить в Корабле Амели",
            "name": "Цветочный пенни"
        },
        "i_location_flowerdust": {
            "earn": "Производится на мельнице",
            "name": "Цветочная пыльца"
        },
        "i_location_gold_lupin": {
            "earn": "Попадается в Золотом люпине",
            "name": "Цветок Золотого люпина"
        },
        "i_location_grass": {
            "earn": "Попадается в Траве",
            "name": "Осока"
        },
        "i_location_green_stone": {
            "earn": "Попадается в Зеленых валунах",
            "name": "Зеленый камень"
        },
        "i_location_hawaii_water": {
            "earn": "Добывается в Колодце",
            "name": "Вода"
        },
        "i_location_hepatica": {
            "earn": "Попадается в Лазурных кустах",
            "name": "Лазурный цветок"
        },
        "i_location_ice_block": {
            "earn": "Попадается в Ледяных валунах",
            "name": "Ледяной блок"
        },
        "i_location_iris": {
            "earn": "Попадается в Ирисе",
            "name": "Цветок Ириса"
        },
        "i_location_mushroom": {
            "earn": "Попадаются в грибах",
            "name": "Грибы"
        },
        "i_location_ore": {
            "earn": "Попадается в Гематите",
            "name": "Железная руда"
        },
        "i_location_palmleaf": {
            "earn": "Попадается в Пальме",
            "name": "Листья Пальмы"
        },
        "i_location_panda_female": {
            "name": "Фотография мамы-панды"
        },
        "i_location_panda_kid": {
            "name": "Фотография малыша-панды"
        },
        "i_location_panda_male": {
            "name": "Фотография папы-панды"
        },
        "i_location_park_ticket": {
            "earn": "Можно получить в Парке чудес",
            "name": "Билет"
        },
        "i_location_patrick_boot": {
            "earn": "Пригодится для украшения фонтана",
            "name": "Башмак"
        },
        "i_location_patrick_hat": {
            "earn": "Пригодится для украшения фонтана",
            "name": "Шляпа"
        },
        "i_location_patrick_lira": {
            "earn": "Пригодится для украшения фонтана",
            "name": "Подкова"
        },
        "i_location_patrick_pipe": {
            "earn": "Пригодится для украшения фонтана",
            "name": "Трубка"
        },
        "i_location_patrik_key": {
            "earn": "Попадается у Лепрекона",
            "name": "Ключ от сокровищницы"
        },
        "i_location_pineapple": {
            "earn": "Растет на грядках в локации",
            "name": "Ананасы"
        },
        "i_location_pinky_tree": {
            "earn": "Попадается в Рододендроне",
            "name": "Цветок Рододендрона"
        },
        "i_location_pinkyflower": {
            "earn": "Попадается в Табебуйе",
            "name": "Цветы Табебуйя"
        },
        "i_location_prayer_wheel": {
            "earn": "Можно получить в Барабанах духов",
            "name": "Реликвия духов"
        },
        "i_location_red_lupin": {
            "earn": "Попадается в Багровом люпине",
            "name": "Цветок Багрового люпина"
        },
        "i_location_red_stone": {
            "earn": "Попадается в Красных валунах",
            "name": "Красный камень"
        },
        "i_location_red_tulip": {
            "earn": "Растет в Цветущих садах",
            "name": "Красный тюльпан"
        },
        "i_location_redleaf": {
            "earn": "Попадается в Кордилине",
            "name": "Красный лист"
        },
        "i_location_rhodiola": {
            "earn": "Попадается в Родиоле",
            "name": "Цветок Родиолы"
        },
        "i_location_rhododendron_bouquet": {
            "earn": "Производится в Букетной лавке",
            "name": "Букет редких цветов"
        },
        "i_location_rune_green": {
            "earn": "Производится в Доме Ацтеков",
            "name": "Зеленая руна"
        },
        "i_location_rune_red": {
            "earn": "Производится в Башне Ацтеков",
            "name": "Красная руна"
        },
        "i_location_rune_yellow": {
            "earn": "Находится у аборигена",
            "name": "Золотая руна"
        },
        "i_location_sandstone": {
            "earn": "",
            "name": "Песчаник"
        },
        "i_location_souvenir_1": {
            "earn": "Производится в Лавке сувениров",
            "name": "Игрушка Патрика"
        },
        "i_location_souvenir_2": {
            "earn": "Производится в Лавке сувениров",
            "name": "Кукла Патрика"
        },
        "i_location_spire": {
            "earn": "Можно найти в Туманных горах",
            "name": "Шпиль"
        },
        "i_location_spring_water": {
            "earn": "Добывается в Колодце",
            "name": "Вода"
        },
        "i_location_spring_windmill_part_1": {
            "earn": "Ищи в Цветущих садах",
            "name": "Мельничная лопасть"
        },
        "i_location_spring_windmill_part_2": {
            "earn": "Ищи в Цветущих садах",
            "name": "Мельничная лопасть"
        },
        "i_location_spring_windmill_part_3": {
            "earn": "Ищи в Цветущих садах",
            "name": "Мельничная лопасть"
        },
        "i_location_spring_windmill_part_4": {
            "earn": "Ищи в Цветущих садах",
            "name": "Мельничная лопасть"
        },
        "i_location_spring_windmill_part_5": {
            "earn": "Ищи в Цветущих садах",
            "name": "Мельничное колесо"
        },
        "i_location_spring_windmill_part_6": {
            "earn": "Ищи в Цветущих садах",
            "name": "Крыша мельницы"
        },
        "i_location_star": {
            "earn": "Я собираюсь украсить ей самую верхушку праздничной eлки",
            "name": "Звезда"
        },
        "i_location_tulips_bouquet": {
            "earn": "Производится в Букетной лавке",
            "name": "Букет тюльпанов"
        },
        "i_location_water": {
            "earn": "Добывается в Колодце",
            "name": "Вода"
        },
        "i_location_wildflowers_bouquet": {
            "earn": "Производится в Букетной лавке",
            "name": "Букет диких цветов"
        },
        "i_location_yellow_tree": {
            "earn": "Попадается в Арагуанее",
            "name": "Цветок Арагуанея"
        },
        "i_location_yellow_tulip": {
            "earn": "Растет в Цветущих садах",
            "name": "Желтый тюльпан"
        },
        "i_log": {
            "earn": "Пилите деревья, или ищите их в подарках на Катере",
            "name": "Бревно"
        },
        "i_mex_guacamole": {
            "earn": "Производится на Мексиканском стенде",
            "name": "Гуакамоле"
        },
        "i_mex_nachos": {
            "earn": "Производится на Мексиканском стенде",
            "name": "Начос"
        },
        "i_mex_taco": {
            "earn": "Производится на Мексиканском стенде",
            "name": "Тако"
        },
        "i_milk": {
            "earn": "Производится в Коровнике",
            "name": "Молоко"
        },
        "i_mussel": {
            "earn": "Исследуй океан на корабле, чтобы найти Мидии",
            "expedition": "Мидии используются как ингредиент в суши, сашими и супах",
            "name": "Мидия"
        },
        "i_mussels_soup": {
            "earn": "Производится в Супной",
            "name": "Суп из мидий"
        },
        "i_nails": {
            "earn": "Ищите их в подарках на Пляже, или в Сундучках",
            "name": "Гвозди"
        },
        "i_necklace": {
            "earn": "Производится в Ювелирной",
            "name": "Ожерелье"
        },
        "i_nori": {
            "earn": "Исследуйте океан на корабле, чтобы найти Нори",
            "expedition": "Нори используется как ингредиент в суши и сашими",
            "name": "Нори"
        },
        "i_octopus": {
            "earn": "Исследуйте океан на корабле, чтобы найти осьминога",
            "expedition": "Ингредиент для некоторых продуктов",
            "name": "Осьминог"
        },
        "i_onion_rings": {
            "earn": "Производится в Закусочной",
            "name": "Бургер"
        },
        "i_ostrich_egg": {
            "earn": "Производиться в загоне Страусов",
            "name": "Страусиное яйцо"
        },
        "i_ostrich_feed": {
            "desc": "Кормите Страусов, чтобы получать Страусиные яйца",
            "earn": "Производится в Фабрике корма",
            "name": "Корм для Страусов"
        },
        "i_patrick_drink": {
            "earn": "Производится в Лавке Трехлистника",
            "name": "Лимонад Патрика"
        },
        "i_peanut_butter": {
            "earn": "Производится на Мельнице",
            "name": "Арахисовое масло"
        },
        "i_perfume_coconut": {
            "earn": "Производится в Парфюмерной лавке",
            "name": "Кокосовый парфюм"
        },
        "i_perfume_coffee": {
            "earn": "Производится в Парфюмерной лавке",
            "name": "Кофейный аромат"
        },
        "i_perfume_fruit": {
            "earn": "Производится в Парфюмерной лавке",
            "name": "Фруктовая вода"
        },
        "i_perfume_pepper": {
            "earn": "Производится в Парфюмерной лавке",
            "name": "Духи Огонeк"
        },
        "i_pickaxe": {
            "earn": "Ищите еe в Морских Сундуках, или в Сундучках на острове",
            "name": "Кирка"
        },
        "i_pie_calzone": {
            "earn": "Производится в Пекарне",
            "name": "Кальцоне"
        },
        "i_pizza": {
            "earn": "Производится в Пекарне",
            "name": "Пицца"
        },
        "i_plank": {
            "earn": "Ищите еe в подарках на Пляже, или в Сундучках",
            "name": "Доска"
        },
        "i_platinum": {
            "earn": "Исследуйте шахту, чтобы найти Платину",
            "name": "Платина"
        },
        "i_platinum_bar": {
            "earn": "Производится в Плавильне",
            "name": "Слиток платины"
        },
        "i_popcorn": {
            "earn": "Производится в Фастфуде",
            "name": "Попкорн"
        },
        "i_pumpkin_icecream": {
            "earn": "Производится в Ларьке с мороженым",
            "name": "Тыквенное мороженое"
        },
        "i_pumpkin_soup": {
            "earn": "Производится в Супной",
            "name": "Тыквенный суп"
        },
        "i_punch": {
            "earn": "Производится в Лимонадной лавке",
            "name": "Компот"
        },
        "i_quark": {
            "earn": "Производится на Молокозаводе",
            "name": "Творог"
        },
        "i_red_ball_decorations": {
            "earn": "Производится в Мастерской декора",
            "name": "Красный шар"
        },
        "i_red_flowers_location": {
            "earn": "Попадается в Красных цветах",
            "name": "Красный цветок"
        },
        "i_red_hat": {
            "earn": "Производится в Швейной",
            "name": "Вязаная шапка"
        },
        "i_ring": {
            "earn": "Производится в Ювелирной",
            "name": "Кольцо"
        },
        "i_salad_apple": {
            "earn": "Производится в Салат-баре",
            "name": "Французский салат"
        },
        "i_salad_avocado": {
            "earn": "Производится в Салат-баре",
            "name": "Салат из авокадо"
        },
        "i_salad_fruit": {
            "earn": "Производится в Салат-баре",
            "name": "Фруктовый салат"
        },
        "i_salad_soy": {
            "earn": "Производится в Салат-баре",
            "name": "Салат из соевых бобов"
        },
        "i_sandwich": {
            "earn": "Производится в Закусочной",
            "name": "Бутерброд"
        },
        "i_sauce_mayo": {
            "earn": "Производится в Соусной",
            "name": "Майонез"
        },
        "i_sauce_pepper": {
            "earn": "Производится в Соусной",
            "name": "Обжигающий соус"
        },
        "i_sauce_soy": {
            "earn": "Производится в Соусной",
            "name": "Соевый соус"
        },
        "i_saw": {
            "earn": "Ищите еe в подарках на Катере",
            "name": "Пила"
        },
        "i_saw_2": {
            "earn": "Ищите еe в подарках на Катере",
            "name": "Двуручная пила"
        },
        "i_saw_3": {
            "earn": "Ищите еe в подарках на Катере",
            "name": "Бензопила"
        },
        "i_scarf": {
            "earn": "Производится в Швейной",
            "name": "Морской шарф"
        },
        "i_scrambled_eggs": {
            "earn": "Производится в Закусочной",
            "name": "Яичница"
        },
        "i_screws": {
            "earn": "Ищите их в подарках на Пляже, или в Сундучках",
            "name": "Болты"
        },
        "i_screwtool": {
            "earn": "Ищите еe в подарках на Катере, или в Сундучках",
            "name": "Отвeртка"
        },
        "i_seafood_icemussels": {
            "earn": "Производится в Баре морепродуктов",
            "name": "Мидии на пару"
        },
        "i_seafood_octopus": {
            "earn": "Производится в Баре морепродуктов",
            "name": "Жареный осьминог"
        },
        "i_seafood_paella": {
            "earn": "Производится в Баре морепродуктов",
            "name": "Паэлья"
        },
        "i_season_regatta_token": {
            "earn": "Можно получить, выполняя задания сезонных регат. Не пропадает после\nокончания сезона. ",
            "name": "Жетоны"
        },
        "i_shrimp": {
            "earn": "Исследуйте океан на корабле, чтобы найти Креветки",
            "expedition": "Креветки используются как ингредиент в сашими и закусках",
            "name": "Креветка"
        },
        "i_silk": {
            "earn": "Производится в Домике шелкопряда",
            "name": "Шелк"
        },
        "i_silk_fox": {
            "earn": "Производится в Швейной мастерской",
            "name": "Лисица"
        },
        "i_silk_pillow": {
            "earn": "Производится в Швейной",
            "name": "Подушка"
        },
        "i_silk_shirt": {
            "earn": "Производится в Швейной мастерской",
            "name": "Шелковая рубашка"
        },
        "i_skates_winter": {
            "earn": "Они выглядят, как прекрасный подарочек",
            "name": "Коньки"
        },
        "i_smoothie_sunflower": {
            "earn": "Производится в Смузи-баре",
            "name": "Веганский смузи"
        },
        "i_sneakers_converse": {
            "earn": "Производится в Обувной лавке",
            "name": "Теннисные тапочки"
        },
        "i_sneakers_superstar": {
            "earn": "Производится в Обувной лавке",
            "name": "Кеды"
        },
        "i_soup_clover": {
            "earn": "Производится в Супной",
            "name": "Крем-суп"
        },
        "i_soup_corn": {
            "earn": "Производится в Супной",
            "name": "Кукурузный суп"
        },
        "i_spyglass": {
            "earn": "Выполняйте заказы в тоннеле, чтобы раздобыть Подзорную трубу",
            "name": "Подзорная труба"
        },
        "i_stone": {
            "earn": "Разбивайте валуны на острове и в Шахте, или ищите их в подарках на Катере",
            "name": "Камень"
        },
        "i_stone_pounder": {
            "earn": "Производится в Мастерской",
            "name": "Каменная ступка"
        },
        "i_strawberry_smoothie": {
            "earn": "Производится в Смузи-баре",
            "name": "Клубничный смузи"
        },
        "i_sunglasses": {
            "earn": "Производится в Ювелирной",
            "name": "Королевские очки"
        },
        "i_surf": {
            "earn": "Производится в Мастерской",
            "name": "Серф"
        },
        "i_sushi_avocado": {
            "earn": "Производится в Суши-баре",
            "name": "Суши с авокадо"
        },
        "i_sushi_mussels": {
            "earn": "Производится в Суши-баре",
            "name": "Суши с мидиями"
        },
        "i_sushi_pumpkin": {
            "earn": "Производится в Суши-баре",
            "name": "Суши с тыквой"
        },
        "i_sushi_shrimps": {
            "earn": "Производится в Суши-баре",
            "name": "Сашими с креветками"
        },
        "i_tea_guarana": {
            "earn": "Производится в Чайной",
            "name": "Энергетический чай"
        },
        "i_tea_honey": {
            "earn": "Производится в Чайной",
            "name": "Чай с медом"
        },
        "i_tea_milk": {
            "earn": "Производится в Чайной",
            "name": "Молочный чай"
        },
        "i_tea_rose": {
            "earn": "Производится в Чайной",
            "name": "Каркаде"
        },
        "i_tomato_soup": {
            "earn": "Производится в Супной",
            "name": "Томатный суп"
        },
        "i_tool": {
            "earn": "Ищите еe в подарках на Пляже, или в Катере",
            "name": "Лопата"
        },
        "i_toys_location": {
            "earn": "Производится в Мастерской игрушек",
            "name": "Плюшевая игрушка"
        },
        "i_train_decorations": {
            "earn": "Производится в Мастерской игрушек",
            "name": "Игрушечный поезд"
        },
        "i_trowel": {
            "earn": "Ищите его в подарках на Катере, или в Сундучках",
            "name": "Мастерок"
        },
        "i_ukulele": {
            "earn": "Производится в Мастерской",
            "name": "Укулеле"
        },
        "i_wok_noodles": {
            "earn": "Производится в Ларьке Вок",
            "name": "Коробочка Лапши"
        },
        "i_wok_octopus": {
            "earn": "Производится в Ларьке Вок",
            "name": "Черный вок"
        },
        "i_wok_rice": {
            "earn": "Производится в Ларьке Вок",
            "name": "Коробочка Риса"
        },
        "i_wood": {
            "earn": "Спиливайте деревья, чтобы получить Брeвна",
            "name": "Бревно"
        },
        "i_wool": {
            "earn": "Производится на Пастбище Лам",
            "name": "Шерсть"
        },
        "i_yo_yo": {
            "earn": "Производится в Мастерской",
            "name": "Йо-йо"
        },
        "i_yogurt_smoothie": {
            "earn": "Производится в Смузи-баре",
            "name": "Йогуртовый смузи"
        },
        "interseason_decor_1": {
            "name": "Приз чемпионов"
        },
        "p_blueberry": {
            "earn": "Растeт на грядках",
            "name": "Черника"
        },
        "p_carrot": {
            "earn": "Растeт на грядках",
            "name": "Морковь"
        },
        "p_clover": {
            "earn": "Растeт на грядках",
            "name": "Клевер"
        },
        "p_corn": {
            "earn": "Растeт на грядках",
            "name": "Кукуруза"
        },
        "p_goldenflower": {
            "earn": "Растет на грядках в локации",
            "name": "Золотой цветок"
        },
        "p_grape": {
            "earn": "Растeт на грядках",
            "name": "Виноград"
        },
        "p_guarana": {
            "earn": "Растeт на грядках",
            "name": "Гуарана"
        },
        "p_location_clover_patrick": {
            "earn": "Растет на грядках в локации",
            "name": "Трехлистный клевер"
        },
        "p_location_golden_flower": {
            "earn": "Растет на грядках в локации",
            "name": "Золотой цветок"
        },
        "p_onion": {
            "earn": "Растeт на грядках",
            "name": "Лук"
        },
        "p_peanut": {
            "earn": "Растeт на грядках",
            "name": "Арахис"
        },
        "p_pepper": {
            "earn": "Растeт на грядках",
            "name": "Перец чили"
        },
        "p_potato": {
            "earn": "Растeт на грядках",
            "name": "Картофель"
        },
        "p_pumpkin": {
            "earn": "Растeт на грядках",
            "name": "Тыква"
        },
        "p_rice": {
            "earn": "Растeт на грядках",
            "name": "Рис"
        },
        "p_rose": {
            "earn": "Растeт на грядках",
            "name": "Роза"
        },
        "p_soybean": {
            "earn": "Растeт на грядках",
            "name": "Соя"
        },
        "p_strawberry": {
            "earn": "Растeт на грядках",
            "name": "Клубника"
        },
        "p_sunflower": {
            "earn": "Растeт на грядках",
            "name": "Подсолнух"
        },
        "p_tealeaf": {
            "earn": "Растeт на грядках",
            "name": "Чай"
        },
        "p_tomato": {
            "earn": "Растeт на грядках",
            "name": "Помидор"
        },
        "p_wheat": {
            "earn": "Растeт на грядках",
            "name": "Пшеница"
        },
        "parrot_boat": {
            "name": "Шхуна"
        },
        "seashell_01": {
            "desc": "Ищите ее у заказчиков в Тоннеле",
            "earn": "Ищите ее у заказчиков и в Тоннеле",
            "name": "Узкая ракушка"
        },
        "seashell_02": {
            "desc": "Ищите ее в подарках у заказчиков на Пляже",
            "earn": "Ищите ее в подарках у заказчиков на Пляже",
            "name": "Иглистая ракушка"
        },
        "seashell_03": {
            "desc": "Ищите ее в больших сундуках из Морских Экспедиций",
            "earn": "Ищите ее в больших сундуках из Морских Экспедиций",
            "name": "Соленая ракушка"
        },
        "seashell_04": {
            "desc": "Ищите ее в Экспедициях и Шахте",
            "earn": "Ищите ее в Экспедициях и Шахте",
            "name": "Спиральная ракушка"
        },
        "sharkpool": {
            "name": "Акулий бассейн"
        },
        "town_item": {
            "decor_polynesia_drums": {
                "name": "Барабаны"
            }
        },
        "xp": {
            "earn": "Повышает ваш уровень",
            "name": "Опыт"
        }
    }
};

export function parseFormDataTextToJsonString(text) {
    if (!text.includes("form-data")) {
        return JSON.stringify(text);
    } else {
        let formDataStr = text.split("form-data; name=");
        let objectArray = [];
        formDataStr.forEach(subString => {
            const data = subString.substring(subString.indexOf("\\"), subString.indexOf('--'))
                .replaceAll("\r\n\r\n", ":")
                .replaceAll("\r\n", "");
            objectArray.push(data)
        })
        return objectArray.join(' ');
    }
}


export const WORLDS_SC = Object.freeze([
    "Нассау (Багамы)",
    "Лас-Вегас (США)",
    "Париж (Франция)",
    "Монте-Карло (Монако)",
    "Макао (САР, КНР)",
    "Сидней (Австралия)",
    "Лондон (Великобритания)",
    "Марина-Бэй (Сингапур)",
    "Венеция (Италия)",
    "Атлантик-Сити (США)",
    "Санта-Крус (Тенерифе)",
    "Кейптаун (ЮАР)",
    "Манила (Филиппины)",
    "Канны (Франция)",
    "Дубай (ОАЭ)",
    "Каир (Египет)",
    "Гонконг (Китай)",
    "Гамбург (Германия)",
    "Нью-Йорк (США)",
    "Москва (Россия)",
    "Неаполь (Италия)",
    "Майами (США)",
    "Прага (Чехия)",
    "Будапешт (Венгрия)"
])

export const CONSUMABLES_SC = Object.freeze([
    {id: "0", name: "Gold"},
    {id: "3", name: "Free Ticket"},
    {id: "4", name: "Wildcard"},
    {id: "5", name: "Reverse"},
    {id: "6", name: "Replenish"},
    {id: "7", name: "Greenback"},
    {id: "10", name: "DiceTicket"},
    {id: "12", name: "PiggyBank"},
    {id: "13", name: "BattlePass"},
    {id: "16", name: "DesignPoint"},
    {id: "18", name: "JourneyKey"},
    {id: "19", name: "Like"},
    {id: "20", name: "ColoringPoint"},
    {id: "22", name: "BingoBall"},
    {id: "24", name: "RumbleToken"},
    {id: "25", name: "CardDeck"},
    {id: "26", name: "GardeningSeed"},
    {id: "27", name: "GardeningWater"},

    {id: "28", name: "PlutoGamblingToken"},
    {id: "29", name: "GardeningPass"},
    {id: "30", name: "CruiseToken"},
    {id: "106", name: "SingleBoosterRemoveColor"},
    {id: "107", name: "SingleBoosterRemoveBlock"},
    {id: "108", name: "SingleBoosterUndo"},

    {id: "110", name: "SingleBoosterRemoveCards"},
    {id: "111", name: "LootboxCollectionCommon"},
    {id: "112", name: "LootboxCollectionRare"},
    {id: "113", name: "LootboxCollectionExclusive"},
    {id: "114", name: "LootboxSouvenirSmall"},
    {id: "115", name: "LootboxSouvenirLarge"},
    {id: "116", name: "LootboxCollectionDuplicates"},

    {id: "123", name: "PaidSubscription", type: "Unlimited"}


])

export function getDeviceIdAbsentWarning() {
    return (
        <Grid container>
            <Grid>
                <ErrorRoundedIcon color={"warning"}/>
            </Grid>
            <Grid>
                <Typography pl={1}>
                    Select id of the device to view its state.
                </Typography>
            </Grid>
        </Grid>
    )
}

export function getMultipleStatesWarning(setOpen) {
    return (
        <Tooltip title="Click to view other states associated with this state id">
            <Button
                startIcon={<ErrorRoundedIcon color={"warning"}/>}
                variant={"outlined"}
                onClick={() => setOpen(true)}>
                All states
            </Button>
        </Tooltip>
    )
}

export function getStatesNotFoundWarning() {
    return (
        <Grid container>
            <Grid>
                <ErrorRoundedIcon color={"warning"}/>
            </Grid>
            <Grid>
                <Typography pl={1}>
                    States not found
                </Typography>
            </Grid>
        </Grid>
    )
}

export function alertMsgProcessor(response, addAlert) {
    if(response!=undefined) {
        if (response.hasOwnProperty('error')) {
            if (response.error.status === 403) {
                addAlert({text: 'This id is not in the whitelist', type: 'error'})
            }
            else if (response.error.status === 403) {
                addAlert({text: 'Not authorized', type: 'error'})
            } else {
                let message = 'Something went wrong'
                if(response.error.hasOwnProperty('data') && response.error.data!=null) {
                    if(response.error.data.hasOwnProperty('message')) message = response.error.data?.message

                }
                addAlert({text: message, type: 'error'})
            }
        } else {
            addAlert({text: 'State created', type: 'success'})
        }
    }
}

