import {useGetConsumablesV1SCQuery} from "../../core/api/solitaire-analytical-data-api";
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Loading from "../../components/Common/Loading";
import {DataGrid} from "@mui/x-data-grid";
import {useState} from "react";

const columns = [
    {
        label: 'Item',
        field: 'itemName',
        width: 400
    },
    {
        label: 'Amount',
        field: 'amount',
        width: 400
    },
    {
        label: 'Reason name',
        field: 'reasonName',
        width: 400
    }
]

const ConsumablesSC = (props) => {

    const [currentAmount, setCurrentAmount] = useState("");
    const [filterText, setFilterText] = useState("");
    const [minusDays, setMinusDays] = useState(1);
    const [depth, setDepth] = useState({
        value: 1, displayName: 'For one day', type: "time"
    });

    const depthVariants = [
        {value: 50, displayName: "50 latest itms", type: "amount"},
        {value: 100, displayName: "100 latest items", type: "amount"},
        {value: 200, displayName: "200 latest items", type: "amount"},
        {value: 1, displayName: 'All for one day', type: "time"},
        {value: 7, displayName: 'All for one week', type: "time"},
        {value: 14, displayName: 'All for two weeks', type: "time"},
        {value: 21, displayName: 'All for three weeks', type: "time"},
        {value: 28, displayName: 'All for four weeks', type: "time"},

    ]

    const userId = props.deviceId;
    const limit = currentAmount;
    const page = minusDays;

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        isFetching
    } = useGetConsumablesV1SCQuery({"userId": props.deviceId, "limit": currentAmount, "minusDays": minusDays})

    function filterData() {
        let newData = [...data.rows]
        return newData.filter(item => item.itemName.toLowerCase().includes(filterText.toLowerCase()))
    }

    let consumables = ""

    if (typeof data === "object") {
        consumables = <DataGrid
            getRowId={(row) => row.id}
            rows={data.rows}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        consumables = "no data"
    }

    const handleChange = (event) => {
        const selectedVariant = depthVariants.find(
            (variant) => variant.value === event.target.value
        );
        if (selectedVariant.type === 'amount') {
            setMinusDays("");
            setCurrentAmount(selectedVariant.value);
        } else {
            setCurrentAmount("");
            setMinusDays(selectedVariant.value);
        }
        setDepth(selectedVariant);
    };

    return (
        <Loading
            isLoading={isLoading}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
            isFetching={isFetching}
        >
            <Grid container spacing={2}>
                <Grid item>
                    <Box display="flex" justifyContent="flex-end">
                        <TextField
                            label="Filter"
                            onChange={(e) => setFilterText(e.target.value)}>
                        </TextField>
                    </Box>
                </Grid>

                <Grid item>
                    <FormControl sx={{minWidth: '200px'}}>
                        <InputLabel id="demo-simple-select-label">Choose depth</InputLabel>
                        <Select value={depth.value} onChange={handleChange} label="Choose depth">
                            {depthVariants.map((variant) => (
                                <MenuItem key={variant.value} value={variant.value}>
                                    {variant.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {consumables}

        </Loading>
    )
}

export default ConsumablesSC