import {Card, CardContent, Typography} from "@mui/material";
import Loading from "../../components/Common/Loading";
import SimpleTable from "../../components/Common/Elements";
import {useGetEnvironmentsV1CMQuery, useGetUserInfoV1CMQuery} from "../../core/api/clockmaker-analytical-data-api";
import {useEffect, useState} from "react";


const USER_INFO_MAP = new Map([
    ['adjustId', 'adjust id'],
    ['vendorId', 'vendor id'],
    ['appsetId', 'appset Id'],
    ['reserveId', 'reserve id'],
    ['gameCenterId', 'game center id'],
    ['appleId', 'apple id'],
    ['gpId', 'gp id'],
    ['afId', 'af id'],
    ['gaid', 'GAID'],
    ['fbId', 'Facebook Id']
]);

const UserSocialInfo = (props) => {
    const {
        data
    } = props;

    let info = ""

    console.log("userData")

    console.log(data)
    if (typeof data === "object") {
        let userInfo = []
        for (const key in data[0]) {
            if (USER_INFO_MAP.has(key)) {
                userInfo.push([USER_INFO_MAP.get(key), data[0][key]])
            }
        }
        info = <SimpleTable data={userInfo}/>
    } else {
        info = "no data"
    }


    return (
        <Card>
            <CardContent>
                <Typography sx={{fontSize: 16}}>
                    User social ids
                </Typography>
            </CardContent>
            {info}
        </Card>
    )
}

export default UserSocialInfo