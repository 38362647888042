import {createTheme} from "@mui/material";


const theme = createTheme({
    components: {
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                    paddingTop: 20,
                },
            },
        },
    },
});

export default theme