import {
    Box,
    Button,
    Card,
    CardContent, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Divider, FormControl,
    Grid,
    IconButton, InputLabel, Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText, MenuItem, Select,
    TextField
} from "@mui/material";
import {Autocomplete, LoadingButton} from "@mui/lab";
import React, {useContext, useState} from "react";
import {useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Editor from "@monaco-editor/react";
import {SnackBarContext} from "../../snackBarProvider";

import { parse, stringify } from 'lossless-json'
import {useSelector} from "react-redux";

import PrefabsRTItem from "./rt/PrefabsRTItem";
import PrefabsBAItem from "./ba/PrefabsBAItem";
import PrefabsSCItem from "./sc/PrefabsSCItem";
import PrefabsCMItem from "./cm/PrefabsCMItem";
import PrefabsTOHItem from "./toh/PrefabsTOHItem";

import {format} from "date-fns";
import ConstructionIcon from '@mui/icons-material/Construction';
import {useGetPrefabsInfoListV2CMQuery} from "../../core/api/clockmaker-prefab-api";
import {useGetPrefabsMetaQuery} from "../../core/api/toh-prefab-api";
import {ContentCopy} from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import DialogTitle from "@mui/material/DialogTitle";
import {alertMsgProcessor} from "../../core/utils/constants";
import {isEmpty} from "../../core/utils/StringUtils";

const MyListItem = ({item, selectItem, selectedItem, onDelete}) => {
    const [hover, setHover] = React.useState(false);
    // export default useSnackBars

    const stringToRGB = (str) => {
        // Хэшируем строку
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        // Преобразуем хэш в RGB и добавляем смещение для светлой палитры
        let r = ((hash >> 16) & 0xFF) + 128; // Минимальное значение 128
        let g = ((hash >> 8) & 0xFF) + 128;  // Минимальное значение 128
        let b = (hash & 0xFF) + 128;         // Минимальное значение 128

        // Убедимся, что значения не превышают 255
        r = r > 255 ? 255 : r;
        g = g > 255 ? 255 : g;
        b = b > 255 ? 255 : b;

        // Возвращаем RGB строку
        return `rgb(${r}, ${g}, ${b})`;
    }

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <ListItem
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <ListItemButton
                onClick={() => selectItem(item)}
                selected={item.id === selectedItem.id}
            >
                <ListItemText
                    primary={item.name}
                    secondary={ <>
                        <div>{format(Date.parse(item.date), "yyyy-MM-dd HH:mm:ss")}</div>
                        {!isEmpty(item.version)&&<Chip
                            label={`${item.version}`}
                            size="small"
                            sx={{ mr: 0.5 }}
                        />}
                        {!isEmpty(item.environment)&&<Chip
                            label={`${item.environment}`}
                            size="small"
                            sx={{ mr: 0.5 }}
                        />}
                        {item.tags && item.tags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                size="small"
                                style={{backgroundColor:stringToRGB(tag)}}
                                sx={{ mr: 0.5 }}
                            />
                        ))}
                    </>}
                />
            </ListItemButton>
        </ListItem>
    );
};

export default function Prefabs({
    game,
    prefabsListReady,
    prefabsList,
    updateUserState,
    updateUserStateResponse,
    updatePrefab,
    updatePrefabResponse,
    deletePrefab,
    deletePrefabResponse,
    refetch,
    setSelectedPrefab,
    children
}) {
    const gameAlias = {cm: 'clockmaker'}

    const {
        data: metas,
        isLoading,
        isSuccess,
        isError,
        error,
        isFetching
    } = useGetPrefabsMetaQuery(gameAlias.hasOwnProperty(game)?gameAlias[game]:game);


    const stringToRGB = (str) => {
        // Хэшируем строку
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        // Преобразуем хэш в RGB и добавляем смещение для светлой палитры
        let r = ((hash >> 16) & 0xFF) + 128; // Минимальное значение 128
        let g = ((hash >> 8) & 0xFF) + 128;  // Минимальное значение 128
        let b = (hash & 0xFF) + 128;         // Минимальное значение 128

        // Убедимся, что значения не превышают 255
        r = r > 255 ? 255 : r;
        g = g > 255 ? 255 : g;
        b = b > 255 ? 255 : b;

        // Возвращаем RGB строку
        return `rgb(${r}, ${g}, ${b})`;
    }


    const CommonData = useSelector((state) => state.data.CommonData)
    let deviceId = CommonData.deviceId
    let vendorId = CommonData.stateId

    const [selectedItem, setSelectedItem] = useState({});
    const [itemFilter, setItemFilter] = useState({ name: "", environment: "", version: "", tags: "" });
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [state, setState] = useState({
        isConfirmOpen: false,
        isDeleteOpen: false,
        isPrefabEditOpened: false
    })
    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    function filterByName(data) {
        return data.filter(item => {
            return (
                item.name.includes(itemFilter.name) &&
                (itemFilter.environment === "" || item.environment === itemFilter.environment) &&
                (itemFilter.version === "" || item.version === itemFilter.version) &&
                (itemFilter.tags === "" || (item.tags && item.tags.includes(itemFilter.tags)))
            );
        });
    }

    function selectItem(item) {
        setSelectedItem(item)
        updatePrefabForm(item)
        if(setSelectedPrefab!=undefined) setSelectedPrefab(item)
    }

    function showConfirmDialog(item) {
        setSelectedItem(item)
        setState({
            ...state,
            isConfirmOpen: true
        })
    }

    function showDeleteDialog(item) {
        setSelectedItem(item)
        setState({
            ...state,
            isDeleteOpen: true
        })
    }

    async function handleConfirmApplyState(event) {
        setState({
            ...state,
            isConfirmOpen: false
        })

        let version = window.localStorage.getItem('isProd') == 'true' ? 'v2' : 'v2';

        if(game != 'cm') version = 'v2'

        let dataString = ''
        try {
            dataString = stringify(parse(selectedItem.data))
        } catch (e) {
            dataString = selectedItem.data
        }

        const response = await updateUserState({
            userId: deviceId,
            vendorId: vendorId,
            game: game,
            body: dataString,
            version: version
        })

        // setOpenSnackbar(response.error)

        if (response.error) {
            if (response.error.status === 403) {
                addAlert({text: 'This id is not in the whitelist', type: 'error'})
            } else {
                addAlert({text: 'Something went wrong', type: 'error'})
            }
        } else {
            addAlert({text: 'State applied', type: 'success'})
        }

    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    };


    async function updatePrefabHandler(selectedItem) {
        if(!selectedItem.hasOwnProperty('data') || selectedItem.data==null) return false;

        debugger

        selectedItem = {...selectedItem, data: stringify(parse(selectedItem.data))}

        const response = await updatePrefab({
            id: selectedItem.id,
            body: selectedItem
        });

        if (response.error) {
            if (response.error.status === 403) {
                addAlert({text: JSON.stringify(response.error), type: 'error'})
            } else {
                addAlert({text: 'Something went wrong ' + response.error.status, type: 'error'})
            }
        } else {
            addAlert({text: 'Prefab updated', type: 'success'})
        }
    }

    async function deletePrefabHandler(selectedItem) {

        const response = await deletePrefab(selectedItem.id);
        if (response.error) {
            if (response.error.status === 403) {
                addAlert({text: JSON.stringify(response.error), type: 'error'})
            } else {
                addAlert({text: 'Something went wrong ' + response.error.status, type: 'error'})
            }
        } else {
            addAlert({text: 'Prefab deleted', type: 'success'})
        }

        setSelectedItem({})
        setState({
            ...state,
            isDeleteOpen: false
        })
    }

    const [isValidJson, setIsValidJson] = useState(true);
    const handleJsonChange = (value, event) => {
        try {
            // Попытка парсить JSON
            const parsedJson = parse(value);
            // Если успешно, обновляем состояние и selectedItem
            setSelectedItem({
                ...selectedItem,
                data: stringify(parsedJson, null, 2)
            });
            setIsValidJson(true);
        } catch (error) {
            // В случае ошибки парсинга, просто сохраняем значение и обновляем состояние
            setSelectedItem({
                ...selectedItem,
                data: value
            });
            setIsValidJson(false);
        }
    }

    const sortVersions = (versions) => {
        let _versions = JSON.parse(JSON.stringify(versions))

        return _versions.sort((a, b) => {
            const aParts = a.split('.').map(Number);
            const bParts = b.split('.').map(Number);

            for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                const aPart = aParts[i] || 0;
                const bPart = bParts[i] || 0;

                if (aPart > bPart) return -1;
                if (aPart < bPart) return 1;
            }

            return 0;
        }).filter(item => !isEmpty(item));
    }

    const sortedVersions = metas!=undefined&&metas.hasOwnProperty('version') ? sortVersions(metas.version) : []

    let metaTags = []
    if(metas!=undefined&&prefabsList!=undefined) {

        if(metas.tags.length>0) metaTags = metas.tags
        else {
            metaTags = prefabsList.reduce((a, b) => {

                return a.concat(b.tags || [])
            }, []);
        }
    }

    const [prefabForm, updatePrefabForm] = useState({
        name: '',
        env: '',
        version: '',
        tags: [],
        environment:''
    })


    async function createPrefabHandler() {
        const request = {
            id: selectedItem.id,
            body: {
                "data": selectedItem.data,
                "name": prefabForm.name,
                "tags": prefabForm.tags,
                "version": prefabForm.version,
                "environment": prefabForm.environment
            },
        };
        debugger
        let response = false

        response = await updatePrefab(request)

        setState({...state, isPrefabEditOpened: false})



        alertMsgProcessor(response, addAlert)


    }


    return (
        <>
            <Card sx={{mb: 2}}>
                <CardContent>

                    {metas!=undefined && !isLoading && <Grid container spacing={2} sx={{mb:2}}>
                        <Grid item sm={2}>
                            <TextField
                                size={"small"}

                                label="Filter by name"
                                value={itemFilter.name}
                                onChange={(e) => setItemFilter({ ...itemFilter, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <Select
                                size={"small"}

                                fullWidth
                                displayEmpty
                                value={itemFilter.environment}
                                onChange={(e) => setItemFilter({ ...itemFilter, environment: e.target.value })}
                            >
                                <MenuItem value="">All Environments</MenuItem>
                                {metas?.environment?.filter(item => !isEmpty(item)).map((env, index) => (
                                    <MenuItem key={index} value={env}>{env}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item sm={3}>
                            <Select
                                size={"small"}

                                fullWidth
                                displayEmpty
                                value={itemFilter.version}
                                onChange={(e) => setItemFilter({ ...itemFilter, version: e.target.value })}
                            >
                                <MenuItem value="">All Versions</MenuItem>
                                {sortedVersions.map((version, index) => (
                                    <MenuItem key={index} value={version}>{version}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item sm={3}>
                            <Select
                                size={"small"}
                                fullWidth
                                displayEmpty
                                value={itemFilter.tags}
                                onChange={(e) => setItemFilter({ ...itemFilter, tags: e.target.value })}
                            >
                                <MenuItem value="">All Tags</MenuItem>
                                {metaTags.map((tag, index) => (
                                    <MenuItem key={index} value={tag}>{tag}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>}

                    <Grid container spacing={2}>
                        <Grid item sm={3}>


                            <List component="nav" className="compact-list" sx={{pt:0}}>
                                {prefabsListReady && filterByName(prefabsList).map((item) => (
                                    <>
                                        <MyListItem
                                            item={item}
                                            selectItem={selectItem}
                                            selectedItem={selectedItem}
                                            onDelete={deletePrefabHandler}
                                        />
                                        <Divider/>
                                    </>


                                ))}


                            </List>
                        </Grid>
                        <Grid item sm={9}>

                            {selectedItem.hasOwnProperty("id") &&
                                <div>
                                    <div>
                                        <Box sx={{mb: 3}}>
                                            <LoadingButton variant={"contained"}
                                                           disabled={!selectedItem.hasOwnProperty('id')}
                                                           loading={updateUserStateResponse.isLoading}
                                                           color={"success"}
                                                           sx={{mr: 1}}
                                                           variant={"outlined"}

                                                           startIcon={<CheckCircleIcon/>}
                                                           onClick={() => showConfirmDialog(selectedItem)}>Apply this</LoadingButton>

                                            <LoadingButton variant={"contained"}
                                                           disabled={!selectedItem.hasOwnProperty('id')}

                                                           loading={updatePrefabResponse.isLoading}
                                                           color={"info"}
                                                           sx={{mr: 1}}
                                                           startIcon={<SaveIcon />}
                                                           variant={"outlined"}

                                                           onClick={() => updatePrefabHandler(selectedItem)}>Save prefab</LoadingButton>

                                            <Button variant={"outlined"}
                                                    disabled={!selectedItem.hasOwnProperty('id')}

                                                    color={"info"}

                                                    startIcon={<ContentCopy />}
                                                    sx={{mr: 1}}

                                                    onClick={
                                                        () => {
                                                            navigator.clipboard.writeText(selectedItem.data)

                                                            addAlert({text: 'JSON Copied', type: 'success'})


                                                        }
                                                    }
                                            >Copy to clipboard</Button>


                                            <LoadingButton
                                                variant={"outlined"}
                                                           disabled={!selectedItem.hasOwnProperty('id')}


                                                           loading={deletePrefabResponse.isLoading}
                                                           color={"error"}
                                                           startIcon={<DeleteIcon/>}
                                                onClick={() => showDeleteDialog(selectedItem)}>Delete</LoadingButton>



                                        </Box>
                                    </div>

                                    <Box sx={{pb:2}}>
                                        <Chip
                                            label={`Date: ${selectedItem.date}`}
                                            size="small"
                                            sx={{ mr: 0.5 }}
                                        />
                                        <Chip
                                            label={`version: ${selectedItem.version}`}
                                            size="small"
                                            sx={{ mr: 0.5 }}
                                        />

                                        <Chip
                                            label={`env: ${selectedItem.environment}`}
                                            size="small"
                                            sx={{ mr: 0.5 }}
                                        />
                                        {selectedItem?.tags && selectedItem?.tags?.map((tag, index) => (
                                            <Chip
                                                key={index}
                                                label={tag}
                                                style={{backgroundColor: stringToRGB(tag)}}
                                                size="small"
                                                sx={{ mr: 0.5 }}
                                            />
                                        ))}

                                        <Link onClick={() => {
                                            setState({...state, isPrefabEditOpened:true});
                                            return false;
                                        }}
                                              href={'#'}
                                                sx={{
                                                    pl: 1,
                                                    textDecoration: 'underline dotted',
                                                    color: 'primary.main', // You can adjust this to match the color of a typical link
                                                    backgroundColor: 'transparent',
                                                    minWidth: 0, // Removes extra padding
                                                    '&:hover': {
                                                        textDecoration: 'underline dotted',
                                                        backgroundColor: 'transparent',
                                                    },
                                                }}
                                        >edit</Link>
                                    </Box>

                                    {game=='rt' && <PrefabsRTItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                    {game=='ba' && <PrefabsBAItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                    {game=='sc' && <PrefabsSCItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                    {game=='cm' && <PrefabsCMItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                    {game=='toh' && <PrefabsTOHItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                </div>
                            }

                        </Grid>
                    </Grid>


                </CardContent>

            </Card>
            <Dialog open={state.isConfirmOpen}>
                <DialogContent>
                    {deviceId == null ? <>Введите state id в панеле сверху и нажмите search</>:
                        <>Apply state <br/>
                            <strong>{selectedItem.name}</strong>
                            <br/> for state id:<br/>
                            <strong>{deviceId}</strong>
                            <br/> and vendor Id:<br/>
                            <strong>{vendorId}</strong></>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={() => {
                            setState({...state, isConfirmOpen: false})
                        }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={handleConfirmApplyState}
                        variant="outlined"
                        color="warning"
                    >
                        APPLY
                    </LoadingButton>

                </DialogActions>
            </Dialog>

            <Dialog open={state.isDeleteOpen}>
                <DialogContent>
                        Delete prefab  <br/>
                        <strong>{selectedItem.name}</strong>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={() => {
                            setState({...state, isDeleteOpen: false})
                        }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={() => { deletePrefabHandler(selectedItem) }}
                        variant="outlined"
                        color="warning"
                    >
                        DELETE
                    </LoadingButton>

                </DialogActions>
            </Dialog>



            <Dialog sx={{ '& .MuiDialog-paper': { width: '400px' } }} open={state.isPrefabEditOpened}>
                <DialogTitle>
                    Update prefab

                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={2}>


                        <Grid item sx={{mt:1}}>
                            <TextField
                                label="Name"
                                value={prefabForm.name}
                                required={true}
                                onChange={(event) => {
                                    updatePrefabForm({
                                        ...prefabForm,
                                        name: event.target.value
                                    })
                                }}></TextField>
                        </Grid>

                        <Grid item>
                            <Autocomplete
                                options={metas?.environment || []}
                                freeSolo
                                value={prefabForm.environment}
                                onChange={(event, newValue) => {
                                    updatePrefabForm({
                                        ...prefabForm,
                                        environment: newValue
                                    });
                                }}
                                onInputChange={(event, newInputValue) => {
                                    updatePrefabForm({
                                        ...prefabForm,
                                        environment: newInputValue || '' // Save input value
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Environment" variant="outlined" fullWidth />
                                )}
                            />
                        </Grid>

                        <Grid item>
                            <Autocomplete
                                options={sortedVersions || []}
                                freeSolo
                                value={prefabForm.version}
                                onChange={(event, newValue) => {
                                    updatePrefabForm({
                                        ...prefabForm,
                                        version: newValue
                                    });
                                }}
                                onInputChange={(event, newInputValue) => {
                                    updatePrefabForm({
                                        ...prefabForm,
                                        version: newInputValue || '' // Save input value
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Version" variant="outlined" fullWidth />
                                )}
                            />
                        </Grid>

                        <Grid item>
                            <Autocomplete

                                multiple
                                options={metas?.tags || []}
                                freeSolo
                                value={prefabForm.tags || []}
                                onChange={(event, newValue) => {

                                    updatePrefabForm({
                                        ...prefabForm,
                                        tags: newValue || []
                                    });
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} key={index} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField helperText="Use , (comma) or 'Enter' to split tags"
                                                                               {...params} label="Tags" variant="outlined" fullWidth />
                                )}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (newInputValue.endsWith(',')) {
                                        // Split the input based on commas and trim the values
                                        const splitValues = newInputValue
                                            .split(',')
                                            .map(tag => tag.trim())
                                            .filter(tag => tag.length > 0);

                                        // Combine with existing tags, ensuring no duplicates
                                        const updatedTags = [...new Set([...prefabForm.tags, ...splitValues])];

                                        // Update the form state with new tags
                                        updatePrefabForm({
                                            ...prefabForm,
                                            tags: updatedTags
                                        });

                                        // Clear the input field by setting the input value to an empty string
                                        setTimeout(() => {
                                            event.target.value = '';
                                        }, 10);
                                    }
                                }}
                            />
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>

                    <Button
                        color="secondary"
                        onClick={() => {
                            setState({...state, isPrefabEditOpened: false})
                        }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={createPrefabHandler}
                        variant="outlined"
                        color="warning"
                        loading={updatePrefab.isLoading}
                        disabled={prefabForm.name=='' || prefabForm.env==''}
                    >
                        SAVE
                    </LoadingButton>

                </DialogActions>
            </Dialog>
        </>
    );
}

Prefabs.propTypes = {
    prefabsListReady: PropTypes.bool.isRequired,
    prefabsList: PropTypes.array.isRequired,
    updateUserState: PropTypes.func.isRequired,
    updateUserStateResponse: PropTypes.object.isRequired,
    updatePrefab: PropTypes.func.isRequired,
    updatePrefabResponse: PropTypes.object.isRequired,
    deletePrefab: PropTypes.func.isRequired,
    deletePrefabResponse: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired

};
