import Loading from "../../../components/Common/Loading";

import {
    useDeletePrefabV2TOHMutation,
    useGetPrefabsListV2TOHQuery,
    useGetPrefabsInfosV2TOHQuery,
    useUpdatePrefabV2TOHMutation
} from "../../../core/api/toh-prefab-api";
import {useCreateStateV2TOHMutation} from "../../../core/api/toh-state-api";
import Prefabs from "../Prefabs";
import {useState} from "react";

function PrefabsTOH(props) {

    const [updateUserState, updateUserStateResponse] = useCreateStateV2TOHMutation();
    const [updatePrefab, updatePrefabResponse] = useUpdatePrefabV2TOHMutation();
    const [deletePrefab, deletePrefabResponse] = useDeletePrefabV2TOHMutation();
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
        isFetching
    } = useGetPrefabsInfosV2TOHQuery();

    const [selectedPrefab, setSelectedPrefab] = useState("")

    return (
        <>
            <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}
            >
                <Prefabs prefabsListReady={isSuccess}
                         prefabsList={data}
                         updateUserState={updateUserState}
                         updateUserStateResponse={updateUserStateResponse}
                         updatePrefab={updatePrefab}
                         updatePrefabResponse={updatePrefabResponse}
                         deletePrefab={deletePrefab}
                         deletePrefabResponse={deletePrefabResponse}
                         refetch={refetch}
                         game={props.game}
                         setSelectedPrefab={setSelectedPrefab}
                >
                </Prefabs>
            </Loading>

        </>
    );

}

export default PrefabsTOH;
