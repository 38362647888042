import {DataGrid} from "@mui/x-data-grid";
import {ListItem} from "@mui/material";

function ChargerLogs(props) {

    return (
        <>
            <DataGrid
                rowHeight={82}
                autoHeight={true}
                rows={props.response.chargeLogs ? props.response.chargeLogs : []}
                columns={props.columns}
                pageSize={props.state.pageSize}
                onPageChange={(newPage) => props.setState({
                    ...props.state,
                    page: newPage
                })}
                pagination={true}
                paginationMode={'server'}
                rowCount={props.response.totalPages * props.state.pageSize}
            />
        </>
    );
}

export default ChargerLogs;
