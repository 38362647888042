import React from 'react';

import {Table, TableBody, TableCell, TableRow} from "@mui/material";


const SimpleTable = (props) => {

    return (
        <Table>
            <TableBody>
                {props.data.map((row) => (
                    <TableRow>
                        <TableCell>{row[0]}</TableCell>
                        <TableCell>{row[1]}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )

}

export default SimpleTable;
