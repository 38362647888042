import React from "react"

import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {format} from "date-fns";

const PfLocationsTable = ({locations}) => {

    console.log('locations')

    const columns = [
        {
            label: "ID",
            field: "id"
        },
        {
            label: "Name",
            field: "name"
        },
        {
            label: "Unlocked timestamp",
            field: "unlockedTimeStamp"
        },
        {
            label: "Visited",
            field: "visited"
        }
    ]

    const rows = Object.entries(
        locations.items
    ).map(([id, location]) => ({
        id: id,
        name: id,//state.guidMap["Locations"][id],
        unlockedTimeStamp: location.unlockedTimeStamp,
        visited: location.visited.toString(),
        clickEvent: () => {

        }
    }))

    console.log(rows)

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>name</TableCell>
                    <TableCell>unlockedTimeStamp</TableCell>
                    <TableCell>visited</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{format(Date.parse(row.unlockedTimeStamp), "dd.MM.yyyy HH:mm:ss")}</TableCell>
                        <TableCell>{row.visited}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>

    )
}

export default PfLocationsTable
