import {Box, Button, Card, Dialog} from "@mui/material";
import {CMItems} from "../../../core/utils/constants";
import React, {useState} from "react";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import CmChargeBlock from "../../Charger/cm/cmChargeBlock";

export default function CollectionItems({items}) {

    const [selectedRows, setSelectedRows] = useState([]);
    const disable = selectedRows.length === 0

    const [chargeItemsDialog, setChargeItemsDialog] = useState(false);

    const columns: GridColumns = [
        {field: 'name', headerName: 'Name', hide: false, width: 700},
        {field: 'data', headerName: 'Data', hide: false, width: 700},
    ]

    const handleSelectRows = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = rows.filter((row) =>
            selectedIDs.has(row.id.toString()));
        setSelectedRows(selectedRowData);
    }

    let rows = [];
    if (items) {
        rows = Object.entries(items).map((([key, value]) => (
            {
                id: CMItems.Collections[value.Id] !== undefined ? CMItems.Collections[value.Id] : value.Id,
                data: value.Count,
                type: "items",
                category: "Collections",
                name: CMItems.Collections[value.Id] !== undefined ? CMItems.Collections[value.Id] : value.Id,
            })));
    }
    return (
        <Card>
            {/*<Button*/}
            {/*    disabled={disable}*/}
            {/*    variant={'contained'}*/}
            {/*    color={"success"}*/}
            {/*    onClick={() => setChargeItemsDialog(!chargeItemsDialog)}>*/}
            {/*    Charge items*/}
            {/*</Button>*/}
            <Box sx={{width: '100%', height: 600}}>
                <DataGrid
                    checkboxSelection={true}
                    onSelectionModelChange={(ids) => handleSelectRows(ids)}
                    columns={columns}
                    rows={rows}>
                </DataGrid>
            </Box>
            <Dialog open={chargeItemsDialog} fullWidth={true}>
                <CmChargeBlock
                    massCharge={false}
                    initialSelectedItems={selectedRows}
                />

                <Button
                    disabled={disable}
                    variant={'contained'}
                    color={"success"}
                    onClick={() => setChargeItemsDialog(!chargeItemsDialog)}>
                    Cancel
                </Button>

            </Dialog>
        </Card>
        // <Card>
        //     <Table>
        //         <TableBody>
        //             {rows.map((row) => (
        //                 <TableRow>
        //                     <TableCell>{row.id}</TableCell>
        //                     <TableCell>{row.count}</TableCell>
        //                 </TableRow>
        //             ))}
        //         </TableBody>
        //     </Table>
        // </Card>
    );
}