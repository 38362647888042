import React, {useEffect, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import SimpleTable from "../../components/Common/Elements";

import {Card, CardContent, Grid, Typography,} from "@mui/material";


import Loading from "../../components/Common/Loading";
import {useGetStateV2RTQuery} from "../../core/api/rogerthat-state-api";
import {getDeviceIdAbsentWarning, paymentUserStatus} from "../../core/utils/constants";
import TechInfoRT from "../TechInfo/techInfoRT";
import {format} from "date-fns";
import {isEmpty} from "../../core/utils/StringUtils";
import {SnackBarContext} from "../../snackBarProvider";
import {Alert} from "@mui/lab";
import UserSocialInfoRT from "../UserInfo/userSocialInfoRT";


function DashboardRT() {

    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId;
    const stateId = CommonData.stateId;
    const deviceIdIsEmpty = (isEmpty(deviceId) || typeof deviceId == 'object') && isEmpty(stateId);

    const {
        data: obj,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetStateV2RTQuery({deviceId, stateId})

    const getByName = (items = [], name) => items.find((item) => item.Name === name)?.Amount

    let panel1 = ""

    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    if (typeof obj === "object" && obj.hasOwnProperty('data')) {

        const data = obj.data
        if((data.hasOwnProperty('userID') || data.hasOwnProperty('UserID'))) {
            let source = {
                account_info: [],
                progress_info: [],
                payment_info: [],
                ingame_goods: []
            }


            try {
                source = {
                    account_info: [
                        ["User ID", data.UserID],
                        //["SocialID", data.SocialID],
                        ["User Seed", data.UserSeed],
                        ["Timespend", data.Timespend],
                        ["Timestamp", format(new Date(data.Timestamp * 1000), "dd.MM.yyyy HH:mm:ss")],
                        ["SaveID", data.SaveID],
                        ["InstallVersion", data.InstallVersion],
                        ["Current version", data.CurrentVersion],
                        ["FacebookProfile", data.Records.FacebookProfile?.Connected?.toString()]
                    ],
                    payment_info: [

                        ["Сумма платежей", data.Records.PaymentProfile.PaymentSum],
                        ["Segment", paymentUserStatus(data.Records.PaymentProfile.PaymentSum)],
                        ["Payment Count", data.Records.PaymentProfile.PaymentCnt],
                        ["Payment Date", format(new Date(data.Records.PaymentProfile.PaymentDate * 1000), "dd.MM.yyyy HH:mm:ss")],
                        ["Payment Least", data.Records.PaymentProfile.PaymentLst],
                        ["Payment Max", data.Records.PaymentProfile.PaymentMax],
                    ],
                    progress_info: [
                        ["Current Location", data.Records.LocationProfile.Location],
                        ["Level", data.Records.PlayerRecord.Level],
                        ["main_quest", data.Records.StorageProfile.Storage.main_quest],
                    ],
                    ingame_goods: []

                }
            } catch (e) {
                console.log(e)
                return <Alert severity={"error"}>Can't parse state</Alert>
            }


            panel1 = <Grid container spacing={2}>
                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Account info
                            </Typography>
                        </CardContent>

                        <SimpleTable data={source.account_info}/>
                    </Card>

                </Grid>


                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Progress info
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.progress_info}/>
                    </Card>
                </Grid>

                <Grid item sm={4}>
                    <TechInfoRT isSimple={true} deviceId={deviceId}/>
                </Grid>


                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Payment info
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.payment_info}/>
                    </Card>
                </Grid>

                <Grid item sm={6}>
                    <UserSocialInfoRT deviceId={deviceId}/>
                </Grid>

                <Grid item sm={4}>
                </Grid>
            </Grid>

        }



    } else if (typeof obj === "object" && obj.data.hasOwnProperty('error')) {
        panel1 = obj.data.error
    } else {
        panel1 = 'unknown error'
    }

    return (
        <Loading
            isLoading={isLoading||isFetching}
            isError={isError}
            error={error}
        >
            <div>{panel1}</div>
        </Loading>
    );

}

export default DashboardRT
