import api from "./base-api"

const clockmakerClansApiV2CM = api.injectEndpoints({
    endpoints: (build) => ({
        getMembershipCM: build.query({
            query: (data = "") => `v2/clockmaker/clans/members/${data}`,
            providesTags: ['ClanMembersCM']
        }),
        getChatCM: build.query({
            query: (data = "") => `v2/clockmaker/clans/chat/${data}/0`,
        }),

        kickCM: build.mutation({
            query: (data = {}) => ({
                url: `v2/clockmaker/clans/kick/${data.userId}/${data.kickId}`,
                method: "POST",
            }),
            invalidatesTags: ['ClanMembersCM'],
        }),

        joinCM: build.mutation({
            query: (data = {}) => ({
                url: `v2/clockmaker/clans/join/${data.userId}/${data.community}`,
                method: "POST",
            }),
            invalidatesTags: ['ClanMembersCM'],
        }),

        promoteCM: build.mutation({
            query: (data) => ({
                url: `v2/clockmaker/clans/promote/${data.userId}/${data.promoteId}`,
                method: "POST",
            }),
            invalidatesTags: ['ClanMembersCM'],
        }),

        setCheaterCM: build.mutation({
            query: (body = {}) => ({
                url: `v2/clockmaker/clans/cheater`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['ClanMembersCM'],
        }),
        setRenameCM: build.mutation({
            query: (body = {}) => ({
                url: `v2/clockmaker/clans/rename`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['ClanMembersCM'],
        })
    }),
    overrideExisting: false
})

export const {
    useGetMembershipCMQuery,
    useGetChatCMQuery,
    useSetCheaterCMMutation,
    useSetRenameCMMutation,
    useKickCMMutation,
    useJoinCMMutation,
    usePromoteCMMutation
} = clockmakerClansApiV2CM
