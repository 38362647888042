import React, {useEffect} from 'react';

import {NavLink, Route, Routes, useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useGetGuidMapQuery} from "../../core/api/bermuda-charge-api";
import UserStates from "../UserStates";
import Charger from "../Charger/ba";
import AllBaChargerLogs from "../ChargerLogs/ba/AllBaChargerLogs";
import PrefabsBa from "../Prefabs/ba/PrefabsBa";
import WhiteListManagement from "../WhiteListManagement";
import {Box, Drawer, MenuItem, MenuList, Toolbar} from "@mui/material";
import SearchBar from "../Search";
import Loading from "../../components/Common/Loading";
import DashboardBA from "../Dashboard/ba";
import UserStatesBA from "../UserStates/ba";
import ChargeManagement from "../ChargeManagement/ba";
import ArchiveRT from "../Archive/rt/ArchiveRT";
import ArchiveBA from "../Archive/ba/ArchiveBA";
import SearchBarBA from "../Search/SearchBA";
import AdvancedSearchCM from "../AdvancedSearch/AdvancedSearchCM";
import AdvancedSearchBA from "../AdvancedSearch/AdvancedSearchBA";

export default function MainBA() {
    const {game, action} = useParams();

    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId ? CommonData.deviceId : '';
    const stateId = CommonData.stateId ? CommonData.stateId : '';


    const {
        data: guidMap,
        isLoading: isLoadingGuidMap,
        isSuccess: isSuccessGuidMap,
        isError: isErrorGuidMap,
        error: errorGuidMap
    } = useGetGuidMapQuery()

    const main_menu = [
        {
            to: '/' + game + '/dashboard/' + deviceId,
            path: 'dashboard/:deviceId?',
            title: 'Dashboard',
            element: <DashboardBA/>
        },
    ];
    const state_menu = [
        {
            to: '/' + game + '/state/' + deviceId + '/' + stateId,
            path: 'state/:deviceId?/:tab?',
            title: 'UserStates',
            element: <UserStatesBA/>,
            // disabled: deviceId == ''
        },
        {to: '/' + game + '/charger/' + deviceId + '/' + stateId, path: 'charger/:deviceId?', title: 'Charger', element: <Charger/>},

        {to: '/' + game + '/logs', path: 'logs', title: 'ChargerLogs', element: <AllBaChargerLogs/>},
        //{to:'reports', title:'Reports',component:'<Reports />'},

    ]
    const common_menu = [
        {
            to: '/' + game + '/prefabs/' + deviceId + '/' + stateId,
            path: "prefabs/:deviceId?",
            title: 'Prefabs',
            element: <PrefabsBa game={game}/>
        },
        {
            to: '/' + game + '/archive/' + deviceId,
            path: "archive/:deviceId?",
            title: 'Archive',
            element: <ArchiveBA game={game}/>
        },
        {
            to: '/' + game + '/search',
            path: "search",
            title: 'Search',
            element: <AdvancedSearchBA game={game}/>
        },
    ]
    const admin_menu = [
        {
            to: '/' + game + '/chargeManagement',
            path: "chargeManagement",
            title: 'Charge Management',
            element: <ChargeManagement game={game}/>
        },
        {
            to: '/' + game + '/whitelistManagement',
            path: "whitelistManagement",
            title: 'White list management',
            element: <WhiteListManagement/>
        },
    ]

    let menu = [
        ...main_menu,
        ...state_menu,
        ...common_menu
    ]


    const routes = (
        <Route>

            {menu.map((object) => (
                <Route path={object.path} element={object.element}/>
            ))}
            {admin_menu.map((object) => (
                <Route path={object.path} element={object.element}/>
            ))}
        </Route>
    );


    return (
        <>
            <div className="wrapper">
                <Drawer
                    sx={{
                        width: 230,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: 230, boxSizing: 'border-box'},

                    }}
                    variant="permanent"
                >
                    <Toolbar/>

                    <MenuList>
                        {menu.map((object) => (
                            <MenuItem
                                component={NavLink}
                                to={object.to}
                                disabled={object.disabled}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                {object.title}
                            </MenuItem>
                        ))}
                        <hr/>
                        {admin_menu.map((object) => (
                            <MenuItem
                                component={NavLink}
                                to={object.to}
                                disabled={object.disabled}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                {object.title}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Drawer>
                <Box component="main"
                     sx={{flexGrow: 1, p: 3}}
                >
                    <Toolbar/>

                    {['chargeManagement', 'whitelistManagement','search'].indexOf(action) == -1 &&
                        <Box display="flex" alignItems="center" sx={{mb: 2}}>
                            <SearchBarBA/>
                        </Box>
                    }

                    <Loading isLoading={isLoadingGuidMap} isError={isErrorGuidMap} error={errorGuidMap}>
                        <Box mb={2}>
                            <Routes>
                                {routes}
                            </Routes>
                        </Box>
                    </Loading>
                </Box>
            </div>
        </>

    )
}
