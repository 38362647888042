import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from "react-redux";
// import './styles.scss'
import {useLocation, useNavigate} from "react-router"

import MainCM from "../Main/cm";
import MainBA from "../Main/ba";
import MainSC from "../Main/sc";
import MainRT from "../Main/rt";
import MainTOH from "../Main/toh";

const routes = [{path: ":game/*"}]


function GameActions() {
    const {game, action, deviceId} = useParams()

    console.log(useParams())

    let game_component = ""
    switch (game) {
        case "cm":
            game_component = <MainCM/>
            break;
        case "ba":
            game_component = <MainBA/>
            break
        case "sc":
            game_component = <MainSC/>
            break
        case "rt":
            game_component = <MainRT/>
            break
        case "toh":
            game_component = <MainTOH/>
            break
        default:
            game_component = "nothing?"
    }


    const location = useLocation();
    const dispatch = useDispatch();

    let navigate = useNavigate();

    const availableGames = ["cm", "ba", "sc", "rt", "toh"];

    useEffect(() => {
        if (!availableGames.includes(game)) {
            navigate("/");
        }
    }, [game, navigate, availableGames]);


    return (
        <>

            {game_component}

        </>
    );

}


export default GameActions;
