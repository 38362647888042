import PropTypes from 'prop-types';
import {Box} from "@mui/material";
import {useGetGuidMapQuery} from "../core/api/bermuda-charge-api";
import {getQuestName} from "../core/utils/GuidMapUtil";
import {format} from "date-fns";

export default function ChargeItems(props) {
    const {data, ignoreAttributes} = props;

    const {
        data: guidMap,
    } = useGetGuidMapQuery();

    let Content = []

    for (const key in data) {
        if (ignoreAttributes.indexOf(key) === -1) {
            if (key === "questKey") {
                Content.push(<div className={"charger-logs-data"}>questName: {getQuestName(data[key], guidMap)} </div>)
            } else if (key === "endTime" && data[key] !== undefined && data[key] !== null) {
                Content.push(<div
                    className={"charger-logs-data"}>{key}: {format(Date.parse(data[key]), "dd.MM.yyyy HH:mm:ss")}</div>)
            } else if (key == "mockSize") {
                Content.push(<div className={"charger-logs-data"}>{key}: [{data[key].x}, {data[key].y}] </div>)
            } else {
                Content.push(<div className={"charger-logs-data"}>{key}: {data[key]} </div>)
            }
        }
    }

    return (
        <Box sx={{mt: 1, mb: 1, display: 'flex', flexDirection: "column"}}>
            {Content}
        </Box>
    )
}

ChargeItems.propTypes = {
    data: PropTypes.object.isRequired,
    ignoreAttributes: PropTypes.array.isRequired,
}