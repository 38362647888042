import api from "./base-api"
import {v4 as uuidv4} from "uuid";

const tohAnalyticalDataApiV1 = api.injectEndpoints({
    endpoints: (build) => ({
        getConsumablesV1TOH: build.query({
            query: (
                data
            ) => {
                return `v1/toh/analytical-data/${data.userId}/consumables?limit=${data.limit}&page=0&from=${data.from}&to=${data.to}`
            },
            transformResponse: (response) => {
                const rows = response.map((row) => ({id: uuidv4(), ...row}));
                return {rows};
            },
        }),
        getEnvironmentsV1TOH: build.query({
            query: (
                userId = "",
                limit = 1,
                page = 0
            ) => `v1/toh/analytical-data/${userId}/environments?limit=${limit}&page=${page}`
        }),
        getLevelsV1TOH: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/toh/analytical-data/${userId}/levels?limit=${limit}&page=${page}`
        }),
        getPaymentsV1TOH: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/toh/analytical-data/${userId}/payments?limit=${limit}&page=${page}`
        }),
        getSpendItemsTOH: build.query({
            query: (
                data
            ) => `v1/toh/analytical-data/${data.userId}/spendItems?limit=${data.limit}&page=${data.page}`
        }),
        getPurchaseItemsTOH: build.query({
            query: (
                data
            ) => `v1/toh/analytical-data/${data.userId}/purchaseItems?limit=${data.limit}&page=${data.page}`
        }),
        getSpendGroupItemsTOH: build.query({
            query: (
                data
            ) => `v1/toh/analytical-data/${data.userId}/spendGroup`
        }),
        getBattlesV1TOH: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/toh/analytical-data/${userId}/battles?limit=${limit}&page=${page}`
        }),
        getPurchasesV1TOH: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/toh/analytical-data/${userId}/purchases?limit=${limit}&page=${page}`
        }),
        getUserInfoV1TOH: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/toh/analytical-data/${userId}/user-info?limit=${limit}&page=${page}`
        }),
    }),
    overrideExisting: false
})

export const {
    useGetConsumablesV1TOHQuery,
    useGetEnvironmentsV1TOHQuery,
    useGetUserInfoV1TOHQuery,
    useGetLevelsV1TOHQuery,
    useGetPaymentsV1TOHQuery,
    useGetSpendGroupItemsTOHQuery,
    useGetPurchaseItemsTOHQuery,
    useGetSpendItemsTOHQuery,
    useGetBattlesV1TOHQuery,
    useGetPurchasesV1TOHQuery
} = tohAnalyticalDataApiV1
