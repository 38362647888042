import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {isExists} from "../../core/utils/constants";
import Loading from "../../components/Common/Loading";
import ChargeItems from "../ChargerItem";


const PfChargesTable = ({charges}) => {

    const rows = Object.entries(
        charges
    ).map(([id, charge]) => ({
        id: charge.Id,
        type: charge.Type,
        charged: charge.Charged,
        data: charge.hasOwnProperty("Data") ? charge.Data : {}
    }))


    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Charge id</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Charged</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Count</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{row.charged}</TableCell>

                        <TableCell>
                            <ChargeItems data={row.data}
                                         ignoreAttributes={["count", "endTime", "keys"]}/>

                        </TableCell>

                        <TableCell>{row.hasOwnProperty("data") && isExists(row.data.count) ? row.data.count : (isExists(row.count) ? row.count : "no data")}</TableCell>

                    </TableRow>
                ))}
            </TableBody>
        </Table>

    )
}

const CmChargesTable = ({charges}) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Charge id</TableCell>
                    <TableCell>Charged</TableCell>
                    <TableCell>Consumables</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {charges.map((row) => (
                    <TableRow>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.charged}</TableCell>
                        <TableCell>{JSON.stringify(row.consumables)}</TableCell>
                        <TableCell>{JSON.stringify(row.actions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>

    )
}

const ScChargesTable = ({charges}) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Charge id</TableCell>
                    <TableCell>User id</TableCell>
                    <TableCell>Reward</TableCell>
                    <TableCell>Charged</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {charges.map((row) => (
                    <TableRow>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{JSON.stringify(row.userID)}</TableCell>
                        <TableCell>{JSON.stringify(row.reward)}</TableCell>
                        <TableCell>{row.charged}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}


const ChargesList = (props) => {


    let charges = ""

    if (typeof props.chargesData === "object" && typeof props.chargeTypes === "object" && typeof props.guidMap === "object") {
        let data = props.chargesData.map(i => {

            let filtered = props.chargeTypes.filter(j => j.value === i.Type)
            if (filtered.length != 0) {
                return {
                    ...i,
                    guidMapType: filtered[0].guidMap,
                    guidMapType2: filtered[0].guidMap2,
                    guidMapKey: filtered[0].key
                        ? filtered[0].key
                        : filtered[0].key2,
                    guidMapKey2: filtered[0]?.key2
                }
            }
            return false;
        }).map(i => {
            let newObj = JSON.parse(JSON.stringify(i));
            debugger
            if (props.guidMap.hasOwnProperty(i.guidMapType) && i.Data.hasOwnProperty(i.guidMapKey)) {
                newObj.Data['name'] = props.guidMap[i.guidMapType].filter(j => j.guid === i.Data[i.guidMapKey])[0]?.name;
                if(i.guidMapType2 != '' && props.guidMap[i.guidMapType2] != undefined)
                    newObj.Data['name2'] = props.guidMap[i.guidMapType2].filter(j => j.guid === i.Data[i.guidMapKey2])[0]?.name;
            }

            return newObj
        })

        charges = <PfChargesTable charges={data}/>
    } else if (typeof props.chargesData === "object" && typeof props.chargeTypes !== "object" && typeof props.guidMap !== "object") {
        charges = <CmChargesTable charges={props.chargesData}/>
    } else if (props.game?.toUpperCase() === 'SC') {
        charges = <ScChargesTable charges={props.chargesData}/>
    } else {
        charges = "No charges"
    }

    return (
        <Loading
            isLoading={props.chargesLoading}
            isError={props.chargesIsError}
            error={props.chargesError}
            isSuccess={props.chargesSuccess}
        >
            {charges}
        </Loading>
    )
}

export default ChargesList