import Loading from "../../../components/Common/Loading";

import {
    useDeletePrefabV2RTMutation,
    useGetPrefabsListV2RTQuery,
    useGetPrefabsInfosV2RTQuery,
    useUpdatePrefabV2RTMutation
} from "../../../core/api/rogerthat-prefab-api";
import {useCreateStateV2RTMutation} from "../../../core/api/rogerthat-state-api";
import Prefabs from "../Prefabs";
import {useState} from "react";

function PrefabsRT(props) {

    const [updateUserState, updateUserStateResponse] = useCreateStateV2RTMutation();
    const [updatePrefab, updatePrefabResponse] = useUpdatePrefabV2RTMutation();
    const [deletePrefab, deletePrefabResponse] = useDeletePrefabV2RTMutation();
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
        isFetching
    } = useGetPrefabsInfosV2RTQuery();

    const [selectedPrefab, setSelectedPrefab] = useState("")

    return (
        <>
            <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}
            >
                <Prefabs prefabsListReady={isSuccess}
                         prefabsList={data}
                         updateUserState={updateUserState}
                         updateUserStateResponse={updateUserStateResponse}
                         updatePrefab={updatePrefab}
                         updatePrefabResponse={updatePrefabResponse}
                         deletePrefab={deletePrefab}
                         deletePrefabResponse={deletePrefabResponse}
                         refetch={refetch}
                         game={props.game}
                         setSelectedPrefab={setSelectedPrefab}
                >
                </Prefabs>
            </Loading>

        </>
    );

}

export default PrefabsRT;
