// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
// import configs from "../../constants";
//
// const api = createApi({
//     baseQuery: fetchBaseQuery({
//         baseUrl: configs.baseUrl,
//         prepareHeaders: (headers) => {
//             const token = sessionStorage.getItem("kctoken")
//             if (token) headers.set("Authorization", `Bearer ${token}`)
//             return headers
//         }
//     }),
//     endpoints: () => ({})
// })
//
// export default api
//


import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import configs from "../../constants";

const rawBaseQuery = fetchBaseQuery({
    baseUrl: 'http://localhost:8000/',
    prepareHeaders: (headers) => {
        const token = sessionStorage.getItem("kctoken")
        if (token) headers.set("Authorization", `Bearer ${token}`)
        return headers
    }
})

const dynamicBaseQuery = (args, api, extraOptions) => {
    const urlEnd = typeof args === 'string' ? args : args.url
    // construct a dynamically generated portion of the url
    let adjustedUrl = ''

    let env = '';
    if (args.hasOwnProperty('env') && ['prod', 'local', 'staging'].indexOf(args.env) !== -1) {
        env = args.env
    } else {
        if (window.localStorage.getItem('isProd') == 'true') {
            env = 'prod'
        } else if (document.location.host == 'localhost:3000') {
            env = 'local'
        } else {
            env = 'staging'
        }
    }

    adjustedUrl = configs.allConfigs[env].baseUrl + urlEnd

    const adjustedArgs =
        typeof args === 'string' ? adjustedUrl : {...args, url: adjustedUrl}

    // provide the amended url and other params to the raw base query
    return rawBaseQuery(adjustedArgs, api, extraOptions)
}

const api = createApi({
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({})
})

export default api
