import {format} from "date-fns";
import ChargeItems from "../ChargerItem";
import UserIdsListComponent from "../UserIdsListComponent";
import {Box, List, ListItem} from "@mui/material";

const columns = {
    BA: [
        {field: 'ind', headerName: 'id', hide: true},
        {
            field: 'timestamp', headerName: 'Date', width: 150,
            renderCell: (params) =>
                <div>{format(Date.parse(params.row.timestamp), "dd.MM.yyyy HH:mm:ss")}</div>
        },
        {field: 'type', headerName: 'Type', width: 130},
        {
            field: 'data', headerName: 'Charger\'s data', width: 350,
            renderCell: (params) => {
                let dataObject = JSON.parse(params.row.data);
                let data = Object.assign({},
                    dataObject,
                    {name: params.row.displayName})
                return <ChargeItems data={data}
                                    ignoreAttributes={["keys"]}/>
            }
        },

        {
            field: 'userIds', headerName: 'Device ids', width: 300,
            renderCell: (params) => {
                return <UserIdsListComponent data={params.row.userIds}/>
            }
        },
        {field: 'employeeEmail', headerName: 'Employee email', width: 300},

    ],
    CM: [
        {field: 'ind', headerName: 'id', hide: true},
        {
            field: 'timestamp', headerName: 'Date', width: 150,
            renderCell: (params) =>
                <div>{format(Date.parse(params.row.timestamp), "dd.MM.yyyy HH:mm:ss")}</div>
        },
        {
            field: 'consumables', headerName: 'Consumables', width: 350,
            renderCell: (params) => {

                let Content = []

                for (let consumable of params.row.data.consumables) {
                    for (let key in consumable) {
                        Content.push(<ListItem>{key} : {consumable[key]}</ListItem>)
                    }
                }

                return (
                    <Box sx={{
                        mt: 1,
                        mb: 1,
                        display: 'flex',
                        flexDirection: "column",
                        maxHeight: '100%',
                        overflowY: "scroll",
                        width: '100%'
                    }}>
                        <List>
                            {Content}
                        </List>
                    </Box>
                )
            }
        },

        {
            field: 'actions', headerName: 'Actions', width: 350,
            renderCell: (params) => {

                let Content = []

                for (let action of params.row.data.actions) {
                    for (let key in action) {
                        Content.push(<ListItem>{key} : {JSON.stringify(action[key])}</ListItem>)
                    }
                }

                return (
                    <Box sx={{
                        mt: 1,
                        mb: 1,
                        display: 'flex',
                        flexDirection: "column",
                        maxHeight: '100%',
                        overflowY: "scroll",
                        width: '100%'
                    }}>
                        <List>
                            {Content}
                        </List>
                    </Box>
                )
            }
        },


        {
            field: 'userIds', headerName: 'Device ids', width: 300,
            renderCell: (params) => {
                return <UserIdsListComponent data={params.row.userIds}/>
            }
        },
        {field: 'email', headerName: 'Employee email', width: 300},

    ],
    RT: [
        {field: 'ind', headerName: 'id', hide: true},
        {
            field: 'timestamp', headerName: 'Date', width: 150,
            renderCell: (params) =>
                <div>{format(Date.parse(params.row.timestamp), "dd.MM.yyyy HH:mm:ss")}</div>
        },
        {field: 'type', headerName: 'Type', width: 130},
        {
            field: 'data', headerName: 'Charger\'s data', width: 350,
            renderCell: (params) => {
                let dataObject = JSON.parse(params.row.data);
                let data = Object.assign({},
                    dataObject,
                    {name: params.row.displayName})
                return <ChargeItems data={data}
                                    ignoreAttributes={["keys"]}/>
            }
        },

        {
            field: 'userIds', headerName: 'Device ids', width: 300,
            renderCell: (params) => {
                return <UserIdsListComponent data={params.row.userIds}/>
            }
        },
        {field: 'employeeEmail', headerName: 'Employee email', width: 300},

    ],
    SC: [
        {field: 'ind', headerName: 'id', hide: true},
        {
            field: 'timestamp', headerName: 'Date', width: 150,
            renderCell: (params) =>
                <div>{format(Date.parse(params.row.timestamp), "dd.MM.yyyy HH:mm:ss")}</div>
        },
        {field: 'data', headerName: 'Type', width: 130},
        {field: 'type', headerName: 'Amount', width: 130},
        {field: 'displayName', headerName: 'Name', width: 130},
        {
            field: 'userIds', headerName: 'Device ids', width: 600,
            renderCell: (params) => {
                return <UserIdsListComponent data={params.row.userIds}/>
            }
        },
        {field: 'employeeEmail', headerName: 'Employee email', width: 300},

    ],
};

export default columns;