import {useGetPaymentsV1Query} from "../../core/api/bermuda-analytical-data-api";
import Loading from "../../components/Common/Loading";
import {DataGrid} from "@mui/x-data-grid";

const columns = [
    {
        headerName: "Currency",
        field: "currency",
        width: 100
    },
    {
        headerName: "Price",
        field: "price",
        width: 120
    },
    {
        headerName: "USD Price",
        field: "usdPrice",
        width: 120
    },
    {
        headerName: "Transaction ID",
        field: "sTransactionId",
        width: 220
    },
    {
        headerName: "Transaction Time",
        field: "transactionTime",
        width: 200
    },
    {
        headerName: "Store ID",
        field: "storeId",
        width: 470
    },
    {
        headerName: "Category",
        field: "category",
        width: 150
    },
    {
        headerName: "Item",
        field: "item",
        width: 220
    }
]

const PaymentsBA = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPaymentsV1Query(props.deviceId)

    let payments = ""

    if (typeof data === "object") {

        payments = <DataGrid
            getRowId={(row) => row.transactionId}
            rows={data}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        payments = "no data"
    }

    return (
        <Loading
            isLoading={isLoading}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {payments}

        </Loading>
    )
}

export default PaymentsBA