import React from "react"

import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {format} from "date-fns";

const TableJson = ({item, fields}) => {

    const getValue = (val) => {
        if(typeof val == 'boolean') return val?'Yes':'No'
        return val
    }

    return (
        <Table>
            <TableBody>
                {fields.map(key => (
                    <TableRow>
                        <TableCell>{key}</TableCell>
                        <TableCell>{getValue(item[key])}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default TableJson
