import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import SimpleTable from "../../components/Common/Elements";

import {Card, CardContent, Grid, Typography} from "@mui/material";


import {useGetStateV2BAQuery,} from "../../core/api/bermuda-state-api";

import TechInfo from "../TechInfo/techInfo";
import PaymentsSummary from "../Payments/summary";
import Loading from "../../components/Common/Loading";
import { parse, stringify } from 'lossless-json'
import {clearIsMassCharge} from "../../core/utils/configureStore";
import {getDeviceIdAbsentWarning} from "../../core/utils/constants";
import UserSocialInfoBA from "../UserInfo/userSocialInfoBA";


function DashboardBA() {

    let dispatch = useDispatch();
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId
    const stateId = CommonData.stateId
    const deviceIdIsEmpty = deviceId == null || deviceId == '' || typeof deviceId == 'object';

    useEffect(() => {
        clearIsMassCharge(dispatch)
    }, [])

    const {
        data: obj,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetStateV2BAQuery({deviceId, stateId}, {skip: deviceIdIsEmpty})

    let status = ""

    let content = ""
    let panel1 = ""
    let panel2 = ""

    let charges = ""


    if (typeof obj === "object" && obj.hasOwnProperty('data')) {
        const data = obj.data

        let source = {
            account_info: [],
            progress_info: []
        }

        if (data.hasOwnProperty('userId')) {
            source = {
                account_info: [
                    ["User ID", data.userId],
                    ["Username", data.hasOwnProperty("userName") ? data.userName : '-'],
                    ["Install version", data.installVersion],
                    ["Current version", data.currentVersion]
                ],
                // tech_info: [
                //
                //     ["Device model", state.envState.model],
                //     ["Device name", state.envState.name],
                //     ["OS version", state.envState.os],
                //     ["Game version", data.currentVersion],
                //     ["System memory", state.envState.systemMemory],
                //     ["Graphics memory", state.envState.graphicsMemory],
                //     ["Resolution", state.envState.resolution]
                // ],
                progress_info: [
                    ["Level", data.progress.level],
                    //["Location", state.guidMap["Locations"][data.progress.locations.current]], //guid-Map
                    ["Total locations", Object.keys(data.progress.locations.items).length]
                ],

            }
        }


        panel1 = <Grid container spacing={2}>
            <Grid item sm={4}>
                <Card>
                    <CardContent>
                        <Typography sx={{fontSize: 16}}>
                            Account info
                        </Typography>
                    </CardContent>

                    <SimpleTable data={source.account_info}/>
                </Card>

                <PaymentsSummary deviceId={deviceId}/>

            </Grid>

            <Grid item sm={4}>
                <Card>
                    <CardContent>
                        <Typography sx={{fontSize: 16}}>
                            Progress info
                        </Typography>
                    </CardContent>
                    <SimpleTable data={source.progress_info}/>
                </Card>
            </Grid>

            <Grid item sm={4}>
                <TechInfo isSimple={true} deviceId={deviceId}/>
            </Grid>
            <Grid item sm={6}>
                <UserSocialInfoBA isSimple={true} deviceId={deviceId}/>
            </Grid>
            <Grid item sm={4}>
            </Grid>
        </Grid>


    } else {
        content = stringify(obj)
    }

    return (
        <>
            {deviceIdIsEmpty ? getDeviceIdAbsentWarning() :
                <Loading
                    isLoading={isLoading||isFetching}
                    isError={isError}
                    error={error}
                >
                    <div>{panel1}</div>
                </Loading>}

        </>
    );

}

export default DashboardBA

//
// const mapStateToProps = (state) => ({
//     items: state.items
// })
// const mapDispatchToProps = dispatch => ({ ...bindActionCreators(dispatch) });
//
//
// export default connect(mapStateToProps, mapDispatchToProps)(UserStates);
