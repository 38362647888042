import api from "./base-api"


const chargeLogsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllBaLogs: build.query({
            query: (pagination) => `v2/bermuda/charges/logs?page=${pagination.page}&size=${pagination.pageSize}`,
            transformResponse(response, meta, arg) {
                response.chargeLogs = response.chargeLogs.map((logs, index) =>
                    Object.assign({}, logs, {id: index})
                );
                return response;
            },
            providesTags: ["BaChargerLogs"]
        }),
        getAllBaLogsByDeviceId: build.query({
            query: (data) => `v2/bermuda/charges/logs/deviceId/${data.deviceId}?page=${data.page}&size=${data.pageSize}`,
            transformResponse(response, meta, arg) {
                response.chargeLogs = response.chargeLogs.map((logs, index) =>
                    Object.assign({}, logs, {id: index})
                );
                return response;
            },
            providesTags: ["BaChargerLogsById"]
        }),

        getAllCmLogs: build.query({
            query: (pagination) => `v2/clockmaker/charges/logs?page=${pagination.page}&size=${pagination.pageSize}`,
            transformResponse(response, meta, arg) {
                response.chargeLogs = response.chargeLogs.map((logs, index) =>
                    Object.assign({}, logs, {id: index})
                );
                return response;
            },
            providesTags: ["CmChargerLogs"]
        }),
        getAllCmLogsByDeviceId: build.query({
            query: (data) => `v2/clockmaker/charges/logs/deviceId/${data.deviceId}?page=${data.page}&size=${data.pageSize}`,
            transformResponse(response, meta, arg) {
                response.chargeLogs = response.chargeLogs.map((logs, index) =>
                    Object.assign({}, logs, {id: index})
                );
                return response;
            },
            providesTags: ["CmChargerLogsById"]
        }),

        getAllRtLogs: build.query({
            query: (pagination) => `v2/rogerthat/charges/logs?page=${pagination.page}&size=${pagination.pageSize}`,
            transformResponse(response, meta, arg) {
                response.chargeLogs = response.chargeLogs.map((logs, index) =>
                    Object.assign({}, logs, {id: index})
                );
                return response;
            },
            providesTags: ["RtChargerLogs"]
        }),
        getAllRtLogsByDeviceId: build.query({
            query: (data) => `v2/rogerthat/charges/logs/deviceId/${data.deviceId}?page=${data.page}&size=${data.pageSize}`,
            transformResponse(response, meta, arg) {
                response.chargeLogs = response.chargeLogs.map((logs, index) =>
                    Object.assign({}, logs, {id: index})
                );
                return response;
            },
            providesTags: ["RtChargerLogsById"]
        }),
        getAllScLogs: build.query({
            query: (pagination) => `v2/solitaire/charges/logs?page=${pagination.page}&size=${pagination.pageSize}`,
            transformResponse(response, meta, arg) {
                response.chargeLogs = response.chargeLogs.map((logs, index) =>
                    Object.assign({}, logs, {id: index})
                );
                return response;
            },
            providesTags: ["ScChargerLogs"]
        }),
        getAllScLogsByDeviceId: build.query({
            query: (data) => `v2/solitaire/charges/logs/deviceId/${data.deviceId}?page=${data.page}&size=${data.pageSize}`,
            transformResponse(response, meta, arg) {
                response.chargeLogs = response.chargeLogs.map((logs, index) =>
                    Object.assign({}, logs, {id: index})
                );
                return response;
            },
            providesTags: ["ScChargerLogsById"]
        }),
    })
})

export const {
    useGetAllBaLogsQuery,
    useGetAllBaLogsByDeviceIdQuery,
    useGetAllCmLogsQuery,
    useGetAllCmLogsByDeviceIdQuery,
    useGetAllRtLogsQuery,
    useGetAllRtLogsByDeviceIdQuery,
    useGetAllScLogsQuery,
    useGetAllScLogsByDeviceIdQuery,
} = chargeLogsApi