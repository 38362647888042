import React, {useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import {
    useCreateChargeV2Mutation,
    useDeleteChargeV2Mutation,
    useGetChargesTypesV2Query,
    useUpdateChargeV2Mutation
} from "../../../core/api/charge-api";
import Loading from "../../../components/Common/Loading";
import CreateUpdateModal from "./createUpdateModal";
import {useParams} from "react-router-dom";


const ChargeManagement = (props) => {
    const [charge, setCharge] = useState({});
    const [deleteMode, setDeleteMode] = useState(false);

    const {game} = useParams()

    const [createCharge] = useCreateChargeV2Mutation();
    const [updateCharge] = useUpdateChargeV2Mutation();
    const [deleteCharge] = useDeleteChargeV2Mutation();

    const params = useParams();

    const [updateMode, setUpdateMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);

    const handleCancel = () => {
        setUpdateMode(false);
        setCreateMode(false);
        setDeleteMode(false);
    }

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetChargesTypesV2Query(game)


    const handleEditCharge = (item) => {
        setCharge(item)
        setUpdateMode(true);
    };

    const handleCreateCharge = () => {
        setCharge({
            name: "",
            amount: false,
            objectId: "",
        })
        setCreateMode(true);
    };

    const handleChargeAction = () => {
        if (updateMode) {
            updateCharge({data: charge, game: params.game})
            handleCancel();
        } else if (createMode) {
            createCharge({data: charge, game: params.game});
            handleCancel();
        }
    }

    const handleDeleteCharge = (index) => {
        setCharge(data[index])
        setDeleteMode(true);
    };

    const handleDeleteAction = () => {
        deleteCharge({id: charge.id, game: params.game});
        handleCancel();
    }

    return (
        <>
            <h1>Charge Management</h1>

            <Button variant="contained"
                    onClick={() => handleCreateCharge()}
            >
                Add Charge
            </Button>
            <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="charge table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" width={500}>Id</TableCell>
                                <TableCell align="center" width={500}>Type</TableCell>
                                <TableCell align="center" width={500}>Name</TableCell>
                                <TableCell align="center" width={200}>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isSuccess && data.map((item, index) => (
                                <TableRow key={item.id}>
                                    <TableCell align="center">{item.id}</TableCell>
                                    <TableCell align="center">{item.type}</TableCell>
                                    <TableCell align="center">{item.name}</TableCell>
                                    <TableCell align="center">{item.amount ? "Yes" : "No"}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditCharge(item)}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleDeleteCharge(index)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Loading>
            <Dialog
                open={updateMode || createMode}
                onClose={handleCancel}>

                <CreateUpdateModal
                    setCharge={setCharge}
                    charge={charge}
                    handleChargeAction={handleChargeAction}
                    handleCancel={handleCancel}
                />

            </Dialog>
            <Dialog
                open={deleteMode}
                onClose={handleCancel}>
                <Box sx={{margin: 5}}>
                    <h2>Are you sure you want to delete charge {charge?.name}?</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={3}>
                            <Button variant="contained"
                                    onClick={handleDeleteAction}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid item sm={3}>
                            <Button variant="outlined"
                                    onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};

export default ChargeManagement;