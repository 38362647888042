import {Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@mui/material";
import React, {useState} from "react";
import {useGetGuidMapRTQuery} from "../../core/api/rogerthat-charge-api";

export default function InGameListRT({items, guidMapName}) {
    const {
        data: guidMap,
    } = useGetGuidMapRTQuery()

    const mapKey = guidMapName == undefined ? 'Consumable' : guidMapName

    const getItemName = (guidMap, itemGuid) => {
        if (guidMap && guidMap.hasOwnProperty(mapKey)) {
            const consumable = guidMap[mapKey].filter(consumable => {
                return consumable.guid === itemGuid
            })

            return consumable && consumable.length > 0 ? (consumable[0].hasOwnProperty('name') ? consumable[0].name : '[no name ' + itemGuid + ']') : '??';
        }
        return 'no value';
    }

    const [filterString, setFilterString] = useState('');


    const rows = items.map((item) => ({
        id: item.Reference,
        name: getItemName(guidMap, item.Reference),
        count: item.Amount,
    })).filter(item => filterString == '' || item.name.toLowerCase().indexOf(filterString) >= 0)


    return (

        <>
            <TextField value={filterString} placeholder="filter name" onChange={(event) => {
                setFilterString(event.target.value)
            }} size="small" sx={{m: 2}}></TextField>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow>
                            <TableCell width="280">{row.id}</TableCell>
                            <TableCell width="100">{row.name}</TableCell>
                            <TableCell>{row.count}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
        </>
    );
}