import PrefabsItem from '../PrefabsItem';
import {useGetPrefabStringV2TOHQuery} from "../../../core/api/toh-prefab-api"; // Import the generic component

function PrefabsTOHItem({ selectedItem, setSelectedItem }) {
    return (
        <PrefabsItem
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            useQueryHook={useGetPrefabStringV2TOHQuery} // Pass the specific query hook
        />
    );
}

export default PrefabsTOHItem;