import {useGetPrefabV2SCQuery} from "../../../core/api/solitaire-prefab-api";
import PrefabsItem from "../PrefabsItem";


function PrefabsSCItem({selectedItem,setSelectedItem}) {
    return (
        <PrefabsItem
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            useQueryHook={useGetPrefabV2SCQuery} // Pass the specific query hook
        />
    );
}

export default PrefabsSCItem;
