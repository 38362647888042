import {configureStore, isRejectedWithValue} from "@reduxjs/toolkit"
import api from "../api/base-api"
import root from "../reducers/index"
import {getEnvironment} from "../../constants";
import {parseFormDataTextToJsonString} from "./constants";
import * as Sentry from "@sentry/react";
import {types} from "../reducers/commonData";

Sentry.init({
    dsn: 'https://86e2d2d85466494aa7493a406936665b@cross-sentry.belka-games.com/31',
    environment: getEnvironment()
});

const myLoggingMiddleware = storeAPI => next => action => {
    if (isRejectedWithValue(action)) {
        const jsonError = JSON.stringify(action.payload.data);

        const severity = (action.payload.status >= 400 && action.payload.status <= 405) ?  Sentry.Severity.Info : Sentry.Severity.Error

        if (action.meta.baseQueryMeta.request.body != null) {
            action.meta.baseQueryMeta.request.body.getReader().read().then(response => {
                const jsonRequest = JSON.stringify({body: parseFormDataTextToJsonString(new TextDecoder().decode(response.value))});
                Sentry.captureMessage(
                    `Ошибка при отправке запроса\n${jsonError}\n${jsonRequest}`,
                    severity
                )
            });
        } else {
            Sentry.captureMessage(
                `Ошибка при отправке запроса ${jsonError}`,
                severity
            )
        }
    }
    return next(action)
}

export function clearIsMassCharge(dispatch) {
    dispatch({
        type: types.SET_MASS_CHARGE,
        payload: {isMassCharge: false},
    });
}


export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        data: root,

    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(myLoggingMiddleware)
})
