import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectGuidMapToh, useCreateChargeTohMutation, useGetChargesTohQuery} from "../../../core/api/toh-charge-api";
import {useCheckExistMutation} from "../../../core/api/bermuda-state-api";

import Loading from "../../../components/Common/Loading";
import ChargesList from "../chargesList";

import {Alert, LoadingButton, TabContext, TabPanel} from "@mui/lab";


import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import {useGetChargesTypesV2Query} from "../../../core/api/charge-api";
import UsersListDialog from "../../UsersListLinksDialog";
import {FULFILLED, UNINITIALIZED} from "../../../core/api/ResponseStatuses";
import DeviceBaChargerLogs from "../../ChargerLogs/ba/DeviceBaChargerLogs";
import Guid2Block from "./guid2block";
import {types} from "../../../core/reducers/commonData";
import {useNavigate} from "react-router";

dayjs.extend(utc); // extend Day.js with the UTC plugin


function Charger(props) {

    console.log("init charger")
    const {game} = useParams()

    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId

    const {
        data: chargesData,
        isLoading: chargesLoading,
        isSuccess: chargesSuccess,
        isError: chargesIsError,
        error: chargesError
    } = useGetChargesTohQuery(deviceId)

    const guidMap = useSelector(selectGuidMapToh);

    const ids = {
        ids: ["3a78896e879a67d889ac3ed2271159ca",
            "dc57a9438835b19896a9fa82c6cd5c3c",
            "DA6460CC-49E7-4B1C-A281-AC5C1097688D"]
    }

    let [checkExist, checkExistResponse = {
        existent: [],
        nonExistent: []
    }] = useCheckExistMutation();


    // const {
    //     data: guidMap,
    // } = useGetGuidMapQuery()

    const [
        createCharge,
        createChargeResponse
    ] = useCreateChargeTohMutation()

    const {
        data: chargeTypes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetChargesTypesV2Query(game)

    const [selectedItem, setSelectedItem] = useState({})

    const [type, setType] = useState('')
    const [isMassCharge, setIsMassCharge] = useState(false)

    const [guid, setGuid] = useState(null)
    const [guid2, setGuid2] = useState(null)
    const [amount, setAmount] = useState(0)
    const [endTime, setEndTime] = useState(null)
    const [massChargeList, setMassChargeList] = useState("")
    const [formErrors, setFormErrors] = useState({})
    const [fileData, setFileData] = useState(null);

    const [validation, setValidation] = useState(false);
    const [validated, setValidated] = useState(false);
    const [existReady, setExistReady] = useState(false);
    const [existedDialogOpen, setExistedDialogOpen] = useState(false);
    const [nonExistentDialogOpen, setNonExistentDialogOpen] = useState(false);
    const [isChargeButtonDisable, setChargeButtonDisable] = useState(true);
    const [tabIndex, setTabIndex] = useState("1");
    const [selectedItemList, setSelectedItemList] = useState([]);
    const [validateChildrenList, setValidateChildrenList] = useState([]);
    const [resetChildData, setResetChildData] = useState(false);
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };
    const dispatch = useDispatch();
    let navigate = useNavigate();
    function clearDeviceId() {
        dispatch({
            type: types.SET_MASS_CHARGE,
            payload: {isMassCharge},
        });
        dispatch({
            type: types.SET_DEVICE_ID,
            payload: {deviceId : ""},
        })
        navigate('/toh/charger/')
    }
    useEffect(() => {
        if (checkExistResponse) {
            if (checkExistResponse.status === FULFILLED) {
                checkExistResponse.status = UNINITIALIZED;
                setValidation(false);
                setValidated(false);
                setExistReady(true);
            }
        }
        if (validation && !validated && massChargeList !== "") {
            setValidated(true);
            checkExist({ids: massChargeList.split(/[\r\n]+/)});
        }
        if (!validation && massChargeList === "") {
            checkExistResponse = {};
            setValidated(false);
            setExistReady(false);
        }
        if (isMassCharge) {
            clearDeviceId();
        }
        setChargeButtonDisable(checkChargeButtonDisable() || !checkChildrenValid() || amountIsZero());
    }, [checkExistResponse, validation, massChargeList, existReady, isMassCharge, validateChildrenList])


    const checkChargeButtonDisable = () => {
        if (isMassCharge) {
            return !existReady
        }
        return false;
    }

    const amountIsZero = () => {
        return !!selectedItemList.filter(sil =>
            sil.hasOwnProperty("amount")
            && (sil.amount == 0 || !(parseInt(sil.amount)==sil.amount) )
            && sil.item.amount == true

        )[0]
    }

    const checkChildrenValid = () => {
        if (validateChildrenList.length === 0) return false;
        return !validateChildrenList.includes(false)
    }

    const resetForm = () => {
        setType(null)
        setGuid(null)
        setGuid2(null)
        endTime(null)
        setAmount(0)
        setMassChargeList("")
        setValidation(false);
        setValidated(false);
        setExistReady(false);
    }

    const handleChange = (event) => {
        setType(event.target.value)
        setGuid(null)
        setGuid2(null)
        setResetChildData(true)
        setSelectedItem(chargeTypes.filter(item => item.value == event.target.value)[0])
        setSelectedItemList([])
        let newSelectedItems = []
        newSelectedItems.push({"item": chargeTypes.filter(item => item.value == event.target.value)[0]})
        setSelectedItemList(newSelectedItems)
        setValidateChildrenList([])
        console.log("event", chargeTypes.filter(item => item.value == event.target.value)[0])
    }

    const handleChangeMass = (event) => {
        setExistReady(false);
        setMassChargeList(event.target.value)
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            // Handle text parsing here
            const fileContents = event.target.result;
            const lines = fileContents.split('\n'); // Example of parsing text from a .txt file
            // const rows = fileContents.split('\n').map(row => row.split(',')); // Example of parsing an array from a .csv file
            setMassChargeList(fileContents);
        };

        reader.readAsText(file);
        setExistReady(false);
        setValidation(true);
    };

    const clearErrors = () => {
        setFormErrors({})
    }
    const addError = (key, value) => {
        setFormErrors({
            ...formErrors,
            [key]: value
        });
    };

    const handleDialogOnClose = () => {
        setExistedDialogOpen(false);
        setNonExistentDialogOpen(false);
    }

    const validateForm = () => {

        let has_errors = false;

        if (guid === '') {
            addError("guid", "не указан guid")
            has_errors = true
        }

        if (isMassCharge && massChargeList.length === 0) {
            addError("guid", "не указан список id")
            has_errors = true

        }


        return !has_errors;
    }

    const createChargeHandler = () => {
debugger
        clearErrors()
        if (!validateForm()) return false;

        let dataList = []

        selectedItemList.forEach(item => {
            let data = {
                "keys": [item["item"].guidMap != "" ? item["guid"]?.guid : ""],
            }

            let amountAttribute = item["item"].amountAttribute != undefined && item["item"].amountAttribute != '' ? item["item"].amountAttribute : 'count'
            data[amountAttribute] = item["amount"] === undefined ? 0 : item["amount"]

            if(item["item"].hasOwnProperty('key'))
                data[item["item"].key] = item["item"].guidMap != "" ? item['guid'].guid : ""


            if (item["item"].guidMap2 != "" && item["guid2"]?.guid != null) {
                data[item["item"].key2] = item["item"].guidMap2 !== false ? item["guid2"].guid : ""
                data["keys"].push(item["guid2"].guid)
            }

            if (item["endTime"] && item['item'].endTime === true) {
                data['endTime'] = item['endTime'].format('YYYY-MM-DDTHH:mm:ss[Z]') // format the date to ISO 8601 format
            }

            if(
                item.hasOwnProperty('guid') && item.guid != '' &&
                item['guid'].hasOwnProperty('label') &&
                item['guid'].hasOwnProperty('x') &&
                item['guid'].hasOwnProperty('y')
            ) {
                if(item['guid'].label != null) {
                    data["label"] = item['guid'].label;
                    data["mockSize"] = {x:parseInt(item['guid'].x), y:parseInt(item['guid'].y)};
                }
            }

            dataList.push({
                "type": item["item"].value,
                "data": data,
                "displayName": item.guid ? item.guid.displayName : (item.guid2 ? item.guid2.displayName : item.item.name)
            })

        })

        let userIds;
        if (isMassCharge) {
            userIds = massChargeList.split(/[\r\n]+/)
        } else {
            userIds = [
                deviceId
            ]
        }

        dataList.map(data => {
            return {
                "type": data["value"],
                "data": data["data"]
            }
        })


        createCharge({
            "userIds": userIds,
            "chargeDataList": dataList
        })

        //resetForm()

    }

    function amountToCharge() {
        let userIds = []
        if (isMassCharge) {
            userIds = massChargeList.split(/[\r\n]+/)
        } else {
            userIds = [
                deviceId
            ]
        }
        userIds = userIds.filter((item) => {
            return item != ''
        })
        return userIds.length

    }

    console.log('createChargeResponse')
    console.log(createChargeResponse)

    return (
        <>
            <Card sx={{mb: 2}}>
                <CardContent>
                    <FormGroup sx={{pb: 2}}>
                        <FormControlLabel
                            control={
                                <Switch checked={isMassCharge} onChange={() => setIsMassCharge(!isMassCharge)}
                                        name="jason"/>
                            }
                            label="Mass charge"
                        />

                    </FormGroup>

                    <FormGroup sx={{pb: 2}}>
                        {
                            isMassCharge && <>
                                <TextField
                                    id="outlined-textarea"
                                    label="List of device ID for mass charge"
                                    placeholder="Через перенос"
                                    multiline
                                    rows={4}
                                    value={massChargeList}
                                    onChange={handleChangeMass}

                                />
                                <Box className={"validation-process"}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                    >
                                        Select file .txt or .csv
                                        <input
                                            accept=".txt,.csv"
                                            id="contained-button-file"
                                            type="file"
                                            onChange={handleFileUpload}
                                            hidden
                                        />
                                    </Button>
                                    {!validation &&
                                        <Button
                                            sx={{ml: 2}}
                                            variant="outlined"
                                            color="warning"
                                            disabled={massChargeList.length === 0}
                                            onClick={() => {
                                                setExistReady(false);
                                                setValidation(true);
                                            }}>
                                            Validate ids
                                        </Button>
                                    }
                                    {existReady === true &&
                                        <Box>
                                            <Link
                                                sx={{ml: 2}}
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    setExistedDialogOpen(true);
                                                }}
                                            >
                                                Existent
                                                ({checkExistResponse.data ? checkExistResponse.data.existent.length : 0})
                                            </Link>
                                            <Link
                                                sx={{ml: 2}}
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    setNonExistentDialogOpen(true);
                                                }}
                                            >
                                                Non existent
                                                ({checkExistResponse.data ? checkExistResponse.data.nonExistent.length : 0})
                                            </Link>
                                            <UsersListDialog
                                                onClose={handleDialogOnClose}
                                                data={checkExistResponse.data ? checkExistResponse.data.existent : []}
                                                open={existedDialogOpen}
                                                name={"Existed device's id"}/>
                                            <UsersListDialog
                                                onClose={handleDialogOnClose}
                                                data={checkExistResponse.data ? checkExistResponse.data.nonExistent : []}
                                                open={nonExistentDialogOpen}
                                                name={"Non existent device's id"}/>
                                        </Box>
                                    }

                                    {validation &&
                                        <Box className={"validation-process"}>
                                            <CircularProgress size={"2em"} sx={{ml: 2, mr: 2}}/>
                                            <Typography sx={{fontSize: 16}}>
                                                Validating ids
                                            </Typography>
                                        </Box>
                                    }
                                </Box>

                            </>
                        }
                    </FormGroup>


                    <Loading
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                        isSuccess={isSuccess}>
                        <FormGroup sx={{pb: 2}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    value={type}
                                    label="Type"
                                    onChange={handleChange}
                                >
                                    {isSuccess && chargeTypes.map((item) => (
                                        <MenuItem value={item.value}>{item.name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Loading>

                    {selectedItemList.map((item, index) => (
                        <Guid2Block
                            selectedItem={item["item"]}
                            selectedITemList={selectedItemList}
                            setSelectedItemList={setSelectedItemList}
                            index={index}
                            setChargeButtonDisable={setChargeButtonDisable}
                            isMassCharge={isMassCharge}
                            resetData={resetChildData}
                            setResetData={setResetChildData}
                            addButtonDisabled={isChargeButtonDisable}
                            validateChildrenList={validateChildrenList}
                            setValidateChildrenList={setValidateChildrenList}
                        >
                        </Guid2Block>
                    ))}

                    {Object.keys(formErrors).length > 0 &&
                        <Alert severity="error" sx={{mb: 2}}>
                            {Object.values(formErrors).map(error => (
                                <div key={formErrors}>{error}</div>
                            ))}
                        </Alert>
                    }

                    {createChargeResponse.isSuccess &&
                        <Alert severity="info" sx={{mb: 2}}>
                            Charge result done {createChargeResponse.data.userIds.length}:
                            {createChargeResponse.data.userIds.map(data => (
                                <div>{data}</div>
                            ))}
                        </Alert>
                    }


                    <Box margin={1}>
                        <LoadingButton
                            sx={{mr: 1, ml: -1}}
                            disabled={isChargeButtonDisable}
                            loading={createChargeResponse.isLoading}
                            variant={"contained"}
                            color={"success"}
                            onClick={createChargeHandler}>Charge</LoadingButton>
                    </Box>

                    {amountToCharge()} ids to charge

                </CardContent>

            </Card>

            {deviceId != '' && isSuccess && <TabContext value={tabIndex}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Charges" value="1"/>
                    <Tab label="Charger Logs" value="2"/>
                </Tabs>
                <TabPanel value="1">
                    <Card>
                        <ChargesList
                            deviceId={deviceId}
                            chargesData={chargesData}
                            chargesLoading={chargesLoading}
                            chargesSuccess={chargesSuccess}
                            chargesIsError={chargesIsError}
                            chargesError={chargesError}
                            chargeTypes={chargeTypes}
                            guidMap={guidMap}
                        />
                    </Card>
                </TabPanel>
                <TabPanel value="2">
                    <DeviceBaChargerLogs deviceId={deviceId}/>
                </TabPanel>
            </TabContext>}

        </>
    );

}

export default Charger;
