import api from "./base-api"

const clockmakerChargeApiV2CM = api.injectEndpoints({
    endpoints: (build) => ({
        getChargesV2CM: build.query({
            query: (data = "") => `${data.version}/clockmaker/charges/${data.deviceId}`,
            providesTags: ['Charges']
        }),
        createChargeV2CM: build.mutation({
            query: (body = {}) => ({
                url: `${body.version}/clockmaker/charges`,
                method: "POST",
                body: body.data
            }),
            invalidatesTags: ['Charges', 'CmChargerLogsById', 'CmChargerLogs'],
        })
    }),
    overrideExisting: false
})

export const {
    useGetChargesV2CMQuery,
    useCreateChargeV2CMMutation,
} = clockmakerChargeApiV2CM
