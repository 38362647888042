import {Backdrop, Box, CircularProgress} from "@mui/material";

const Loading = (props) => {
    const getErrorText = () => {
        debugger
        if (!props.hasOwnProperty("error")) return "network error";
        if (props.error.hasOwnProperty('data')) {
            let error_text = '';
            let error_data = props.error.data?.error == undefined ? '' : props.error.data?.error;
            let error_message = ''
            if (props.error.data == null) return props.error.status + ' ' + error_text
            try {
                error_message = JSON.parse(props.error.data.message)
                if(typeof error_message == 'object') {
                    error_message = error_message.message
                }
            } catch (e) {
                error_message = props.error.data.message
            }
            error_text = (typeof props.error.data == "object") ? error_data + error_message : "network error "
            return props.error.status + ' ' + error_text
        }
        if (props.error.hasOwnProperty('error')) return props.error.error
        return "undefined error";
    }

    return (
        <>
            {props.isLoading ? <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <>
                    {props.isError ? getErrorText() : <div>{props.children}</div>}
                </>
            }

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={props.isFetching}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>

    )
}

export default Loading