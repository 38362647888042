import React from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import SimpleTable from "../../components/Common/Elements";

import {Card, CardContent, Grid, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";


import Loading from "../../components/Common/Loading";
import {useGetStateV2SCQuery} from "../../core/api/solitaire-state-api";
import {
    formatDate,
    getDeviceIdAbsentWarning,
    paymentUserStatus,
    timestampToDate,
    WORLDS_SC
} from "../../core/utils/constants";
import TechInfoSC from "../TechInfo/techInfoSC";
import {isEmpty} from "../../core/utils/StringUtils";
import {useGetUserSessionSCQuery} from "../../core/api/solitaire-analytical-data-api";
import UserSocialInfoSC from "../UserInfo/userSocialInfoSC";


function DashboardSC() {
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId;
    const stateId = CommonData.stateId;

    const deviceIdIsEmpty = isEmpty(deviceId) || typeof deviceId == 'object';


    const {
        data: obj,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetStateV2SCQuery({deviceId, stateId})

    const {data: sessionBQ, error: sessionBQError, isLoading: sessionBQIsLoading, isError: sessionBQIsError} = useGetUserSessionSCQuery(deviceId)
console.log("sessionBQ")
console.log(sessionBQ)
    const getByName = (items = [], name) => items.find((item) => item.Name === name)?.Amount

    let panel1 = ""


    if (typeof obj === "object" && obj.hasOwnProperty('data')) {
        const data = obj.data

        if((data.hasOwnProperty('userID') || data.hasOwnProperty('UserID'))) {
            let source = {
                account_info: [],
                progress_info: []
            }


            source = {
                account_info: [
                    ["User ID", data.userID],
                    //["SocialID", data.SocialID],
                    ["Username", data.UserProfile.hasOwnProperty("Facebook") ? data.UserProfile.Facebook.first_name + ' ' + data.UserProfile.Facebook.last_name : '-'],
                    ["Nickname", data.UserProfile.Nickname],
                    ["Created", data.Timestamp],
                    ["Current version", data.CurrentVersion]
                ],
                progress_info: [
                    [
                        "World",
                        WORLDS_SC[
                            data.ProgressionProfile
                                .WorldID
                            ]
                    ],
                    [
                        "Level",
                        data.ProgressionProfile
                            .LevelGID
                    ],
                    [
                        "Current World Level",
                        data.ProgressionProfile
                            .LevelWID
                    ],
                    [
                        "Stars",
                        data.ProgressionProfile.Stars
                    ],
                    [
                        "Loop level",
                        data.ProgressionProfile.LoopID
                    ],
                    [
                        "Star for LuckyWheel",
                        getByName(data.Consumables, "Star")
                        +
                        (data.ProgressionProfile
                            .WorldID !== 1
                            ? "/20"
                            : "/10")
                    ]
                ],
                ingame_goods: [
                    [
                        "Gold",
                        getByName(
                            data.Consumables,
                            "Gold"
                        )
                    ],
                    [
                        "Ticket",
                        getByName(
                            data.Consumables,
                            "Ticket"
                        )
                    ],
                    [
                        "Wildcard",
                        getByName(
                            data.Consumables,
                            "Wildcard"
                        )
                    ],
                    [
                        "Reverse",
                        getByName(
                            data.Consumables,
                            "Reverse"
                        )
                    ],
                    [
                        "Replenish",
                        getByName(
                            data.Consumables,
                            "Replenish"
                        )
                    ],
                    [
                        "Greenback",
                        getByName(
                            data.Consumables,
                            "Greenback"
                        )
                    ],
                    [
                        "Dice Ticket",
                        getByName(
                            data.Consumables,
                            "DiceTicket"
                        )
                    ],
                    [
                        "Streak Crystal",
                        getByName(
                            data.Consumables,
                            "StreakCrystal"
                        )
                    ]
                ],
                payment_info: [
                    [
                        "Segment",
                        paymentUserStatus(data.PaymentProfile.PaymentSum)
                    ],
                    [
                        "Total",
                        Math.floor(data.PaymentProfile.PaymentSum)
                    ],
                    [
                        "Last payment date",
                        formatDate(timestampToDate(data.PaymentProfile.PaymentDate))
                    ]
                ]

            }


            panel1 = <Grid container spacing={2}>
                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Account info
                            </Typography>
                        </CardContent>

                        <SimpleTable data={source.account_info}/>
                        <Loading isLoading={sessionBQIsLoading} error={sessionBQError} isError={sessionBQIsError}>

                            {sessionBQ!=undefined && sessionBQ.length > 0 && <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>TimeStamp</TableCell>
                                        <TableCell>{sessionBQ[0].timestamp}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>timestamp update</TableCell>
                                        <TableCell>{sessionBQ[0].timestamp_update}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Version</TableCell>
                                        <TableCell>{sessionBQ[0].version}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Status</TableCell>
                                        <TableCell>{sessionBQ[0].status}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>}
                        </Loading>
                    </Card>

                </Grid>
                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Payment info
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.payment_info}/>
                    </Card>
                </Grid>


                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Progress info
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.progress_info}/>
                    </Card>
                </Grid>
                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Ingame goods
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.ingame_goods}/>
                    </Card>
                </Grid>


                <Grid item sm={4}>
                    <TechInfoSC isSimple={true} deviceId={deviceId}/>
                </Grid>
                <Grid item sm={6}>
                    <UserSocialInfoSC deviceId={deviceId}/>
                </Grid>
                <Grid item sm={4}>
                </Grid>
            </Grid>


        }
        else if (typeof obj.data === "object" && obj.hasOwnProperty('error')) {
            panel1 = obj.data.error
        } else {
            panel1 = obj.data
        }
    } else {
        panel1 = 'unknown error'
    }

    return (
        <>
            {deviceIdIsEmpty ? getDeviceIdAbsentWarning() :<Loading
                isLoading={isLoading||isFetching}
                isError={isError}
                error={error}
            >
                <div>{panel1}</div>
            </Loading>}

        </>
    );

}

export default DashboardSC
