import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";
import {useGetGuidMapQuery} from "../../core/api/bermuda-charge-api";

export default function InGameList({items}) {
    const {
        data: guidMap,
    } = useGetGuidMapQuery()

    const getItemName = (guidMap, itemGuid) => {
        if (guidMap && guidMap.hasOwnProperty('Consumables')) {
            const consumable = guidMap.Consumables.filter(consumable => {
                return consumable.guid === itemGuid
            })

            return consumable && consumable.length > 0 ? (consumable[0].hasOwnProperty('name') ? consumable[0].name : '[no name ' + itemGuid + ']') : '';
        }
        return 'no value';
    }

    const rows = Object.entries(items).map(([key, value]) => ({
        id: key,
        name: getItemName(guidMap, key),
        count: value,
    }))

    return (

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Count</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.count}</TableCell>
                    </TableRow>
                ))}
            </TableBody>

        </Table>
    );
}