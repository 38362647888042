import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import React from "react";
import {Delete} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ScCharge(props) {
    return (
        <FormGroup sx={{mb: 2}}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <FormControl fullWidth>

                        <InputLabel id="demo-simple-select-label">Name</InputLabel>
                        <Select
                            id={'name'}
                            value={props.charge.name}
                            label="Name"
                            onChange={(e) => props.handleChange(e.target.value, 'name', props.index)}
                        >
                            {props.chargeTypes.map((item) => (
                                <MenuItem value={item.name}>{item.name}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            id={'amount'}
                            label={'Amount'}
                            onChange={(e) => props.handleChange(e.target.value, 'amount', props.index)}
                            value={props.charge.amount}
                        >
                        </TextField>
                    </FormControl>
                </Grid>
                {!(props.charges.length === 1 && props.index === 0) &&
                    <Grid item xs={0.7} display={'flex'} justifyContent={'start'}>
                        <Button style={{minWidth: '100%'}}
                            variant={"outlined"}
                            color={"error"}
                            onClick={() => props.remove(props.index)}
                        >
                            <DeleteIcon/>
                        </Button>
                    </Grid>
                }
            </Grid>
        </FormGroup>

    )
}