import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Helmet from 'react-helmet';
import config from '../config';
import Login from './Login';
import GamesList from './GamesList';
import GameActions from './GameActions';
import {ThemeProvider} from "@mui/material";

import Header from '../components/Common/Header'

//import store from '../core/reducers/index'
import {store} from '../core/utils/configureStore';


import {ReactKeycloakProvider} from "@react-keycloak/web";
import {keycloak} from '../keycloak-config';

import "./App.css"
import LoadingCircle from "../components/Common/Loading";
import theme from "../theme";
import {SnackBarProvider} from "../snackBarProvider";


export default function App(props) {

    const UserSession = useSelector((state) => state.data.UserSession)


    if (window.localStorage.getItem('isProd') == null) {
        switch (document.location.host) {

            case "hermes2.belka-games.com": {
                store.dispatch({type: "MODE_PROD"})
                window.localStorage.setItem('isProd', true);
                console.log("setItem")
                break;
            }
            case "hermes2.stage.belka-games.com": {
                store.dispatch({type: "MODE_STAGING"})
                window.localStorage.setItem('isProd', false);
                break;
            }
            case "localhost:3000": {
                store.dispatch({type: "MODE_STAGING"})
                break;
            }
        }
    } else {
        if (window.localStorage.getItem('isProd') == 'true') {
            store.dispatch({type: "MODE_PROD"})
        } else {
            store.dispatch({type: "MODE_STAGING"})
        }
    }


    function kcEvent(event) {

        if (event == 'onAuthLogout') {
            keycloak.logout();
        }

        if (event == 'onAuthRefreshError') {

        }

        if (event == 'onAuthRefreshSuccess') {
            sessionStorage.setItem('kctoken', keycloak.token);
        }

        if (event == 'onAuthSuccess') {
            if (keycloak.authenticated) {
                sessionStorage.setItem('kctoken', keycloak.token);

                let isAdmin = false;
                if (keycloak.realmAccess.roles.includes("BELCOINSHOP_ADMIN")) {
                    isAdmin = true;
                }

                store.dispatch({
                    type: 'AUTH_SELF_SUCCESS', payload: {
                        data: {
                            username: keycloak.idTokenParsed.name ? keycloak.idTokenParsed.name : keycloak.idTokenParsed.preferred_username,
                            isAdmin: isAdmin,
                            roles: keycloak.realmAccess.roles
                        }
                    }
                });

            } else {
                store.dispatch({type: 'AUTH_LOGOUT'});
                //keycloak.login();
            }
        }

    }

    function getContent() {
        let Content

        if (UserSession.authenticated == true) {
            Content =
                <Routes>
                    <Route path='/' element={<GamesList/>}/>
                    <Route path='/login' element={<Login/>}/>
                    <Route path='/:game' element={<GameActions/>}>
                        <Route index/>
                        <Route path=':action'/>
                        <Route path=':action/:deviceId'/>
                        <Route path=':action/:deviceId/:tab'/>
                        <Route path=':action/:deviceId/:tab/:subtab'/>
                    </Route>

                </Routes>
            ;
        } else {
            Content = <Login/>
        }
        return Content
    }


    return (
        <>
            <ThemeProvider theme={theme}>

                <Helmet {...config.app}></Helmet>
                <Header/>
                <ReactKeycloakProvider
                    LoadingComponent={<LoadingCircle/>}
                    onEvent={kcEvent}
                    initOptions={{onLoad: 'check-sso', checkLoginIframeInterval: 1000}}
                    authClient={keycloak}
                >
                    <SnackBarProvider>
                        {getContent()}
                    </SnackBarProvider>
                </ReactKeycloakProvider>
            </ThemeProvider>
        </>
    );
}

