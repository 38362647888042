import React from 'react';

class Site extends React.Component {

    static propTypes = {};

    state = {};

    render() {
        return (
            <>


            </>
        );
    }
}

export default Site;
