import {
    Autocomplete,
    Box,
    Button, Card, CardContent,
    Dialog, DialogActions,
    DialogContent,
    Grid, IconButton, Paper, Table,
    TableCell,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@mui/material";
import {
    getMultipleStatesWarning,
    getSearchHistory, getSearchHistoryKey, getSearchHistoryKeySynchroID,
    getSearchHistoryKeyVendorID,
    getSearchHistorySynchroId,
    getSearchHistoryVendorId,
    getStatesNotFoundWarning,
    putSearchHistoryForKey,
} from "../../core/utils/constants";
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {createSearchParams, useParams, useSearchParams} from "react-router-dom";
import {isEmpty} from "../../core/utils/StringUtils";
import DialogTitle from "@mui/material/DialogTitle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AdvancedSearchTable from "../AdvancedSearch/advancedSearchTable";
import Loading from "../../components/Common/Loading";
import {LoadingButton} from "@mui/lab";

export default function MultiSearch({useSearchHook}) {
    const selector = useSelector((state) => state.data)
    const CommonData = selector.CommonData
    const {game, deviceId: deviceIdParam, action} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const vendorIdParam = searchParams.get('vendorId');
    const synchroIdParam = searchParams.get('synchroId');
    const [currentDeviceId, setCurrentDeviceId] = useState(deviceIdParam);
    const [currentVendorId, setCurrentVendorId] = useState(vendorIdParam);
    const [currentSynchroId, setCurrentSynchroId] = useState(synchroIdParam);
    const isMassCharge = CommonData.isMassCharge
    const [advancedSearch, advancedSearchResponse] = useSearchHook();
    const [open, setOpen] = useState(false);
    const mode = selector.SourceMode;

    const isCharger = window.location.pathname.indexOf("/charger") >= 0;

    useEffect(() => {
        if (isCharger) {
            setSearchParams({vendorId: '', synchroId: ''})
            setSynchroId('');
            setStateId('');
        }
    }, [isCharger])


    function setDeviceId(deviceId) {
        dispatch({
            type: "SET_DEVICE_ID",
            payload: {deviceId},
        });
    }

    function setStateId(stateId) {
        dispatch({
            type: "SET_STATE_ID",
            payload: {stateId},
        });
    }

    function setSynchroId(synchroId) {
        dispatch({
            type: "SET_SYNCHRO_ID",
            payload: {synchroId},
        });
    }

    function submitForm(deviceId) {
        console.log("deviceId")
        console.log(deviceId)
        if(
            (currentVendorId == vendorIdParam && currentVendorId!='') ||
            (currentDeviceId == deviceIdParam && currentDeviceId!='')
        ) {
            setOpen(true)
            return false;
        }

        if(deviceId === undefined) {
            deviceId = currentDeviceId
        } else {
            setCurrentDeviceId(deviceId)
        }
        setDeviceId(deviceId);
        setStateId(currentVendorId);
        setSynchroId(currentSynchroId);
        if (deviceId && deviceId != "") {
            putSearchHistoryForKey(deviceId, game, mode, getSearchHistoryKey);
        }
        if (currentVendorId && currentVendorId != "") {
            putSearchHistoryForKey(currentVendorId, game, mode, getSearchHistoryKeyVendorID);
        }
        if (currentSynchroId && currentSynchroId != "") {
            putSearchHistoryForKey(currentSynchroId, game, mode, getSearchHistoryKeySynchroID);
        }
        navigate({
                pathname: '/' + game + '/' + (action ? action : 'dashboard') + '/' + (!isEmpty(deviceId) ? deviceId : ''),
                search: `?${createSearchParams({'vendorId': !isEmpty(currentVendorId) ? currentVendorId : ''})}&${createSearchParams({'synchroId': !isEmpty(currentSynchroId) ? currentSynchroId : ''})}`
            },
            {state: deviceId})
    }

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const handleChangeDeviceId = (v) => {
        setCurrentDeviceId(v)
    }

    const handleChangeStateId = (v) => {
        setCurrentVendorId(v)
    }

    useEffect(() => {
        setDeviceId(deviceIdParam);
        setStateId(vendorIdParam);
        setSynchroId(currentSynchroId);
    }, [deviceIdParam, vendorIdParam, synchroIdParam])

    useEffect(() => {
        if (!isEmpty(deviceIdParam) || !isEmpty(vendorIdParam) || !isEmpty(synchroIdParam)) {
            console.log("SEARCH")
            advancedSearch({
                stateId: deviceIdParam,
                vendorId: vendorIdParam,
                synchroId: synchroIdParam
            })
        }
    }, [deviceIdParam, vendorIdParam, synchroIdParam])

    useEffect(()=>{
        console.log(currentDeviceId)
        console.log(getSearchHistory(game, mode))
        setCurrentDeviceId(CommonData.deviceId);
        setCurrentVendorId(CommonData.stateId);
        setCurrentSynchroId(CommonData.synchroId);
    }, [CommonData])

    useEffect(() => {
        if (advancedSearchResponse.status === 'fulfilled' && advancedSearchResponse.data.length === 1 && isEmpty(currentDeviceId)) {
            setOpen(true)
        }
    }, [advancedSearchResponse])

    let onAdvancedSearchComplete = '';

    if (advancedSearchResponse && advancedSearchResponse.status === 'fulfilled' && (advancedSearchResponse.data.length > 1 )) {
        onAdvancedSearchComplete = getMultipleStatesWarning(setOpen);
    } else if (advancedSearchResponse && advancedSearchResponse.status === 'fulfilled' && (advancedSearchResponse.data.length === 0)) {
        onAdvancedSearchComplete = getStatesNotFoundWarning();
    }

    return (
        <Box width={'100%'}>
            <Card sx={{mb:0, pb:0}}>
                <CardContent style={{paddingBottom:'16px'}}>
                    <Grid container  alignItems={'center'}>
                        <Grid mr={1} minWidth={'300px'} maxWidth={'300px'} item>
                            <Autocomplete
                                fullWidth
                                // style={{width: '400px'}}
                                id="autocomplete-search-state"
                                disabled={isMassCharge}
                                freeSolo
                                options={getSearchHistory(game, mode)}
                                value={currentDeviceId ? currentDeviceId : ''}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               label="State id"
                                               onBlur={(e) => handleChangeDeviceId(e.target.value)}

                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') {
                                                       e.preventDefault();
                                                       submitForm(e.target.value);
                                                   } else {
                                                       handleChangeDeviceId(e.target.value)
                                                   }
                                               }}
                                    />}
                            />
                        </Grid>
                        {['rt','cm','ba','sc'].includes(game) && !isCharger && <Grid mr={1} sx={5} minWidth={'300px'} maxWidth={'300px'} item>
                            <Autocomplete
                                // style={{width: '400px'}}
                                id="autocomplete-search-vendor"
                                disabled={isMassCharge}
                                freeSolo
                                options={getSearchHistoryVendorId(game, mode)}
                                // onChange={(e, v) => handleChangeStateId(v)}

                                value={currentVendorId ? currentVendorId : ''}
                                // onBlur={(e) => handleChangeStateId(e.target.value)}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               value={currentVendorId ? currentVendorId : ''}
                                               label="Vendor id"
                                        // onChange={(e) => handleChangeStateId(e.target.value)}
                                               onBlur={(e) => handleChangeStateId(e.target.value)}
                                    />}
                            />
                        </Grid>}
                        {['rt'].includes(game) && !isCharger && <Grid sx={5} minWidth={'300px'} maxWidth={'300px'} item>
                            <Autocomplete
                                // style={{width: '400px'}}
                                id="autocomplete-search-synchro"
                                disabled={isMassCharge}
                                freeSolo
                                options={getSearchHistorySynchroId(game, mode)}
                                value={currentSynchroId ? currentSynchroId : ''}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               value={currentSynchroId ? currentSynchroId : ''}
                                               label="Synchro id"
                                               onBlur={(e) => setSynchroId(e.target.value)}
                                    />}
                            />
                        </Grid>}
                        <Grid ml={1}>
                            <LoadingButton loading={advancedSearchResponse.isLoading} variant="contained" onClick={() => {submitForm()}}>search</LoadingButton>
                        </Grid>
                        <Grid ml={1}>
                            {['rt'].includes(game) &&
                                <Loading isLoading={advancedSearchResponse.status==='pending'}>
                                    {onAdvancedSearchComplete}
                                </Loading>
                            }
                        </Grid>
                    </Grid>
                    {open&&<Dialog open={open} onClose={() => setOpen(false)} maxWidth={'1000px'}>
                        <DialogTitle>
                            Choose state:
                        </DialogTitle>
                        <DialogContent>
                            <AdvancedSearchTable
                                advancedSearchResponse={advancedSearchResponse}
                                closeDialog={setOpen}
                                modalView={true}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant={'contained'} onClick={() => setOpen(false)}>cancel</Button>
                        </DialogActions>
                    </Dialog>}
                </CardContent>
            </Card>
        </Box>

    )
}