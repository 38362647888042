import api from "./base-api"

const tohArchiveApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getArchivedStateV2TOH: build.query({
            query: (userId = "") => `v2/toh/archive/${userId}`
        }),
        archiveStateV2TOH: build.mutation({
            query: (userId = "") => ({
                url: `v2/toh/archive/${userId}`,
                method: "POST"
            })
        }),
        restoreArchivedStateV2TOH: build.mutation({
            query: (data = "") => ({
                url: `v2/toh/archive/${data.userId}/restore`,
                method: "POST"
            })
        }),
        deleteArchivedStateV2TOH: build.mutation({
            query: (userId = "") => ({
                url: `v2/toh/archive/${userId}`,
                method: "DELETE"
            })
        })
    }),
    overrideExisting: false
})

export const {
    useGetArchivedStateV2TOHQuery,
    useArchiveStateV2TOHQuery,
    useRestoreArchivedStateV2TOHMutation,
    useDeleteArchivedStateV2TOHQuery
} = tohArchiveApiV2
