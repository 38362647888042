import React from 'react';

import {NavLink, Route, Routes, useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import UserStates from "../UserStates";
import Charger from "../Charger/toh";
import PrefabsTOH from "../Prefabs/toh/PrefabsTOH";
import ArchiveRT from "../Archive/rt/ArchiveRT";
import WhiteListManagement from "../WhiteListManagement";
import {Box, Drawer, MenuItem, MenuList, Toolbar} from "@mui/material";

import UserStatesTOH from "../UserStates/toh";
import ChargeManagement from "../ChargeManagement/ba";
import AllRtChargerLogs from "../ChargerLogs/rt/AllRtChargerLogs";
import AdvancedSearch from "../AdvancedSearch";
import {isEmpty} from "../../core/utils/StringUtils";
import {getDeviceIdAbsentWarning} from "../../core/utils/constants";
import SearchBarRT from "../Search/SearchRT";
import AdvancedSearchRT from "../AdvancedSearch/AdvancedSearchRT";
import {useGetGuidMapTohQuery} from "../../core/api/toh-charge-api";
import DashboardTOH from "../Dashboard/toh";
import AnalyticsCM from "../Analytics/cm";
import AnalyticsTOH from "../Analytics/toh";
import ArchiveTOH from "../Archive/toh/ArchiveTOH";

export default function MainTOH() {
    const {game, action} = useParams();
    const CommonData = useSelector((state) => state.data.CommonData)
    let deviceId = CommonData.deviceId ? CommonData.deviceId : '';
    let vendorId = CommonData.stateId ? CommonData.stateId : '';


    const location = useLocation();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    if (deviceId != '' && deviceId != CommonData.deviceId) {
        dispatch({
            type: "SET_DEVICE_ID",
            payload: {deviceId},
        });
    } else {
        if (typeof CommonData.deviceId == 'string') {
            deviceId = CommonData.deviceId
        }
    }


    const {
        data: guidMap,
        isLoading: isLoadingGuidMap,
        isSuccess: isSuccessGuidMap,
        isError: isErrorGuidMap,
        error: errorGuidMap
    } = useGetGuidMapTohQuery()


    const main_menu = [
        {
            to: '/' + game + '/dashboard/' + deviceId + '?vendorId=' +vendorId,
            path: 'dashboard/:deviceId?',
            title: 'Dashboard',
            element: <DashboardTOH/>
        },
        {
            to: '/' + game + '/state/' + deviceId + '?vendorId=' +vendorId,
            path: 'state/:deviceId?/:tab?/:subtab?',
            title: 'State',
            element: <UserStatesTOH/>,
            // disabled: deviceId == ''
        },

        {
            to: '/' + game + '/analytics/' + deviceId + '?vendorId=' +vendorId,
            path: 'analytics/:deviceId?/:tab?/:subtab?',
            title: 'Analytics',
            element: <AnalyticsTOH/>,
            // disabled: deviceId == ''
        },
    ];
    const state_menu = [

        {to: '/' + game + '/charger/' + deviceId + '?vendorId=' +vendorId, path: 'charger/:deviceId?', title: 'Charger', element: <Charger/>},

        {to: '/' + game + '/logs', path: 'logs', title: 'ChargerLogs', element: <AllRtChargerLogs/>},
        //{to:'reports', title:'Reports',component:'<Reports />'},

    ]
    const common_menu = [
        {
            to: '/' + game + '/prefabs/' + deviceId + '?vendorId=' +vendorId,
            path: "prefabs/:deviceId?",
            title: 'Prefabs',
            element: <PrefabsTOH game={game}/>
        },
        {
            to: '/' + game + '/archive/' + deviceId + '?vendorId=' +vendorId,
            path: "archive/:deviceId?",
            title: 'Archive',
            element: <ArchiveTOH game={game}/>
        },
        // {
        //     to: '/' + game + '/search',
        //     path: "search",
        //     title: 'Search',
        //     element: <AdvancedSearchRT game={game}/>
        // },
    ]
    const admin_menu = [
        {
            to: '/' + game + '/chargeManagement',
            path: "chargeManagement",
            title: 'Charge Management',
            element: <ChargeManagement game={game}/>
        },
        {
            to: '/' + game + '/whitelistManagement',
            path: "whitelistManagement",
            title: 'White list management',
            element: <WhiteListManagement/>
        },
    ]

    let menu = [
        ...main_menu,
        ...state_menu,
        ...common_menu
    ]

    const deviceIdIsEmpty = (isEmpty(deviceId) || typeof deviceId == 'object') && isEmpty(vendorId);

    const routes = (
        <Route>

            {menu.map((object) => (
                <Route path={object.path} element={object.element}/>
            ))}
            {admin_menu.map((object) => (
                <Route path={object.path} element={object.element}/>
            ))}
        </Route>
    );


    return (
        <>
            <div className="wrapper">
                <Drawer
                    sx={{
                        width: 230,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: 230, boxSizing: 'border-box'},

                    }}
                    variant="permanent"
                >
                    <Toolbar/>

                    <MenuList>
                        {menu.map((object) => (
                            <MenuItem
                                component={NavLink}
                                to={object.to}
                                disabled={object.disabled}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                {object.title}
                            </MenuItem>
                        ))}
                        <hr/>
                        {admin_menu.map((object) => (
                            <MenuItem
                                component={NavLink}
                                to={object.to}
                                disabled={object.disabled}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                {object.title}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Drawer>
                <Box component="main"
                     sx={{flexGrow: 1, p: 3}}
                >
                    <Toolbar/>

                    {['chargeManagement', 'whitelistManagement', 'search'].indexOf(action) == -1 &&
                        <Box display="flex" alignItems="center" sx={{mb: 2}}>
                            <SearchBarRT />
                        </Box>
                    }

                    <Box mb={2}>
                        {(deviceIdIsEmpty && (action == 'dashboard' || action=='state') ) ? getDeviceIdAbsentWarning() :
                            <Routes>
                                {main_menu.map((object) => (
                                    <Route path={object.path} element={object.element}/>
                                ))}
                            </Routes>
                        }
                        <Routes>
                            {state_menu.map((object) => (
                                <Route path={object.path} element={object.element}/>
                            ))}
                            {common_menu.map((object) => (
                                <Route path={object.path} element={object.element}/>
                            ))}
                            {admin_menu.map((object) => (
                                <Route path={object.path} element={object.element}/>
                            ))}
                        </Routes>

                    </Box>
                </Box>
            </div>
        </>

    )
}
