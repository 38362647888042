import {useGetPaymentsV1Query} from "../../core/api/bermuda-analytical-data-api";
import {Card, CardContent, Typography} from "@mui/material";
import {formatDate, paymentUserStatus} from "../../core/utils/constants";
import Loading from "../../components/Common/Loading";
import SimpleTable from "../../components/Common/Elements";

const PaymentsSummary = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPaymentsV1Query(props.deviceId)


    let sixMonthsAgo = ""
    let total = 0;
    let max_date = ""

    //6 months
    if (typeof data == "object") {
        sixMonthsAgo = new Date()
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6)
        const data6m = data.filter((payment) => new Date(payment.transactionTime) >= sixMonthsAgo)


        max_date = data6m.length > 0
            ? formatDate(new Date(Math.max(...data6m.map((e) => new Date(e["usdPrice"])))))
            : "-"

        console.log(max_date)

        total = parseFloat(data6m.reduce((previous, current) => previous + (current["usdPrice"] / 100 || 0), 0))
    }


    return (
        <Loading
            isLoading={isLoading}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            <Card>
                <CardContent>
                    <Typography sx={{fontSize: 16}}>
                        Payment info

                    </Typography>
                </CardContent>
                <SimpleTable data={
                    [
                        ['Segment', paymentUserStatus(total)],
                        ['Total', total],
                        ['Last 6mon payment', max_date],
                        ['Season pass', ""]
                    ]
                }/>
            </Card>

        </Loading>
    )
}

export default PaymentsSummary