import api from "./base-api"


const rogerthatStateApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getStateV2RT: build.query({
            query: (data) => {
                return `v2/rogerthat/states/${data.deviceId}/${data.stateId}`
            },
            providesTags: ['UserStates']
        }),
        getStatesByFacebookIdV2RT: build.query({
            query: (facebookId = "") => `v2/rogerthat/states/facebook/${facebookId}`
        }),
        createStateV2RT: build.mutation({
            query: (data) => {
                const {userId, vendorId, game, ...body} = data


                return {
                    url: `v2/rogerthat/states/${game}/${userId}?vendorId=${vendorId}`,
                    method: "POST",
                    body: body.body
                }
            },
            invalidatesTags: ['UserStates']
        }),
        clearData: build.mutation({
            query: () => null,
            onQueryStarted: (arg, {dispatch, queryFulfilled}) => {
                dispatch(clearData())
                queryFulfilled(undefined, {data: null})
            },
        }),
        checkRtExist: build.mutation({
            query: (body = {}) => ({
                url: `v2/rogerthat/states/exist`,
                method: "POST",
                body: body
            }),
        }),
        advancedSearch: build.mutation({
            query: (body = {}) => ({
                url: `v2/rogerthat/states/advancedSearch`,
                method: "POST",
                body: body
            }),
        }),
    }),
    overrideExisting: false
})

export const {
    useGetStateV2RTQuery,
    useGetStatesByFacebookIdV2RTQuery,
    useCreateStateV2RTMutation,
    clearData,
    useCheckRtExistMutation,
    useAdvancedSearchMutation
} = rogerthatStateApiV2
