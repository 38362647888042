import {DataGrid} from "@mui/x-data-grid";
import {useGetSpendGroupItemsTOHQuery, useGetSpendItemsTOHQuery} from "../../../core/api/toh-analytical-data-api";
import Loading from "../../../components/Common/Loading";

const columns = [
    { label: 'purchased', field: 'purchased', flex: 1 },
    { label: 'spend', field: 'spend', flex: 1 },
    { label: 'category', field: 'category', flex: 1 },

]

const GroupSpendTOH = (props) => {

    const {
        data: Groups,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        error
    } = useGetSpendGroupItemsTOHQuery({
        userId: props.deviceId
    })

    let payments = ""

    const data = Groups != undefined ? (() => {
        const result = {};

        // Process spend data
        Groups.spend.forEach(item => {
            const category = item.category || 'Unknown'; // Handle null categories
            if (!result[category]) {
                result[category] = { purchased: 0, spend: 0, category: category };
            }
            result[category].spend += parseFloat(item.totalAmount);
        });

        // Process purchase data
        Groups.purchase.forEach(item => {
            const category = item.category || 'Unknown';
            if (!result[category]) {
                result[category] = { purchased: 0, spend: 0, category: category };
            }
            result[category].purchased += parseFloat(item.totalAmount);
        });

        // Convert the result object to an array
        return Object.values(result);
    })() : [];

    if (typeof data === "object") {

        payments = <DataGrid
            getRowId={(row) => row.category}
            rows={data}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        payments = "no data"
    }

    return (
        <Loading
            isLoading={isLoading||isFetching}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {payments}

        </Loading>
    )
}

export default GroupSpendTOH