import React from "react"

import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {format} from "date-fns";

const ListJson = ({items, fields}) => {


    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>id</TableCell>
                    {fields.map(field => (
                            <TableCell>{field}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(items).map((key) => (
                    <TableRow
                    >
                        <TableCell>{key}</TableCell>
                        {fields.map(field => (
                            <TableCell>{items[key][field]}</TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>

    )
}

export default ListJson
