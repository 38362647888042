import {Box, Card, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {formatDate, timestampToDate} from "../../../core/utils/constants";

export default function Events({eventsData}) {
    const rows = eventsData ? Object.entries(eventsData).map((([id, event]) => ({
        id: id,
        eventId: event.Id,
        finishReason: event.FinishReason,
        finishTime: formatDate(timestampToDate(event.FinishedSec)),
        level: event.LevelsComplete ? event.LevelsComplete : "no data"
    }))) : [];
    let activeBP = eventsData.filter(event => {
        return event["$type"] === "BPStateData, Assembly-CSharp" && event.FinishedSec === 0;
    });

    return (
        <Box>
            <Card>
                <Table>
                    <TableHead>
                        <TableRow>

                            <TableCell>#</TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>Finish Reason</TableCell>
                            <TableCell>Finished Time</TableCell>
                            <TableCell>Complete levels</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.eventId}</TableCell>
                                <TableCell>{row.finishReason}</TableCell>
                                <TableCell>{row.finishTime}</TableCell>
                                <TableCell>{row.level}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>
            <Card>
                {activeBP.length !== 0 ?
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>BP name</TableCell>
                                <TableCell>{activeBP[0].Id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Score</TableCell>
                                <TableCell>{activeBP[0].Score}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Gold Pass</TableCell>
                                <TableCell>{activeBP[0].GoldPassPurchased.toString()}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table> :
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>No current BP</TableCell>
                                <TableCell>-</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
            </Card>
        </Box>
    );
}