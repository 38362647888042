import api from "./base-api"

const chargeManagementApi = api.injectEndpoints({
    endpoints: (build) => ({
        createChargeV2: build.mutation({
            query: (param) => ({
                url: `v2/${param.game}/chargesTypes`,
                method: "POST",
                body: param.data
            }),
            invalidatesTags: ['ChargeTypes']
        }),

        getChargesTypesV2: build.query({
            query: (game = "") => `v2/${game}/chargesTypes`,
            transformResponse(data) {
                return data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            },
            providesTags: ['ChargeTypes']
        }),

        updateChargeV2: build.mutation({
            query: (param) => {
                return ({
                    url: `v2/${param.game}/chargesTypes/${param.data.objectId ? param.data.objectId : param.data.id}`,
                    method: "POST",
                    body: param.data
                })
            },
            invalidatesTags: ['ChargeTypes']
        }),

        deleteChargeV2: build.mutation({
            query: (param) => ({
                url: `v2/${param.game}/chargesTypes/${param.id}`,
                method: "DELETE"
            }),
            invalidatesTags: ['ChargeTypes']
        })
    }),
    overrideExisting: false
})

export const {
    useCreateChargeV2Mutation,
    useGetChargesTypesV2Query,
    useUpdateChargeV2Mutation,
    useDeleteChargeV2Mutation
} = chargeManagementApi