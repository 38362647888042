import api from "./base-api"


const tohChargeApi = api.injectEndpoints({
    endpoints: (build) => ({
        getGuidMapToh: build.query({
            query: () => `v2/toh/states/guid-map`
        }),
        getChargesToh: build.query({
            query: (userId = "") => `v2/toh/charges/${userId}`,
            providesTags: ['Charges']
        }),
        createChargeToh: build.mutation({
            query: (body = {}) => ({
                url: `v2/toh/charges`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Charges', 'TohChargerLogsById', 'TohChargerLogs'],
        }),
        createMassChargeToh: build.mutation({
            query: (body = new FormData()) => ({
                url: `v2/toh/charges`,
                method: "POST",
                body: body
            })
        }),
        dischargeToh: build.mutation({
            query: (body = []) => ({
                url: `v2/toh/charges/discharge`,
                method: "PUT",
                body: body
            })
        })
    }),
    overrideExisting: false
})

export const selectGuidMapToh = (state) => api.endpoints.getGuidMapToh.select()(state).data


export const {
    useGetGuidMapTohQuery,
    useGetChargesTohQuery,
    useCreateChargeTohMutation,
    useCreateMassChargeTohQuery
} = tohChargeApi
