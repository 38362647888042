import Loading from "../../components/Common/Loading";
import UserSocialInfo from "./userSocialInfo";
import {useEffect, useState} from "react";
import {useGetUserInfoV1SCQuery} from "../../core/api/solitaire-analytical-data-api";


const UserSocialInfoSC = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUserInfoV1SCQuery(props.deviceId)
    const [infoData, setInfoData] = useState("");

    useEffect(() => {
        setInfoData(data)

    }, [data])

    return (
        <Loading
            isLoading={isLoading}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {infoData && <UserSocialInfo
                data={infoData}

            />
            }
        </Loading>
    )
}

export default UserSocialInfoSC