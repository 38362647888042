import {useGetPaymentsV1CMQuery} from "../../core/api/clockmaker-analytical-data-api";
import Loading from "../../components/Common/Loading";
import {DataGrid} from "@mui/x-data-grid";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import PurchaseCM from "../Analytics/cmPurchase";
import {useState} from "react";

const PaymentsCM = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        error
    } = useGetPaymentsV1CMQuery(props.deviceId)


    const [showDetails, setShowDetails] = useState(false)
    const [selectedPayload, setSelectedPayload] = useState(0)

    const handleViewDetails = (data) => {
        setSelectedPayload(data)
        setShowDetails(true)
    }

    const columns = [
        {
            field: 'action',
            headerName: 'Purchases',
            width: 70,
            renderCell: (params) => {
                // Assuming isAdmin is available in the component's scope
                return <IconButton
                    aria-label="edit"
                    onClick={() => handleViewDetails(params.row.payload)}
                >
                    <SearchIcon />
                </IconButton>
            },
            sortable: false,
            filterable: false,
        },
        {
            label: 'Currency',
            field: 'currency',
            width: 100
        },
        {
            label: 'UsdCents',
            field: 'usdCents',
            width: 120
        },
        {
            label: 'Transaction ID',
            field: 'sTransactionId',
            width: 220
        },
        {
            label: 'Transaction Time',
            field: 'transactionTime',
            width: 200
        },
        {
            label: 'Store ID',
            field: 'storeId',
            width: 470
        },
        {
            label: 'Category',
            field: 'category',
            width: 150
        },
        {
            label: 'Item',
            field: 'item',
            width: 220
        },
        {
            label: 'Ruby',
            field: 'ruby',
            width: 150
        },
        {
            label: 'Value',
            field: 'value',
            width: 150
        },
        {
            label: 'payload',
            field: 'payload',
            width: 150
        },
    ]

    let payments = ""

    if (typeof data === "object") {

        payments = <DataGrid
            getRowId={(row) => row.transactionId}
            rows={data}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        payments = "no data"
    }

    return (
        <><Loading
            isLoading={isLoading||isFetching}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {payments}

        </Loading>

            {showDetails&&<Dialog
                maxWidth={"xl"}
                fullWidth={true}
                scroll={"paper"}
                open={showDetails} onClose={() => setShowDetails(false)}>
                <DialogTitle></DialogTitle>
                <DialogContent dividers={true}>
                    <PurchaseCM deviceId={props.deviceId} payload={selectedPayload}/>
                </DialogContent>
                <DialogActions>

                    <Button variant="outlined"
                            onClick={() => setShowDetails(false)}>
                        Close
                    </Button>

                </DialogActions>


            </Dialog>}
        </>
    )
}

export default PaymentsCM