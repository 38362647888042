import {useGetGuidMapQuery} from "../api/bermuda-charge-api";

const CONSUMABLES = 'Consumables';
const EVER_MERGE_ITEMS = 'EverMergeItems';
const BLUEPRINTS = 'Blueprints';
const LOCATIONS = 'Locations';
const QUESTS = 'Quests';
const COMPENDIUM_TASKS = 'CompendiumTasks';
const TUTORIALS = 'Tutorials';


const getGuidMapSection = (key, guidMap) => {
    switch (key) {
        case CONSUMABLES:
            return guidMap.Consumables;
        case EVER_MERGE_ITEMS:
            return guidMap.EverMergeItems;
        case BLUEPRINTS:
            return guidMap.Blueprints;
        case LOCATIONS:
            return guidMap.Locations;
        case QUESTS:
            return guidMap.Quests;
        case COMPENDIUM_TASKS:
            return guidMap.CompendiumTasks;
        case TUTORIALS:
            return guidMap.Tutorials;
    }
}
const getChargerLogKey = (key, chargerLogData) => {
    switch (key) {
        case CONSUMABLES:
            return chargerLogData.consumableKey;
        case EVER_MERGE_ITEMS:
            return chargerLogData.itemKey;
        case BLUEPRINTS:
            return chargerLogData.blueprintKey;
        case LOCATIONS:
            return chargerLogData.locationKey;
        case QUESTS:
            return chargerLogData.questKey;
        case COMPENDIUM_TASKS:
            return chargerLogData.compendiumTaskKey;
        case TUTORIALS:
            return chargerLogData.tutorialKey;
    }
}

export const GetItemNameGeneral = (chargerLogData) => {
    const NO_VALUE = 'no value';
    const chargersMap = new Map([
        ['consumableKey', CONSUMABLES],
        ['itemKey', EVER_MERGE_ITEMS],
        ['blueprintKey', BLUEPRINTS],
        ['locationKey', LOCATIONS],
        ['questKey', QUESTS],
        ['compendiumTaskKey', COMPENDIUM_TASKS],
        ['tutorialKey', TUTORIALS],
    ]);

    const {
        data: guidMap,
    } = useGetGuidMapQuery();

    if (chargerLogData) {
        const chargerLogDataKeys = Object.keys(chargerLogData);
        let itemName = NO_VALUE;
        chargersMap.forEach((value, key) => {
            if (chargerLogDataKeys.includes(key) && guidMap.hasOwnProperty(value)) {
                const names = getGuidMapSection(value, guidMap).filter(guidData => {
                    return guidData.guid === getChargerLogKey(value, chargerLogData);
                })
                itemName = names.length > 0 ? names[0].name : NO_VALUE;
            }
        })
        return itemName;
    }
    return 'no value';
};

export const getQuestName = (questKey, guidMap) => {
    if (guidMap.hasOwnProperty(QUESTS)) {
        let quest = guidMap[QUESTS].filter(value => value.guid === questKey);
        return (quest !== undefined && quest !== null && quest[0]) ? quest[0].name : 'no value';
    }
    return 'no value';
}
export const getGuidNameByKey = (key, guidMap, category) => {
    if (guidMap.hasOwnProperty(category)) {
        let quest = guidMap[category].filter(value => value.guid === key);
        return (quest !== undefined && quest !== null && quest[0]) ? quest[0].name : key;
    }
    return key;
}