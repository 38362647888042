import {Box, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import SimpleTable from "../../../components/Common/Elements";

export default function Events({tournaments, twenties}) {
    let twentiesForDisplay = {}

    if (twenties && twenties.length > 0) {
        twentiesForDisplay.id = twenties[0].ID+"";
        twentiesForDisplay.start = new Date(twenties[0].Start*1000).toString();
        twentiesForDisplay.finish = twenties[0].Finish+"";
        twentiesForDisplay.update = new Date(twenties[0].Update*1000).toString();
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid sm={8} item>
                    <Card>
                        <Typography m={2}>
                            <b>Tournaments</b>
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow>

                                    <TableCell>#</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Place</TableCell>
                                    <TableCell>Score</TableCell>
                                    <TableCell>Active</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tournaments && tournaments.map((row, i) => (
                                    <TableRow>
                                        <TableCell>{i+1}</TableCell>
                                        <TableCell>{row.ID}</TableCell>
                                        <TableCell>{row.Leaderboard?.Boards?.board?.VisualPlace + ""}</TableCell>
                                        <TableCell>{row.Leaderboard?.Boards?.board?.VisualScore + ""}</TableCell>
                                        <TableCell>{row.Distribution?.active+""}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Card>
                </Grid>
                <Grid sm={4} item>
                    <Card>
                        <Typography m={2}>
                            <b>Twenties TP</b>
                        </Typography>
                        <SimpleTable data={Object.entries(twentiesForDisplay)}/>
                    </Card>
                </Grid>
            </Grid>


        </Box>
    );
}