import {Box, Button, Card, Dialog} from "@mui/material";
import {MATERIALS} from "../../../core/utils/constants";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import CmChargeBlock from "../../Charger/cm/cmChargeBlock";
import React, {useState} from "react";

export default function Materials({materials}) {
    const [chargeItemsDialog, setChargeItemsDialog] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const disable = selectedRows.length === 0

    let rows = [];
    if (materials) {
        rows = Object.entries(materials).map((([key, value]) => ({
            id: MATERIALS.has(value.Id) ? MATERIALS.get(value.Id) : value.Id,
            count: value.Count,
        }))).map(row => ({id: row.id, name: row.id, data: row.count}));
    }

    const columns: GridColumns = [
        {field: 'name', type: '', headerName: 'Name', hide: false, width: 700},
        {field: 'data', headerName: 'Data', hide: false, width: 700},
    ]

    const handleSelectRows = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = rows.filter((row) =>
            selectedIDs.has(row.id.toString()));
        setSelectedRows(selectedRowData);
    }

    return (
        <Card>
            {/*<Button*/}
            {/*    disabled={disable}*/}
            {/*    variant={'contained'}*/}
            {/*    color={"success"}*/}
            {/*    onClick={() => setChargeItemsDialog(!chargeItemsDialog)}>*/}
            {/*    Charge items*/}
            {/*</Button>*/}
            <Box sx={{width: '100%', height: 600}}>
                <DataGrid
                    checkboxSelection={true}
                    onSelectionModelChange={(ids) => handleSelectRows(ids)}
                    columns={columns}
                    rows={rows}>
                </DataGrid>
            </Box>
            <Dialog open={chargeItemsDialog} fullWidth={true}>
                <CmChargeBlock
                    massCharge={false}
                    initialSelectedItems={selectedRows}
                />

                <Button
                    disabled={disable}
                    variant={'contained'}
                    color={"success"}
                    onClick={() => setChargeItemsDialog(!chargeItemsDialog)}>
                    Cancel
                </Button>

            </Dialog>

            {/*<Table>*/}
            {/*    <TableBody>*/}
            {/*        */}
            {/*        {rows.map((row) => (*/}
            {/*            <TableRow>*/}
            {/*                <TableCell>{row.id}</TableCell>*/}
            {/*                <TableCell>{row.count}</TableCell>*/}
            {/*            </TableRow>*/}
            {/*        ))}*/}
            {/*    </TableBody>*/}
            {/*</Table>*/}
        </Card>
    );
}