import api from "./base-api"

const clockmakerArchivedStateApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getArchivedStateCMV2: build.query({
            query: (userId = "") => `v2/clockmaker/states/archive/${userId}`
        }),
        archiveStateCMV2: build.mutation({
            query: (userId = "") => ({
                url: `v2/clockmaker/states/archive/${userId}`,
                method: "POST"
            })
        }),
        restoreArchivedStateCMV2: build.mutation({
            query: (data = "") => ({
                url: `v2/clockmaker/states/archive/${data.userId}/restore`,
                method: "POST"
            })
        }),
        deleteArchivedStateCMV2: build.mutation({
            query: (userId = "") => ({
                url: `v2/clockmaker/states/archive/${userId}`,
                method: "DELETE"
            })
        })
    }),
    overrideExisting: false
})

export const {
    useGetArchivedStateCMV2Query,
    useArchiveStateCMV2Query,
    useRestoreArchivedStateCMV2Mutation,
    useDeleteArchivedStateCMV2Query
} = clockmakerArchivedStateApiV2
