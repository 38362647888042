import React from 'react';

import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {ApiProvider} from "@reduxjs/toolkit/dist/query/react";
import {BrowserRouter} from "react-router-dom"

import './index.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import {store} from './core/utils/configureStore';
import api from './core/api/base-api'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApiProvider api={api}>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </ApiProvider>,
    root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
