import React, {useState} from "react"

import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {format} from "date-fns";
import {useGetGuidMapRTQuery} from "../../../core/api/rogerthat-charge-api";
import {getGuidNameByKey} from "../../../core/utils/GuidMapUtil";

const RTLocationsTable = ({locations}) => {

    console.log('locations')

    const [selectedLocation, setSelectedLocation] = useState(locations.Location)
    const {
        data: guidMap,
    } = useGetGuidMapRTQuery()


    return (
        <Grid container>
            <Grid item sm={5}>
                <h2>Locations</h2>

                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>

                            <TableCell>name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>LastVisit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(locations._tokens).map(item => (
                            <TableRow hover={true}
                                      selected={selectedLocation == item}
                                      onClick={() => setSelectedLocation(item)}
                            >
                                <TableCell>{item}</TableCell>
                                <TableCell>{locations._tokens[item].Type}</TableCell>
                                <TableCell>{locations._tokens[item].State}</TableCell>
                                <TableCell>
                                    {format(new Date(locations._tokens[item].LastVisit * 1000), "dd.MM.yyyy HH:mm:ss")}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
            <Grid item sm={7}>
                <Grid container>
                    <Grid item sm={6}>

                        <h2>Consumables</h2>
                        {(selectedLocation !== false && locations._locations.hasOwnProperty(selectedLocation)) &&
                            <Table size="small">
                                <TableHead>
                                    <TableRow>

                                        <TableCell>Reference</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locations._locations[selectedLocation].Items.Consumables.map(item => (
                                        <TableRow>

                                            <TableCell>{getGuidNameByKey(item.Reference, guidMap, 'Transient')}</TableCell>
                                            <TableCell>{item.Amount}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        }

                        <h2>Transient</h2>
                        {(selectedLocation !== false && locations._locations.hasOwnProperty(selectedLocation)) &&
                            <Table size="small">
                                <TableHead>
                                    <TableRow>

                                        <TableCell>Reference</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locations._locations[selectedLocation].Items.Transient.map(item => (
                                        <TableRow>

                                            <TableCell>{getGuidNameByKey(item.Reference, guidMap, 'Transient')}</TableCell>
                                            <TableCell>{item.Amount}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        }

                        <h2>Unlimited</h2>
                        {(selectedLocation !== false && locations._locations.hasOwnProperty(selectedLocation)) &&
                            <Table size="small">
                                <TableHead>
                                    <TableRow>

                                        <TableCell>Reference</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locations._locations[selectedLocation].Items.Unlimited.map(item => (
                                        <TableRow>

                                            <TableCell>{getGuidNameByKey(item.Reference, guidMap, 'Transient')}</TableCell>
                                            <TableCell>{item.Amount}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        }

                        <h2>Permanent</h2>
                        {(selectedLocation !== false && locations._locations.hasOwnProperty(selectedLocation)) &&
                            <Table size="small">
                                <TableHead>
                                    <TableRow>

                                        <TableCell>Reference</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locations._locations[selectedLocation].Items.Permanent.map(item => (
                                        <TableRow>

                                            <TableCell>{getGuidNameByKey(item.Reference, guidMap, 'Permanent')}</TableCell>
                                            <TableCell>{item.Amount}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        }


                    </Grid>
                    <Grid item sm={6}>
                        <h2>Quests</h2>
                        {(selectedLocation !== false && locations._locations.hasOwnProperty(selectedLocation)) &&
                            <Table size="small">
                                <TableHead>
                                    <TableRow>

                                        <TableCell>Reference</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(locations._locations[selectedLocation].QuestProfile._quests).map(item => (
                                        <TableRow>

                                            <TableCell>{getGuidNameByKey(locations._locations[selectedLocation].QuestProfile._quests[item].Reference, guidMap, 'Quests')}</TableCell>
                                            <TableCell>{locations._locations[selectedLocation].QuestProfile._quests[item].Status}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}

export default RTLocationsTable
