import api from "./base-api"

const bermudaArchivedStateApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getArchivedStateV2: build.query({
            query: (userId = "") => `v2/bermuda/states/archive/${userId}`
        }),
        archiveStateV2: build.mutation({
            query: (userId = "") => ({
                url: `v2/bermuda/states/archive/${userId}`,
                method: "POST"
            })
        }),
        restoreArchivedStateV2: build.mutation({
            query: (data = "") => ({
                url: `v2/bermuda/states/archive/${data.userId}/restore`,
                method: "POST"
            })
        }),
        deleteArchivedStateV2: build.mutation({
            query: (userId = "") => ({
                url: `v2/bermuda/states/archive/${userId}`,
                method: "DELETE"
            })
        })
    }),
    overrideExisting: false
})

export const {
    useGetArchivedStateV2Query,
    useArchiveStateV2Query,
    useRestoreArchivedStateV2Mutation,
    useDeleteArchivedStateV2Query
} = bermudaArchivedStateApiV2
