import {
    Button,
    Card, CardActions,
    CardContent,
    CardHeader,
    Grid,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import Loading from "../../components/Common/Loading";
import SimpleTable from "../../components/Common/Elements";
import {useGetChatCMQuery, useJoinCMMutation} from "../../core/api/clockmaker-clans-api";
import {format} from "date-fns";
import {useContext, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {SnackBarContext} from "../../snackBarProvider";

const ClanJoin = (props) => {

    const [
        joinClan,
        joinClanResponse
    ] = useJoinCMMutation(props.userId)


    const [newclan, setNewclan] = useState()

    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const onJoinClan = async () => {
        const response = await joinClan({
            community: newclan,
            userId: props.userId
        })

        debugger
        if (response.hasOwnProperty('error')) {
            const errorData = response.error.data.hasOwnProperty('message')?JSON.parse(response.error.data.message):{}
            addAlert({text: errorData.message, type: 'error'})
        } else {
            addAlert({text: 'Saved', type: 'success'})
        }


    }


    return (
        <Card>

            <CardContent>
                <Typography variant="h6" component="div" display="inline">
                    Join to community
                </Typography>
                <div>

                    <TextField placeholder={"Community id"} value={newclan} onChange={(e)=>{
                        setNewclan(e.target.value)
                    }}></TextField>
                </div>

            </CardContent>
            <CardActions>
                <LoadingButton
                    isLoading={joinClanResponse.isLoading}
                    onClick={()=>{
                    onJoinClan()
                }}>JOIN</LoadingButton>
            </CardActions>
        </Card>
    )
}

export default ClanJoin