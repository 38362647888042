import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useContext, useState} from "react";
import {useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Editor from "@monaco-editor/react";
import {SnackBarContext} from "../../snackBarProvider";

import { parse, stringify } from 'lossless-json'


const MyListItem = ({item, selectItem, selectedItem, onDelete}) => {
    const [hover, setHover] = React.useState(false);
    // export default useSnackBars

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <ListItem
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <ListItemButton
                onClick={() => selectItem(item)}
                selected={item.id === selectedItem.id}
            >
                <ListItemText
                    primary={item.date}
                    secondary={item.id}
                />

            </ListItemButton>
        </ListItem>
    );
};

export default function Archive(props) {
    const {
        archiveListReady,
        archiveList,
        restoreState,
        restoreStateResponse,

        refetch
    } = props;

    const {deviceId: deviceIdParam} = useParams();
    const deviceId = props.stateId != undefined ? props.stateId : deviceIdParam || '';

    const [selectedItem, setSelectedItem] = useState({});
    const [itemFilter, setItemFilter] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [state, setState] = useState({
        isConfirmOpen: false
    })
    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();


    function selectItem(item) {
        setSelectedItem(item)
    }

    function showConfirmDialog(item) {
        setSelectedItem(item)
        setState({
            ...state,
            isConfirmOpen: true
        })

    }

    async function handleConfirmApplyState(event) {
        setState({
            ...state,
            isConfirmOpen: false
        })

        const response = await restoreState({
            userId: deviceId,
            version: 'v2'
        })

        // setOpenSnackbar(response.error)

        if (response.error) {
            if (response.error.status === 403) {
                addAlert({text: 'This id is not in the whitelist', type: 'error'})
            } else {
                addAlert({text: 'Something went wrong', type: 'error'})
            }
        } else {
            addAlert({text: 'State restored', type: 'success'})
        }

        console.log("openSnackbar", openSnackbar)
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    };

    return (
        <>
            <Card sx={{mb: 2}}>
                <CardContent>

                    <Grid container>
                        <Grid item sm={3}>


                            <List component="nav" className="compact-list">
                                {archiveListReady && archiveList.map((item) => (
                                    <>
                                        <MyListItem
                                            item={item}
                                            selectItem={selectItem}
                                            selectedItem={selectedItem}
                                        />
                                        <Divider/>
                                    </>


                                ))}
                                {
                                    archiveList.length == 0 && <>
                                        No archived states
                                    </>
                                }


                            </List>
                        </Grid>
                        <Grid item sm={9}>

                            {selectedItem.hasOwnProperty("id") &&
                                <>
                                    <div>
                                        <Box sx={{mb: 3}}>


                                            <LoadingButton variant={"contained"}
                                                           disabled={!selectedItem.hasOwnProperty('id')}
                                                           loading={restoreStateResponse.isLoading}
                                                           color={"success"}
                                                           sx={{mr: 1}}
                                                           startIcon={<CheckCircleIcon/>}
                                                           onClick={() => showConfirmDialog(selectedItem)}>Восстановить</LoadingButton>


                                        </Box>
                                    </div>
                                    <Editor height="90vh"
                                            defaultLanguage="json"
                                            value={stringify(parse(selectedItem.data), null, 2)}
                                            onChange={(value, event) => setSelectedItem({
                                                ...selectedItem,
                                                data: value
                                            })}
                                    />
                                </>
                            }

                        </Grid>
                    </Grid>


                </CardContent>

            </Card>
            <Dialog open={state.isConfirmOpen}>
                <DialogContent>
                    Восстановить стэйт из архива <br/>
                    <strong>{selectedItem.date}</strong>
                    <br/> для state id<br/>
                    <strong>{deviceId}</strong>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={() => {
                            setState({...state, isConfirmOpen: false})
                        }}>
                        Отменить
                    </Button>
                    <LoadingButton
                        onClick={handleConfirmApplyState}
                        variant="outlined"
                        color="warning"
                    >
                        Да
                    </LoadingButton>

                </DialogActions>
            </Dialog>
        </>
    );
}

Archive.propTypes = {
    archiveListReady: PropTypes.bool.isRequired,
    archiveList: PropTypes.array.isRequired,
    restoreState: PropTypes.func.isRequired,
    restoreStateResponse: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired

};
