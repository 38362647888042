import {Card, Table, TableBody, TableCell, TableRow} from "@mui/material";

export default function Clans({data}) {

    return (
        <Card>
            <Table>
                <TableBody>
                    {Object.keys(data).map((key) => (
                        <TableRow>
                            <TableCell>{key}</TableCell>
                            <TableCell>{JSON.stringify(data[key])}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}