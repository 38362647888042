import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

import {Box, Button, Card, CardContent, Grid, Tab, Tabs, Typography} from "@mui/material";


import Loading from "../../../components/Common/Loading";
import ChargesList from "../chargesList";

import {Alert, LoadingButton, TabContext, TabPanel} from "@mui/lab";


import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import {useGetChargesTypesV2Query} from "../../../core/api/charge-api";
import {useCreateChargeScV2Mutation, useGetChargesScV2Query} from "../../../core/api/solitaire-charge-api";
import {hasText} from "../../../core/utils/constants";
import ScCharge from "./scCharge";
import DeviceScChargerLogs from "../../ChargerLogs/sc/DeviceScChargerLogs";
import {useSelector} from "react-redux";

dayjs.extend(utc); // extend Day.js with the UTC plugin


function Charger() {
    const CommonData = useSelector((state) => state.data.CommonData);
    console.log("init charger")
    const {game} = useParams()

    let deviceId = CommonData.deviceId;

    const {
        data: chargesData,
        isLoading: chargesLoading,
        isSuccess: chargesSuccess,
        isError: chargesIsError,
        error: chargesError
    } = useGetChargesScV2Query(deviceId)

    const [
        createCharge,
        createChargeResponse
    ] = useCreateChargeScV2Mutation()

    const {
        data: chargeTypes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetChargesTypesV2Query(game);

    const [charges, setCharges] = useState([]);

    const [isMassCharge, setIsMassCharge] = useState(false)

    const [massChargeList, setMassChargeList] = useState("")
    const [formErrors, setFormErrors] = useState({})
    const [isChargeButtonDisable, setChargeButtonDisable] = useState(true);
    const [tabIndex, setTabIndex] = useState("1");
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    useEffect(() => {
        validateData();
    }, [charges, deviceId])

    useEffect(() => {
        addCharges();
    }, [])

    const validateData = () => {

        const newCharges = [...charges];
        charges.forEach((c, index) => {
            const charge = newCharges[index];
            charge.errors = !(hasText(charge.amount) && hasText(charge.type));
        })

        if (newCharges.length === 0 || newCharges.some(c => c.errors) || !hasText(deviceId)) setChargeButtonDisable(true)
        else setChargeButtonDisable(false);
    }

    console.log('charges', charges)

    const handleChange = (data, field, index) => {
        const newCharges = [...charges]
        let charge = newCharges[index];
        if (field === 'name') {
            charge.name = data;
            charge.type = chargeTypes.filter(c => c.name === data)[0].type;
            charge.amount = ("")
            charge.id = chargeTypes.filter(c => c.name === data)[0].id;
        }
        if (field === 'amount') {
            if (/^(?:\d*|)$/.test(data))
                charge.amount = data;
        }
        setCharges(newCharges)
    }

    const createChargeHandler = () => {


        createCharge({
            "userId": deviceId,
            "chargeData": charges.map(c => {
                return {
                    "type": c.type,
                    "id": c.id,
                    "amount": c.amount
                }
            })
        })

    }

    function amountToCharge() {
        let userIds = []
        if (isMassCharge) {
            userIds = massChargeList.split(/[\r\n]+/)
        } else {
            userIds = [
                deviceId
            ]
        }
        userIds = userIds.filter((item) => {
            return item != ''
        })
        return userIds.length

    }

    console.log('createChargeResponse')
    console.log(createChargeResponse)

    const addCharges = () => {
        const newCharges = [...charges]
        newCharges.push({type: "", amount: "", id: "", error: true, name: ""});
        setCharges(newCharges);
    }

    const removeCharge = (index) => {
        const newCharges = [...charges];
        newCharges.splice(index, 1)
        setCharges(newCharges)
    }

    return (
        <>
            <Card sx={{mb: 2}}>
                <CardContent>
                    <Loading
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                        isSuccess={isSuccess}>

                        {charges.map((charge, index) => {
                            return <ScCharge
                                chargeTypes={chargeTypes}
                                handleChange={handleChange}
                                index={index}
                                charge={charge}
                                charges={charges}
                                remove={removeCharge}
                            />
                        })}

                    </Loading>
                    <Grid mt={3}>
                        <Button
                            variant="contained"
                            color="info"
                            onClick={() => addCharges()}
                        >
                            ADD
                        </Button>
                    </Grid>
                    <Grid mt={1}>
                        <Button
                            disabled={isChargeButtonDisable}
                            loading={createChargeResponse.isLoading}
                            variant={"contained"}
                            color={"success"}
                            onClick={createChargeHandler}>Charge
                        </Button>
                    </Grid>

                    {Object.keys(formErrors).length > 0 &&
                        <Alert severity="error" sx={{mb: 2}}>
                            {Object.values(formErrors).map(error => (
                                <div key={formErrors}>{error}</div>
                            ))}
                        </Alert>
                    }
                    {createChargeResponse.isSuccess &&
                        <Alert severity="info" sx={{mb: 2, mt: 2}}>
                            Charge result OK
                        </Alert>
                    }
                    <Typography mt={1}>
                        {amountToCharge()} ids to charge
                    </Typography>


                </CardContent>

            </Card>

            {deviceId != '' && chargesSuccess && <TabContext value={tabIndex}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Charges" value="1"/>
                    <Tab label="Charger Logs" value="2"/>
                </Tabs>
                <TabPanel value="1">
                    <Card>
                        <ChargesList
                            game={game}
                            deviceId={deviceId}
                            chargesData={chargesData}
                            chargesLoading={chargesLoading}
                            chargesSuccess={chargesSuccess}
                            chargesIsError={chargesIsError}
                            chargesError={chargesError}
                            chargeTypes={chargeTypes}
                        />
                    </Card>
                </TabPanel>
                <TabPanel value="2">
                    <DeviceScChargerLogs deviceId={deviceId}/>
                </TabPanel>
            </TabContext>}

        </>
    );

}

export default Charger;
