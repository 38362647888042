import api from "./base-api"

const whitelistManagementApi = api.injectEndpoints({
    endpoints: (build) => ({
        createWhitelistV2: build.mutation({
            query: (data) => ({
                url: `v2/whitelists/${data.game}`,
                method: "POST",
                body: data.whitelist
            }),
            invalidatesTags: ['Whitelists']
        }),
        getWhitelistsV2: build.query({
            query: (game = "") => `v2/whitelists/${game}`,
            providesTags: ['Whitelists']
        }),
        deleteWhitelistV2: build.mutation({
            query: (data) => ({
                url: `v2/whitelists/${data.game}/${data.whitelist}`,
                method: "DELETE"
            }),
            invalidatesTags: ['Whitelists']
        })
    }),
    overrideExisting: false
});

export const {
    useCreateWhitelistV2Mutation,
    useGetWhitelistsV2Query,
    useDeleteWhitelistV2Mutation
} = whitelistManagementApi