import {
    Button,
    Card,
    CardContent,
    CardHeader, Dialog, DialogActions,
    DialogContent, FormControl,
    Grid, InputLabel, MenuItem, Select,
    Table,
    TableCell,
    TableRow, TextField,
    Typography
} from "@mui/material";
import Loading from "../../components/Common/Loading";
import SimpleTable from "../../components/Common/Elements";
import {
    useGetMembershipCMQuery,
    useKickCMMutation,
    usePromoteCMMutation,
    useSetCheaterCMMutation, useSetRenameCMMutation
} from "../../core/api/clockmaker-clans-api";
import DialogTitle from "@mui/material/DialogTitle";
import {LoadingButton, TabPanel} from "@mui/lab";
import {useState} from "react";
import {useCreateStateV2CMMutation} from "../../core/api/cloclmaker-state-api";
import {format} from "date-fns";
import ClanJoin from "./clanJoin";



const ClanInfo = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMembershipCMQuery(props.userId)

    const [dialogsState, setDialogsState] = useState({
        cheater:false,
        promote:false,
        kick:false,
        rename:false
    })

    const [cheaterDialogOpened, setCheaterDialogOpened] = useState(false)
    const [kickDialogOpened, setKickDialogOpened] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)


    const [setCheater, setCheaterResponse] = useSetCheaterCMMutation();
    const [setRename, setRenameResponse] = useSetRenameCMMutation();
    const [kick, kickResponse] = useKickCMMutation();
    const [promote, promoteResponse] = usePromoteCMMutation();

    const [newname, setNewname] = useState('')

    const saveCheaterStatus = (cheater) => {
        setCheater({
            userId: selectedUser.id,
            cheater: cheater
        })
        setDialogsState({...dialogsState, cheater: false})
    }

    const saveKick = () => {
        setDialogsState({...dialogsState, kick: false})
        kick({
            userId: data.response.communities[0].owner,
            kickId: selectedUser.id
        })
    }

    const savePromote = () => {
        setDialogsState({...dialogsState, promote: false})
        promote({
            userId: data.response.communities[0].owner,
            promoteId: selectedUser.id
        })
    }

    const saveName = () => {
        setDialogsState({...dialogsState, rename: false})
        setRename({
            "community": data.response.communities[0].id,
            "name": newname
        })
    }


    return (
        <>
            <Loading
            isLoading={isLoading}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
            >
                {data!=undefined && data.hasOwnProperty('response') &&
                    <>
                        <Grid container spacing={2}>
                            <Grid item sm={4}>
                                <Card>
                                    <Table>
                                        <TableRow>
                                            <TableCell>id</TableCell>
                                            <TableCell>{data.response.communities[0].id}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Country</TableCell>
                                            <TableCell>{data.response.communities[0].country}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>
                                                {data.response.communities[0].name} <Button size={"small"} variant={'outlined'} onClick={
                                                () => {
                                                    setDialogsState({...dialogsState,rename: true})
                                                }
                                                }
                                            >rename</Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Creator</TableCell>
                                            <TableCell>{data.response.communities[0].creator}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Owner</TableCell>
                                            <TableCell>{data.response.communities[0].owner}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Level</TableCell>
                                            <TableCell>{data.response.communities[0].level}</TableCell>
                                        </TableRow>
                                    </Table>
                                </Card>
                            </Grid>

                        </Grid>
                        <Grid container>

                            <Grid item sm={12}>
                                <Card>
                                    <CardContent>

                                        <Typography sx={{fontSize: 16}}>
                                            Members
                                        </Typography>

                                    </CardContent>
                                    <Table>
                                        <TableRow>
                                            <TableCell>id</TableCell>
                                            <TableCell>name</TableCell>
                                            <TableCell>level</TableCell>
                                            <TableCell>Help Score</TableCell>
                                            <TableCell>Last Activity</TableCell>
                                            <TableCell>cheater</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        {data.response.communities[0].members.map(item => (
                                            <TableRow>
                                                <TableCell>{item.id}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.level}</TableCell>
                                                <TableCell>{item.helpScore}</TableCell>
                                                <TableCell>
                                                    {format(item.last_activity, "dd.MM.yyyy HH:mm:ss")}
                                                </TableCell>
                                                <TableCell>{item.cheater==true?<strong>Yes</strong>:'No'}</TableCell>

                                                <TableCell><Button variant={"outlined"} onClick={() => {
                                                    setDialogsState({...dialogsState, cheater: true})
                                                    setSelectedUser(item)
                                                }}>cheater</Button></TableCell>
                                                <TableCell><Button variant={"outlined"}  onClick={() => {
                                                    setDialogsState({...dialogsState, promote: true})
                                                    setSelectedUser(item)
                                                }}>promote</Button></TableCell>
                                                <TableCell><Button variant={"outlined"}  onClick={() => {
                                                    setDialogsState({...dialogsState, kick: true})
                                                    setSelectedUser(item)
                                                }}>kick</Button></TableCell>
                                            </TableRow>
                                        ))
                                        }
                                    </Table>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                }
            </Loading>

            {dialogsState.cheater&&<Dialog open={dialogsState.cheater}>
                <DialogTitle>
                    Cheater {selectedUser.name}
                </DialogTitle>
                <DialogContent>
                    Change cheater status
                </DialogContent>
                <DialogActions>

                    <Button
                        color="secondary"
                        onClick={() => {
                            setDialogsState({...dialogsState, cheater: false})
                        }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        color="primary"
                        loading={setCheaterResponse.isLoading}

                        onClick={() => {
                            saveCheaterStatus(true)
                        }}>
                        Set Cheater
                    </LoadingButton>
                    <LoadingButton
                        onClick={()=>{
                            saveCheaterStatus(false)

                        }}
                        variant="outlined"
                        color="warning"
                        loading={setCheaterResponse.isLoading}
                    >
                        Set NOT Cheater
                    </LoadingButton>

                </DialogActions>
            </Dialog>}

            {dialogsState.kick&&<Dialog open={dialogsState.kick}>
                <DialogTitle>
                    Kick user {selectedUser.name}
                </DialogTitle>
                <DialogContent>
                    You will kick {selectedUser.id}
                </DialogContent>
                <DialogActions>

                    <Button
                        color="secondary"
                        onClick={() => {
                            setDialogsState({...dialogsState, kick: false})
                        }}>
                        Cancel
                    </Button>

                    <LoadingButton
                        onClick={()=>{
                            saveKick()
                        }}
                        variant="outlined"
                        color="warning"
                        loading={kickResponse.isLoading}
                    >
                        Yes, kick
                    </LoadingButton>

                </DialogActions>
            </Dialog>}

            {dialogsState.promote&&<Dialog open={dialogsState.promote}>
                <DialogTitle>
                    Promote user {selectedUser.name}
                </DialogTitle>
                <DialogContent>
                    You will PROMOTE {selectedUser.id}
                </DialogContent>
                <DialogActions>

                    <Button
                        color="secondary"
                        onClick={() => {
                            setDialogsState({...dialogsState, promote: false})
                        }}>
                        Cancel
                    </Button>

                    <LoadingButton
                        onClick={()=>{
                            savePromote()
                        }}
                        variant="outlined"
                        color="warning"
                        loading={promoteResponse.isLoading}
                    >
                        Yes, promote
                    </LoadingButton>

                </DialogActions>
            </Dialog>}
            {dialogsState.rename&&<Dialog open={dialogsState.rename}>
                <DialogTitle>
                    Rename community {data.response.communities[0].id}
                </DialogTitle>
                <DialogContent>
                    <div>old name: <strong>{data.response.communities[0].name}</strong></div>
                    <div>new name: <strong>{newname}</strong></div>
                    <TextField value={newname} onChange={(e)=>{
                        setNewname(e.target.value)
                    }} placeholder={data.response.communities[0].name}></TextField>
                </DialogContent>
                <DialogActions>

                    <Button
                        color="secondary"
                        onClick={() => {
                            setDialogsState({...dialogsState, rename: false})
                        }}>
                        Cancel
                    </Button>

                    <LoadingButton
                        onClick={()=>{
                            saveName()
                        }}
                        variant="outlined"
                        color="warning"
                        loading={promoteResponse.isLoading}
                    >
                        Save
                    </LoadingButton>

                </DialogActions>
            </Dialog>}
        </>
    )
}

export default ClanInfo