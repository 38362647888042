import {
    Button,
    Card,
    CardContent,
    CardHeader, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState, useContext} from "react"

import {Autocomplete, LoadingButton} from "@mui/lab";
import {useParams} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Editor from "@monaco-editor/react";

import {useCreatePrefabV2BAMutation} from "../../../core/api/bermuda-prefab-api";
import {useCreateStateV2BAMutation} from "../../../core/api/bermuda-state-api";

import {useCreatePrefabV2CMMutation} from "../../../core/api/clockmaker-prefab-api";
import {useCreateStateV2CMMutation} from "../../../core/api/cloclmaker-state-api";

import {useCreatePrefabV2RTMutation} from "../../../core/api/rogerthat-prefab-api";
import {useCreateStateV2RTMutation} from "../../../core/api/rogerthat-state-api";
import {useCreatePrefabV2SCMutation} from "../../../core/api/solitaire-prefab-api";
import {useCreateStateV2SCMutation} from "../../../core/api/solitaire-state-api";

import { parse, stringify } from 'lossless-json'
import {SnackBarContext} from "../../../snackBarProvider";
import {useDispatch} from "react-redux";
import {useCreatePrefabV2TOHMutation, useGetPrefabsMetaQuery} from "../../../core/api/toh-prefab-api";
import {AddCircle, ContentCopy} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {alertMsgProcessor} from "../../../core/utils/constants";
import {useCreateStateV2TOHMutation} from "../../../core/api/toh-state-api";


const RawDataEdit = (props) => {
    const {deviceId, action, tab: tabParam, game} = useParams()

    const raw = props.isRaw===true?props.raw:stringify(props.data, null, 2);

    const [rawString, setRawString] = useState()

    useEffect(() => {
        setRawString(raw)
    }, ['raw', raw])

    const [updateState, updateResponse] = useCreateStateV2BAMutation()
    const [updateCMState, updateCMStateResponse] = useCreateStateV2CMMutation();
    const [updateRTState, updateRTStateResponse] = useCreateStateV2RTMutation();
    const [updateSCState, updateSCStateResponse] = useCreateStateV2SCMutation();
    const [updateTOHState, updateTOHStateResponse] = useCreateStateV2TOHMutation();

    const [createPrefabBA, createPrefabBAResponse] = useCreatePrefabV2BAMutation();
    const [createPrefabCM, createPrefabCMResponse] = useCreatePrefabV2CMMutation();
    const [createPrefabRT, createPrefabRTResponse] = useCreatePrefabV2RTMutation();
    const [createPrefabSC, createPrefabSCResponse] = useCreatePrefabV2SCMutation();
    const [createPrefabTOH, createPrefabTOHResponse] = useCreatePrefabV2TOHMutation();

    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const gameAlias = {cm: 'clockmaker'}

    const {
        data: metas,
        isLoading,
        isSuccess,
        isError,
        error,
        isFetching
    } = useGetPrefabsMetaQuery(gameAlias.hasOwnProperty(game)?gameAlias[game]:game);

    const [isConfirmOpen, setIsConfirmOpen] = useState(false)

    const [prefabForm, updatePrefabForm] = useState({
        name: '',
        isOpened: false,
        env: '',
        version: '',
        tags: [],
        environment:''
    })

    const handleChangeRaw = (value, event) => {
        setRawString(value)
    }


    let version = window.localStorage.getItem('isProd') == 'true' ? 'v2' : 'v2';
    if(game != 'cm') version = 'v2'


    async function handleUpdateState(raw) {
//        console.log(stringify(parse(text)))

        let dataString = ''
        try {
            dataString = stringify(parse(raw))
        } catch (e) {
            dataString = raw
        }

        const data = {
            userId: deviceId,
            body: dataString,
            game: game,
            version: version
        }
        let response = false;

        switch (game) {
            case 'ba' :
                response = await updateState(data)
                break;
            case 'cm' :
                response = await updateCMState(data)
                break;
            case 'rt' :
                response = await updateRTState(data)
                break;
            case 'sc' :
                response = await updateSCState(data)
                break;
            case 'toh' :
                response = await updateTOHState(data)
                break;
        }


        alertMsgProcessor(response, addAlert)


        setIsConfirmOpen(false)
    }

    const dispatch = useDispatch();

    async function createPrefabHandler() {

        let dataString = ''
        try {
            dataString = stringify(parse(rawString))
        } catch (e) {
            dataString = rawString
        }

        const request = {
            data: {
                "name": prefabForm.name,
                "data": dataString,
                "tags": prefabForm.tags,
                "version": prefabForm.version,
                "environment": prefabForm.environment
            },
            env: prefabForm.env
        };

        let response = false

        switch (game) {
            case 'ba' :
                response = await createPrefabBA(request)
                break;
            case 'cm' :
                response = await createPrefabCM(request)
                break;
            case 'rt' :
                response = await createPrefabRT(request)
                break;
            case 'sc' :
                response = await createPrefabSC(request)
                break;
            case 'toh' :
                response = await createPrefabTOH(request)
                break;
        }


        updatePrefabForm({
            name: '',
            isOpened: false,
            env: '',
            version: '',
            tags: []
        })


        alertMsgProcessor(response, addAlert)


    }



    const sortVersions = (versions) => {
        let _versions = JSON.parse(JSON.stringify(versions))

        return _versions.sort((a, b) => {
            const aParts = a.split('.').map(Number);
            const bParts = b.split('.').map(Number);

            for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                const aPart = aParts[i] || 0;
                const bPart = bParts[i] || 0;

                if (aPart > bPart) return -1;
                if (aPart < bPart) return 1;
            }

            return 0;
        });
    }

    const sortedVersions = metas!=undefined&&metas.hasOwnProperty('version') ? sortVersions(metas.version) : []


    return <>
        <Card>
            <CardHeader
                title={
                    <Typography variant="h6" component="div" display="inline">
                        Update user state
                    </Typography>
                }
                action={
                    <>

                        <Button variant={"outlined"}
                               color={"success"}
                                startIcon={<CheckCircleIcon/>}

                                onClick={() => {
                                    setIsConfirmOpen(true)
                                }}
                        >Save state</Button>
                        <Button variant={"outlined"}
                                color={"info"}
                                startIcon={<ContentCopy />}

                                onClick={
                                    () => {
                                        navigator.clipboard.writeText(rawString)
                                    }
                                }
                        >Copy to clipboard</Button>
                        <LoadingButton
                            variant={"outlined"}
                            color={"warning"}
                            id="cretePrefabBtn"
                            loading={createPrefabBAResponse.isLoading ||
                                createPrefabCMResponse.isLoading ||
                                createPrefabRTResponse.isLoading ||
                                createPrefabTOHResponse.isLoading ||
                                createPrefabSCResponse.isLoading
                        }
                            startIcon={<AddCircle />}
                            onClick={() => {
                                updatePrefabForm({...prefabForm, isOpened: true})
                            }}>Create prefab</LoadingButton>
                    </>
                }
            />

            <CardContent>


                <div>


                    <Editor height="90vh"
                            defaultLanguage="json"
                            value={rawString}
                            onChange={handleChangeRaw}
                    />
                </div>
            </CardContent>


        </Card>
        <Dialog sx={{ '& .MuiDialog-paper': { width: '400px' } }} open={isConfirmOpen}>
            <DialogContent>
                Save current state for device id <br/>
                <strong>{deviceId}</strong>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={() => {
                        setIsConfirmOpen(false)
                    }}>
                    Cancel
                </Button>
                <LoadingButton variant={"contained"}
                               color={"warning"}
                               onClick={
                                   () => {
                                       handleUpdateState(rawString)
                                   }
                               }
                               loading={updateResponse.isLoading || updateCMStateResponse.isLoading
                                   || updateRTStateResponse.isLoading
                                   || updateSCStateResponse.isLoading
                                   || updateTOHStateResponse.isLoading
                }
                >Save state</LoadingButton>

            </DialogActions>
        </Dialog>

        <Dialog sx={{ '& .MuiDialog-paper': { width: '400px' } }} open={prefabForm.isOpened}>
            <DialogTitle>
                Create prefab

            </DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item >
                        <FormControl sx={{mt:1}} variant="outlined" fullWidth>
                            <InputLabel required={true} id="environment-select-label">Environment (Hermes)</InputLabel>
                            <Select

                                labelId="environment-select-label"
                                onChange={(event) => {
                                    updatePrefabForm({
                                        ...prefabForm,
                                        env: event.target.value
                                    })
                                }}
                                id="prefabFormEnvField"

                                label="Environment"
                            >
                                <MenuItem id="prefabFormEnvField-option-0" value="staging">Staging</MenuItem>
                                <MenuItem id="prefabFormEnvField-option-1" value="prod">Prod</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <TextField
                            label="Name"
                            required={true}
                            id="prefabFormNameField"

                            onChange={(event) => {
                                updatePrefabForm({
                                    ...prefabForm,
                                    name: event.target.value
                                })
                            }}></TextField>
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            options={metas?.environment || []}
                            freeSolo
                            value={prefabForm.environment}
                            onChange={(event, newValue) => {
                                updatePrefabForm({
                                    ...prefabForm,
                                    environment: newValue
                                });
                            }}
                            id="prefabFormEnvtagField"
                            onInputChange={(event, newInputValue) => {
                                updatePrefabForm({
                                    ...prefabForm,
                                    environment: newInputValue || '' // Save input value
                                });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Environment" variant="outlined" fullWidth />
                            )}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            id="prefabFormVersionField"
                            options={sortedVersions || []}
                            freeSolo
                            value={prefabForm.version}
                            onChange={(event, newValue) => {
                                updatePrefabForm({
                                    ...prefabForm,
                                    version: newValue
                                });
                            }}
                            onInputChange={(event, newInputValue) => {
                                updatePrefabForm({
                                    ...prefabForm,
                                    version: newInputValue || '' // Save input value
                                });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Version" variant="outlined" fullWidth />
                            )}
                        />
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            multiple
                            options={metas?.tags || []}
                            freeSolo
                            value={prefabForm.tags}
                            onChange={(event, newValue) => {
                                updatePrefabForm({
                                    ...prefabForm,
                                    tags: newValue || []
                                });
                            }}

                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return (
                                        <Chip variant="outlined" label={option} key={key} {...tagProps} />
                                    );
                                })
                            }
                            renderInput={(params) => (
                                <TextField helperText="Use , (comma) or 'Enter' to split tags" {...params} label="Tags" variant="outlined" fullWidth />
                            )}
                            onInputChange={(event, newInputValue, reason) => {
                                if (newInputValue.endsWith(',')) {
                                    // Split the input based on commas and trim the values
                                    const splitValues = newInputValue
                                        .split(',')
                                        .map(tag => tag.trim())
                                        .filter(tag => tag.length > 0);

                                    // Combine with existing tags, ensuring no duplicates
                                    const updatedTags = [...new Set([...prefabForm.tags, ...splitValues])];

                                    // Update the form state with new tags
                                    updatePrefabForm({
                                        ...prefabForm,
                                        tags: updatedTags
                                    });

                                    // Clear the input field by setting the input value to an empty string
                                    setTimeout(() => {
                                        event.target.value = '';
                                    },10);
                                }
                            }}
                        />
                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions>

                <Button
                    color="secondary"
                    onClick={() => {
                        updatePrefabForm({...prefabForm, isOpened: false})
                    }}>
                    Cancel
                </Button>
                <LoadingButton
                    id="prefabFormSaveBtn"
                    onClick={createPrefabHandler}
                    variant="outlined"
                    color="warning"
                    loading={createPrefabBAResponse.isLoading || createPrefabCMResponse.isLoading}
                    disabled={prefabForm.name=='' || prefabForm.env==''}
                >
                    CREATE
                </LoadingButton>

            </DialogActions>
        </Dialog>
    </>

}
export default RawDataEdit

