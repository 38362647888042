const chargeConstants = {
    CmChargeAdditionalFieldsTypes: [
        'String', 'Boolean', 'Integer'
    ],
    CmChargeTypeOptions: {
        items: "Items",
        actions: "Actions"
    },
    CmChargeConsumableCategoryName: [
        'Main',
        'Trick',
        'Bonus',
        'Unlimited',
        'Mates',
        'UnityMates',
        'Puzzles',
        'Collections',
        'Seasonal Collections',
    ],
    CmChargeActionCategoryName: [
        "Other actions",
        "Special events actions",
    ]
}

export default chargeConstants;