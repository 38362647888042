import api from "./base-api"
import {v4 as uuidv4} from "uuid";

const solitaireAnalyticalDataApiV1 = api.injectEndpoints({
    endpoints: (build) => ({
        getConsumablesV1SC: build.query({
            query: (
                data
            ) => `v1/solitaire/analytical-data/${data.userId}/consumables?limit=${data.limit}&page=0&minusDays=${data.minusDays}`,
            transformResponse: (response) => {
                const rows = response.map((row) => ({id: uuidv4(), ...row}));
                return {rows};
            },
        }),
        getEnvironmentsV1SC: build.query({
            query: (data) => `v1/solitaire/analytical-data/${data.userId}/environments?limit=${data.limit}&page=${data.page}`
        }),
        getUserSessionSC: build.query({
            query: (
                userId = ""
            ) => `v1/solitaire/analytical-data/${userId}/session`
        }),
        getPaymentsV1SC: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/solitaire/analytical-data/${userId}/payments?limit=${limit}&page=${page}`,
            transformResponse: (response) => {
                response.map(obj => obj.usdPrice = obj.usdPrice / 100)
                return response;
            }
        }),
        getUserInfoV1SC: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/solitaire/analytical-data/${userId}/user-info?limit=${limit}&page=${page}`
        }),
    }),
    overrideExisting: false
})

export const {
    useGetConsumablesV1SCQuery,
    useGetEnvironmentsV1SCQuery,
    useGetUserSessionSCQuery,
    useGetPaymentsV1SCQuery,
    useGetUserInfoV1SCQuery
} = solitaireAnalyticalDataApiV1
