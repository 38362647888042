import api from "./base-api"

const solitairePrefabApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getPrefabV2SC: build.query({
            query: (id = "") => `v2/solitaire/prefabs/${id}`,
            providesTags: (result, error, arg) => {
                return result
                    ? [{type: 'PrefabItemSC', id:result.id}, 'PrefabItemSC']
                    : ['PrefabItemSC']
            }
        }),
        getScPrefabsListV2: build.query({
            query: (id = "") => `v2/solitaire/prefabs`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsSC']
        }),
        getPrefabsInfosV2SC: build.query({
            query: (id = "") => `v2/solitaire/prefabs/infos`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsCM']
        }),
        createPrefabV2SC: build.mutation({
            query: (body = {}) => ({
                url: "v2/solitaire/prefabs",
                method: "POST",
                body: body.data,
                env: body.env
            }),
            invalidatesTags: ['PrefabsSC']

        }),
        deleteScPrefabV2: build.mutation({
            query: (id = "") => ({
                url: `v2/solitaire/prefabs/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['PrefabsSC']
        }),
        updateScPrefabV2: build.mutation({
            query: (data = {}) => ({
                url: `v2/solitaire/prefabs/${data.id}`,
                method: "PUT",
                body: data.body
            }),
            invalidatesTags: ['PrefabItemSC']
        })
    }),
    overrideExisting: false
})

export const {
    useGetPrefabV2SCQuery,
    useGetScPrefabsListV2Query,
    useGetPrefabsInfosV2SCQuery,
    useCreatePrefabV2SCMutation,
    useDeleteScPrefabV2Mutation,
    useUpdateScPrefabV2Mutation
} = solitairePrefabApiV2
