import {Card, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {getItemByName} from "../../../core/utils/constants";

export default function Puzzles(props) {
    const puzzlePiece = getItemByName(props.items, "puzzlePiece")
    return (
        <Card>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Active puzzle</TableCell>
                        <TableCell>{props.puzzles.ActivePuzzleId}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Next cycled puzzle</TableCell>
                        <TableCell>puzzle + {props.puzzles.NextCycledPuzzleIndex}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Unused puzzle pieces</TableCell>
                        <TableCell>{props.puzzles._unassembledPiecesCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Active Puzzle Assembled Pieces Count</TableCell>
                        <TableCell>{props.puzzles.ActivePuzzleAssembledPiecesCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Active Puzzle Unassembled Pieces Count</TableCell>
                        <TableCell>{puzzlePiece != undefined && puzzlePiece.Count}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
}