import {Box, Card, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {formatDate, timestampToDate} from "../../../core/utils/constants";

export default function TournamentEvents({eventsData}) {
    // const rows = eventsData ? Object.entries(eventsData).map((([id, event]) => ({
    //     id: id,
    //     eventId: event.Id,
    //     finishReason: event.FinishReason,
    //     finishTime: formatDate(timestampToDate(event.FinishedSec)),
    //     level: event.LevelsComplete ? event.LevelsComplete : "no data"
    // }))) : [];

    let tournametTypes = [
        "Belka.Game.PP.TOGStateData, Assembly-CSharp",
        "Belka.Game.PP.TOWSStateData, Assembly-CSharp",
        "Belka.Game.PP.TOWCStateData, Assembly-CSharp"
    ]

    let rows = eventsData.filter(event => {
        return tournametTypes.includes(event["$type"]);
    });

    return (
        <Box>
            <Card>
                <Table>
                    <TableHead>
                        <TableRow>

                            <TableCell>#</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Place</TableCell>
                            <TableCell>SeenScore</TableCell>
                            <TableCell>SeenPlace</TableCell>
                            <TableCell>Type</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow>
                                <TableCell>{row.Id}</TableCell>
                                <TableCell>{row.Score}</TableCell>
                                <TableCell>{row.Place}</TableCell>
                                <TableCell>{row.SeenScore}</TableCell>
                                <TableCell>{row.SeenPlace}</TableCell>
                                <TableCell>{row['$type']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>

        </Box>
    );
}