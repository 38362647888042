import {Card, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {CMItems, formatDate, getItemByName, timestampToDate} from "../../../core/utils/constants";

export default function Mates({mates}) {
    let counter = 0;
    const rows = mates ? Object.entries(CMItems.UnityMates).map((([id, mate]) => {
        return {
            id: counter++,
            mate: mate,
            count: getItemByName(mates, id).Count,
            expireAfter: formatDate(timestampToDate(getItemByName(mates, id.toString()).InfiniteTimeEnd))
        }
    })) : [];
    console.log(rows)
    return (
        <Card>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Mate</TableCell>
                        <TableCell>Count</TableCell>
                        <TableCell>Expire After</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.mate}</TableCell>
                            <TableCell>{row.count}</TableCell>
                            <TableCell>{row.expireAfter}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}