import api from "./base-api"


const rogerthatChargeApiRT = api.injectEndpoints({
    endpoints: (build) => ({
        getGuidMapRT: build.query({
            query: () => `v2/rogerthat/states/guid-map`
        }),
        getChargesRT: build.query({
            query: (userId = "") => `v2/rogerthat/charges/${userId}`,
            providesTags: ['Charges']
        }),
        createChargeRT: build.mutation({
            query: (body = {}) => ({
                url: `v2/rogerthat/charges`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Charges', 'BaChargerLogsById', 'BaChargerLogs'],
        }),
        createMassChargeRT: build.mutation({
            query: (body = new FormData()) => ({
                url: `v2/rogerthat/charges`,
                method: "POST",
                body: body
            })
        }),
        dischargeRT: build.mutation({
            query: (body = []) => ({
                url: `v2/rogerthat/charges/discharge`,
                method: "PUT",
                body: body
            })
        })
    }),
    overrideExisting: false
})

export const selectGuidMapRT = (state) => api.endpoints.getGuidMapRT.select()(state).data


export const {
    useGetGuidMapRTQuery,
    useGetChargesRTQuery,
    useCreateChargeRTMutation,
    useCreateMassChargeRTQuery
} = rogerthatChargeApiRT
