import Loading from "../../../components/Common/Loading";

import {useGetArchivedStateCMV2Query, useRestoreArchivedStateCMV2Mutation} from "../../../core/api/clockmaker-archived-state-api";
import {useParams} from "react-router-dom";
import Archive from "../../Archive/Archive";

function ArchiveCM(props) {
    const {deviceId, action, tab: tabParam, subtab: subTabParam, game} = useParams()

    const [restoreState, restoreStateResponse] = useRestoreArchivedStateCMV2Mutation();
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
        isFetching
    } = useGetArchivedStateCMV2Query(deviceId);

    return (
        <>
            <h2>Archives for {deviceId}</h2>
            <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}
                isFetching={isFetching}
            >
                <Archive archiveListReady={isSuccess}
                         archiveList={data}
                         restoreState={restoreState}
                         restoreStateResponse={restoreStateResponse}

                         refetch={refetch}
                         game={props.game}
                />
            </Loading>

        </>
    );

}

export default ArchiveCM;
