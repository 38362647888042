import Loading from "../../../components/Common/Loading";

import {
    useDeletePrefabV2BAMutation, useGetPrefabsInfoV2BAQuery,
    useGetPrefabsListV2Query,
    useUpdatePrefabV2BAMutation
} from "../../../core/api/bermuda-prefab-api";
import {useCreateStateV2BAMutation} from "../../../core/api/bermuda-state-api";
import Prefabs from "../Prefabs";

function PrefabsBa(props) {

    const [updateUserState, updateUserStateResponse] = useCreateStateV2BAMutation();
    const [updatePrefab, updatePrefabResponse] = useUpdatePrefabV2BAMutation();
    const [deletePrefab, deletePrefabResponse] = useDeletePrefabV2BAMutation();
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
        isFetching
    } = useGetPrefabsInfoV2BAQuery();

    return (
        <>
            <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}
            >
                <Prefabs prefabsListReady={isSuccess}
                         prefabsList={data}
                         updateUserState={updateUserState}
                         updateUserStateResponse={updateUserStateResponse}
                         updatePrefab={updatePrefab}
                         updatePrefabResponse={updatePrefabResponse}
                         deletePrefab={deletePrefab}
                         deletePrefabResponse={deletePrefabResponse}
                         refetch={refetch}
                         game={props.game}
                />
            </Loading>

        </>
    );

}

export default PrefabsBa;
