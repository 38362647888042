import React, {useEffect, useState} from "react";
import SelectedItemBlock from "./selectedItemBlock";
import Loading from "../../../components/Common/Loading";
import {useGetChargesTypesV2Query} from "../../../core/api/charge-api";
import {useParams} from "react-router-dom";
import {Alert, LoadingButton} from "@mui/lab";
import groupBy from 'lodash/groupBy';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Link,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {useCreateChargeV2CMMutation} from "../../../core/api/clockmaker-charge-api";
import {FULFILLED, UNINITIALIZED} from "../../../core/api/ResponseStatuses";
import {useCmCheckExistMutation} from "../../../core/api/cloclmaker-state-api";
import UsersListDialog from "../../UsersListLinksDialog";

export default function CmChargeBlock(props) {

    const {game, deviceId: deviceIdParam} = useParams()

    const deviceId = deviceIdParam || ''


    let [checkExist, checkExistResponse = {
        existent: [],
        nonExistent: []
    }] = useCmCheckExistMutation();


    const [
        createCharge,
        createChargeResponse
    ] = useCreateChargeV2CMMutation()

    const {
        data: chargeTypes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetChargesTypesV2Query(game)

    const [isMassCharge, setIsMassCharge] = useState(false)

    const [massChargeList, setMassChargeList] = useState("")
    const [formErrors, setFormErrors] = useState({})

    const [validation, setValidation] = useState(false);
    const [validated, setValidated] = useState(false);
    const [existReady, setExistReady] = useState(false);
    const [existedDialogOpen, setExistedDialogOpen] = useState(false);
    const [nonExistentDialogOpen, setNonExistentDialogOpen] = useState(false);
    const [isChargeButtonDisable, setChargeButtonDisable] = useState(true);
    // const [selectedItemList, setSelectedItemList] = useState(props.initialSelectedItems?.length > 0 ?
    //     props.initialSelectedItems.map(item => {
    //         props.initialSelectedItems.push({
    //             id: Math.random(),
    //             type: item.type,
    //             category: item.category,
    //             key: item.id,
    //             amount: item.data,
    //             date: null,
    //             customFields: null
    //         })
    //     }) : [{
    //         id: Math.random(),
    //         type: null,
    //         category: null,
    //         key: null,
    //         amount: null,
    //         date: null,
    //         customFields: null
    //     }]);


    const [childChanged, setIsChildChanged] = useState(false);
    const [someChildIsBlocking, setSomeChildIsBlocking] = useState([])

    useEffect(() => {

        if (checkExistResponse) {
            if (checkExistResponse.status === FULFILLED) {
                checkExistResponse.status = UNINITIALIZED;
                setValidation(false);
                setValidated(false);
                setExistReady(true);
            }

        }
        if (validation && !validated && massChargeList !== "") {
            setValidated(true);
            checkExist({ids: massChargeList.split(/[\r\n]+/)});
        }
        if (!validation && massChargeList === "") {
            checkExistResponse = {};
            setValidated(false);
            setExistReady(false);
        }

        setChargeButtonDisable(checkChargeButtonDisable() || someChildIsBlocking.filter(i => i === true)[0]);

    }, [checkExistResponse, validation, massChargeList, existReady, isMassCharge, childChanged, props.selectedItemList])


    const checkChargeButtonDisable = () => {
        if (isMassCharge) {
            return !existReady
        }
        return false;
    }

    const handleAddItem = () => {
        let id = Math.random();

        let newSelectedItemList = JSON.parse(JSON.stringify(props.selectedItemList))
        newSelectedItemList.push({
            id: id,
            type: null,
            category: null,
            key: null,
            amount: null,
            date: null,
            customFields: null
        })
        props.setSelectedItemList(newSelectedItemList)
    }

    const handleRemoveItem = (index) => {

        let newSomeChildBlocking = [...someChildIsBlocking];
        newSomeChildBlocking.splice(index, 1);
        setSomeChildIsBlocking(newSomeChildBlocking);
        let newSelectedItemList = [...props.selectedItemList];
        newSelectedItemList.splice(index, 1);
        props.setSelectedItemList(newSelectedItemList);
    }

    const handleChange = (event, item, fieldName) => {
        item.amount = null;
        let newSelectedItemList = JSON.parse(JSON.stringify(props.selectedItemList));
        let currentItem = newSelectedItemList.filter(i => i.id === item.id)[0]
        currentItem[fieldName] = event;

        resetItem(currentItem, fieldName);

        props.setSelectedItemList(newSelectedItemList);
    }

    const resetItem = (currentItem, fieldName) => {
        if (fieldName === 'type') {
            currentItem['category'] = null;
            currentItem['key'] = null;
        }
        if (fieldName === 'category') {
            currentItem['key'] = null;
        }
    }

    const handleChangeCustomFields = (propName, event, item, customFieldIndex) => {
        let newSelectedItemList = JSON.parse(JSON.stringify(props.selectedItemList));
        if (!newSelectedItemList.filter(i => i.id === item.id)[0]['customFields']) {
            newSelectedItemList.filter(i => i.id === item.id)[0]['customFields'] = {}
        }

        newSelectedItemList.filter(i => i.id === item.id)[0]['customFields'][propName] = event
        props.setSelectedItemList(newSelectedItemList);

    }

    const handleChangeMass = (event) => {
        setExistReady(false);
        setMassChargeList(event.target.value)
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            // Handle text parsing here
            const fileContents = event.target.result;
            const lines = fileContents.split('\n'); // Example of parsing text from a .txt file
            // const rows = fileContents.split('\n').map(row => row.split(',')); // Example of parsing an array from a .csv file
            setMassChargeList(fileContents);
        };

        reader.readAsText(file);
        setExistReady(false);
        setValidation(true);
    };

    const clearErrors = () => {
        setFormErrors({})
    }
    const addError = (key, value) => {
        setFormErrors({
            ...formErrors,
            [key]: value
        });
    };

    const handleDialogOnClose = () => {
        setExistedDialogOpen(false);
        setNonExistentDialogOpen(false);
    }

    const validateForm = () => {

        let has_errors = false;

        if (isMassCharge && massChargeList.length === 0) {
            addError("guid", "не указан список id")
            has_errors = true

        }


        return !has_errors;
    }

    const createChargeHandler = () => {

        clearErrors()
        if (!validateForm()) return false;

        let userIds;
        if (isMassCharge) {
            userIds = massChargeList.split(/[\r\n]+/)
        } else {
            userIds = [
                deviceId
            ]
        }

        let groupedBy = groupBy(props.selectedItemList, (i) => {
            return i.type;
        })

        let chargeData = {consumables: [], actions: []}
        groupedBy.items && groupedBy.items.forEach(i => {
            chargeData.consumables.push({id: i.key.trim(), value: i.amount ? i.amount : i.date})
        })
        groupedBy.actions && groupedBy.actions.forEach(i => {
            chargeData.actions.push({id: i.key.trim(), value: i.customFields})
        })


        let version = window.localStorage.getItem('isProd') == 'true' ? 'v1' : 'v2';

        createCharge({
            "data": {
                "userIds": userIds,
                "chargeData": chargeData
            },
            "version": 'v1'
        })
    }

    function amountToCharge() {
        let userIds = []
        if (isMassCharge) {
            userIds = massChargeList.split(/[\r\n]+/)
        } else {
            userIds = [
                deviceId
            ]
        }
        userIds = userIds.filter((item) => {
            return item != ''
        })
        return userIds.length

    }

    return (
        <>

                    {props.massCharge &&
                        <FormGroup sx={{pb: 2}}>
                            <FormControlLabel
                                control={
                                    <Switch checked={isMassCharge} onChange={() => setIsMassCharge(!isMassCharge)}
                                            name="jason"/>
                                }
                                label="Mass charge"
                            />
                        </FormGroup>}

                    <FormGroup sx={{pb: 2}}>
                        {
                            isMassCharge && <>
                                <TextField
                                    id="outlined-textarea"
                                    label="List of device ID for mass charge"
                                    placeholder="Через перенос"
                                    multiline
                                    rows={4}
                                    value={massChargeList}
                                    onChange={handleChangeMass}

                                />
                                <Box className={"validation-process"}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                    >
                                        Select file .txt or .csv
                                        <input
                                            accept=".txt,.csv"
                                            id="contained-button-file"
                                            type="file"
                                            onChange={handleFileUpload}
                                            hidden
                                        />
                                    </Button>
                                    {!validation &&
                                        <Button
                                            sx={{ml: 2}}
                                            variant="outlined"
                                            color="warning"
                                            disabled={massChargeList.length === 0}
                                            onClick={() => {
                                                setExistReady(false);
                                                setValidation(true);
                                            }}>
                                            Validate ids
                                        </Button>
                                    }
                                    {existReady === true &&
                                        <Box>
                                            <Link
                                                sx={{ml: 2}}
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    setExistedDialogOpen(true);
                                                }}
                                            >
                                                Existent
                                                ({checkExistResponse.data ? checkExistResponse.data.existent.length : 0})
                                            </Link>
                                            <Link
                                                sx={{ml: 2}}
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    setNonExistentDialogOpen(true);
                                                }}
                                            >
                                                Non existent
                                                ({checkExistResponse.data ? checkExistResponse.data.nonExistent.length : 0})
                                            </Link>
                                            <UsersListDialog
                                                onClose={handleDialogOnClose}
                                                data={checkExistResponse.data ? checkExistResponse.data.existent : []}
                                                open={existedDialogOpen}
                                                name={"Existed device's id"}/>
                                            <UsersListDialog
                                                onClose={handleDialogOnClose}
                                                data={checkExistResponse.data ? checkExistResponse.data.nonExistent : []}
                                                open={nonExistentDialogOpen}
                                                name={"Non existent device's id"}/>
                                        </Box>
                                    }

                                    {validation &&
                                        <Box className={"validation-process"}>
                                            <CircularProgress size={"2em"} sx={{ml: 2, mr: 2}}/>
                                            <Typography sx={{fontSize: 16}}>
                                                Validating ids
                                            </Typography>
                                        </Box>
                                    }
                                </Box>

                            </>
                        }
                    </FormGroup>

                    <Loading
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                        isSuccess={isSuccess}>
                        {props.selectedItemList.map((item, index) => (
                            <>
                                <SelectedItemBlock
                                    isSuccess={isSuccess}
                                    item={item}
                                    index={index}
                                    chargeTypes={chargeTypes}
                                    handleChange={handleChange}
                                    handleChangeCustomFields={handleChangeCustomFields}
                                    handleRemoveItem={handleRemoveItem}
                                    setIsChildChanged={setIsChildChanged}
                                    childChanged={childChanged}
                                    setSomeChildIsBlocking={setSomeChildIsBlocking}
                                    someChildIsBlocking={someChildIsBlocking}
                                    selectedItemList={props.selectedItemList}

                                />
                            </>

                        ))}
                    </Loading>
                    {Object.keys(formErrors).length > 0 &&
                        <Alert severity="error" sx={{mb: 2}}>
                            {Object.values(formErrors).map(error => (
                                <div key={formErrors}>{error}</div>
                            ))}
                        </Alert>
                    }

                    {createChargeResponse.isSuccess &&
                        <Alert severity="info" sx={{mb: 2}}>
                            Charge result done {createChargeResponse.data.userIds.length}:
                            {createChargeResponse.data.userIds.map(data => (
                                <div>{data}</div>
                            ))}
                        </Alert>
                    }

                    <Button
                        variant="contained"
                        color="info"
                        onClick={handleAddItem}
                    >
                        Add
                    </Button>
                    <Box margin={1}>
                        <LoadingButton
                            sx={{mr: 1, ml: -1}}
                            disabled={isChargeButtonDisable}
                            loading={createChargeResponse.isLoading}
                            variant={"contained"}
                            color={"success"}
                            onClick={createChargeHandler}>Charge</LoadingButton>
                    </Box>

                    {amountToCharge()} ids to charge


            {/*{deviceId != '' && <TabContext value={tabIndex}>*/}
            {/*    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">*/}
            {/*        <Tab label="Chargers" value="1"/>*/}
            {/*        <Tab label="Charger Logs" value="2"/>*/}
            {/*    </Tabs>*/}
            {/*    <TabPanel value="1">*/}
            {/*        <Card>*/}
            {/*            <ChargesList*/}
            {/*                deviceId={deviceId}*/}
            {/*                chargesData={chargesData}*/}
            {/*                chargesLoading={chargesLoading}*/}
            {/*                chargesSuccess={chargesSuccess}*/}
            {/*                chargesIsError={chargesIsError}*/}
            {/*                chargesError={chargesError}*/}
            {/*            />*/}
            {/*        </Card>*/}
            {/*    </TabPanel>*/}
            {/*    <TabPanel value="2">*/}
            {/*        <DeviceCmChargerLogs deviceId={deviceId}/>*/}
            {/*    </TabPanel>*/}
            {/*</TabContext>}*/}

        </>
    );
}