import {Card, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {HOUSES} from "../../../core/utils/constants";

export default function Houses({housesData}) {
    const rows = housesData ? Object.entries(housesData).map((([id, house]) => ({
        house: parseInt(house.Id) ? HOUSES[parseInt(house.Id) - 1] : house.Id,
        status: house.State,
        level: house.LevelNumber
    }))) : [];
    return (
        <Card>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>House</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Level</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow>
                            <TableCell>{row.house}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.level}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}