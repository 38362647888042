export const types = {
    GUID_MAP_LOADED: 'GUID_MAP_LOADED',
    SET_DEVICE_ID: 'SET_DEVICE_ID',
    SET_STATE_ID: 'SET_STATE_ID',
    SET_SYNCHRO_ID: 'SET_SYNCHRO_ID',
    SET_MASS_CHARGE: 'SET_MASS_CHARGE',
};

const initialState = {
    guidMap: {},
    deviceId: '',
    massCharge: false
};


export default (state = initialState, action) => {
    switch (action.type) {

        case types.GUID_MAP_LOADED:
            return {
                ...state,
                guidMap: {
                    ...action.payload.data,
                }
            }

        case types.SET_DEVICE_ID:
            return {
                ...state,
                deviceId: action.payload.deviceId ? action.payload.deviceId : '',
            }
        case types.SET_STATE_ID:
            return {
                ...state,
                stateId: action.payload.stateId ? action.payload.stateId : '',
            }
        case types.SET_MASS_CHARGE:
            return {
                ...state,
                isMassCharge: action.payload.isMassCharge
            }
        case types.SET_SYNCHRO_ID:
            return {
                ...state,
                synchroId: action.payload.synchroId ? action.payload.synchroId : ''
            }

        default:
            return state;
    }
};
