import api from "./base-api"

const bermudaPrefabApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getPrefabV2: build.query({
            query: (id = "") => `v2/bermuda/prefabs/${id}`,
            providesTags: (result, error, arg) => {
                return result
                    ? [{type: 'PrefabBAItem', id:result.id}, 'PrefabBAItem']
                    : ['PrefabBAItem']
            }
        }),
        getPrefabsListV2: build.query({
            query: (id = "") => `v2/bermuda/prefabs`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsBA']
        }),
        getPrefabsInfoV2BA: build.query({
            query: (id = "") => `v2/bermuda/prefabs/info`,
            transformResponse(data) {
                return data.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            },
            providesTags: ['PrefabsBA']
        }),
        createPrefabV2BA: build.mutation({
            query: (body = {}) => ({
                url: "v2/bermuda/prefabs",
                method: "POST",
                body: body.data,
                env: body.env
            }),
            invalidatesTags: ['PrefabsBA']

        }),
        deletePrefabV2BA: build.mutation({
            query: (id = "") => ({
                url: `v2/bermuda/prefabs/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['PrefabsBA']

        }),
        updatePrefabV2BA: build.mutation({
            query: (data = {}) => ({
                url: `v2/bermuda/prefabs/${data.id}`,
                method: "PUT",
                body: data.body
            }),
            invalidatesTags: ['PrefabBAItem']

        })
    }),
    overrideExisting: false
})

export const {
    useGetPrefabV2Query,
    useGetPrefabsListV2Query,
    useGetPrefabsInfoV2BAQuery,
    useCreatePrefabV2BAMutation,
    useDeletePrefabV2BAMutation,
    useUpdatePrefabV2BAMutation
} = bermudaPrefabApiV2
