import React, {useState} from 'react'
import {useGetAllScLogsByDeviceIdQuery} from "../../../core/api/charge-log-api";
import Loading from "../../../components/Common/Loading";
import ChargerLogs from "../index";
import columns from "../chargerLogColumns";

export default function DeviceScChargerLogs(props) {
    const [state, setState] = useState({
        page: 0,
        pageSize: 20,
    });

    const {
        data: response = {
            chargeLogs: [],
            totalPages: 1
        },
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAllScLogsByDeviceIdQuery({
        deviceId: props.deviceId,
        page: state.page,
        pageSize: state.pageSize
    })

    return (
        <>
            <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}
            >
                <ChargerLogs columns={columns.SC} response={response} state={state} setState={setState}/>
            </Loading>
        </>
    )
}