import { useGetPrefabV2RTQuery } from "../../../core/api/rogerthat-prefab-api";
import PrefabsItem from '../PrefabsItem'; // Import the generic component

function PrefabsRTItem({ selectedItem, setSelectedItem }) {
    return (
        <PrefabsItem
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            useQueryHook={useGetPrefabV2RTQuery} // Pass the specific query hook
        />
    );
}

export default PrefabsRTItem;