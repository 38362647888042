import {useGetRtConsumablesV1Query} from "../../core/api/roger-that-analytical-data-api";
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Loading from "../../components/Common/Loading";
import {DataGrid} from "@mui/x-data-grid";
import {useState} from "react";
import {format} from "date-fns";

const columns = [
    {
        headerName: "Location",
        field: "locationId",
        width: 200
    },
    {
        headerName: "Action",
        field: "action",
        width: 200
    },
    {
        headerName: "Reason",
        field: "reason",
        width: 250
    },
    {
        headerName: "Consumable",
        field: "consumable",
        width: 300
    },
    {
        headerName: "Amount",
        field: "consumableAmount",
        width: 200
    },
    {
        headerName: "Date/Time",
        field: "dateTime",
        width: 300,
        renderCell: (params) =>
            <div>{format(Date.parse(params.row.dateTime), "dd.MM.yyyy HH:mm:ss")}</div>
    }
]

const RtConsumables = (props) => {

    const [currentAmount, setCurrentAmount] = useState(200);
    const [filterText, setFilterText] = useState("");
    const [minusDays, setMinusDays] = useState('');
    const [depth, setDepth] = useState({
        value: 1, displayName: 'For one day', type: "time"
    });

    const depthVariants = [
        {value: 50, displayName: "50 latest itms", type: "amount"},
        {value: 100, displayName: "100 latest items", type: "amount"},
        {value: 200, displayName: "200 latest items", type: "amount"},
        {value: 1, displayName: 'All for one day', type: "time"},
        {value: 7, displayName: 'All for one week', type: "time"},
        {value: 14, displayName: 'All for two weeks', type: "time"},
        {value: 21, displayName: 'All for three weeks', type: "time"},
        {value: 28, displayName: 'All for four weeks', type: "time"},
    ]

    const {
        data,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetRtConsumablesV1Query({"userId": props.deviceId, "limit": currentAmount, "minusDays": minusDays})

    function filterData() {
        let newData = [...data.rows]
        return newData.filter(item => item.consumable.toLowerCase().includes(filterText.toLowerCase())
            || item.action.toLowerCase().includes(filterText.toLowerCase())
            || item.reason.toLowerCase().includes(filterText.toLowerCase())
            || item.locationId.toLowerCase().includes(filterText.toLowerCase())
            || (item.consumable && item.consumable.toLowerCase().includes(filterText.toLowerCase())) || (item.consumableAmount != null && item.consumableAmount.toString().includes(filterText.toLowerCase())));
    }

    let consumables = ""

    if (typeof data === "object") {
        consumables = <DataGrid
            getRowId={(row) => row.id}
            rows={filterData()}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        consumables = "no data"
    }


    const handleChange = (event) => {
        const selectedVariant = depthVariants.find(
            (variant) => variant.value === event.target.value
        );
        if (selectedVariant.type === 'amount') {
            setMinusDays("");
            setCurrentAmount(selectedVariant.value);
        } else {
            setCurrentAmount("");
            setMinusDays(selectedVariant.value);
        }
        setDepth(selectedVariant);
    };

    return (
        <Loading
            isLoading={isFetching}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            <Grid container spacing={2}>
                <Grid item>
                    <Box display="flex" justifyContent="flex-end">
                        <TextField
                            label="Filter"
                            onChange={(e) => setFilterText(e.target.value)}>
                        </TextField>
                    </Box>
                </Grid>

                <Grid item>
                    <FormControl sx={{minWidth: '200px'}}>
                        <InputLabel id="demo-simple-select-label">Choose depth</InputLabel>
                        <Select value={depth.value} onChange={handleChange} label="Choose depth">
                            {depthVariants.map((variant) => (
                                <MenuItem key={variant.value} value={variant.value}>
                                    {variant.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {consumables}

        </Loading>
    )
}

export default RtConsumables