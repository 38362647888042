import React, {useState} from 'react';
import ChargerLogs from "../index";
import {useGetAllCmLogsQuery} from "../../../core/api/charge-log-api";
import Loading from "../../../components/Common/Loading";
import columns from "../chargerLogColumns";

export default function AllCmChargerLogs() {
    const [state, setState] = useState({
        page: 0,
        pageSize: 20,
    });

    const {
        data: response = {
            chargeLogs: [],
            totalPages: 1
        },
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAllCmLogsQuery({
        page: state.page,
        pageSize: state.pageSize
    })

    return (
        <>
            <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}
            >
                <ChargerLogs
                    columns={columns.CM}
                    response={response}
                    state={state}
                    setState={setState}
                />
            </Loading>
        </>
    )
}