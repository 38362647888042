import React from "react"

import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {format} from "date-fns";

const OffersListRT = ({items}) => {


    return (
        <Table>
            <TableHead>
                <TableRow>

                    <TableCell>Reference</TableCell>
                    <TableCell>Number</TableCell>
                    <TableCell>SegmentGUID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>ProduceTimestamp</TableCell>
                    <TableCell>DisplayTimestamp</TableCell>
                    <TableCell>FulfillTimestamp</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map(item => (
                    <TableRow
                    >
                        <TableCell>{item.Reference}</TableCell>
                        <TableCell>{item.Number}</TableCell>
                        <TableCell>{item.SegmentGUID}</TableCell>
                        <TableCell>{item.Status}</TableCell>
                        <TableCell>
                            {format(new Date(item.ProduceTimestamp * 1000), "dd.MM.yyyy HH:mm:ss")}
                        </TableCell>
                        <TableCell>
                            {format(new Date(item.DisplayTimestamp * 1000), "dd.MM.yyyy HH:mm:ss")}
                        </TableCell>
                        <TableCell>
                            {format(new Date(item.FulfillTimestamp * 1000), "dd.MM.yyyy HH:mm:ss")}
                        </TableCell>

                    </TableRow>
                ))}
            </TableBody>
        </Table>

    )
}

export default OffersListRT
