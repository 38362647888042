import Loading from "../../../components/Common/Loading";

import {useGetArchivedStateRTV2Query, useRestoreArchivedStateRTV2Mutation} from "../../../core/api/regerthat-archive-api";
import {useParams} from "react-router-dom";
import Archive from "../../Archive/Archive";
import {useSelector} from "react-redux";
import {Alert} from "@mui/lab";

function ArchiveRT(props) {
    const {deviceId, action, tab: tabParam, subtab: subTabParam, game} = useParams()

    const CommonData = useSelector((state) => state.data.CommonData)
    console.log("CommonData")
    console.log(CommonData)
    const stateId = CommonData.stateId;

    const [restoreState, restoreStateResponse] = useRestoreArchivedStateRTV2Mutation();
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
        isFetching
    } = useGetArchivedStateRTV2Query(stateId);

    return (
        <>
            <h2>Archives for {stateId}</h2>
            {stateId=='' && <Alert severity={"info"}>Input vendor id</Alert> }
            {stateId!='' && <Loading
                isLoading={isLoading}
                isError={isError}
                error={error}
                isSuccess={isSuccess}
                isFetching={isFetching}
            >
                <Archive archiveListReady={isSuccess}
                         archiveList={data}
                         restoreState={restoreState}
                         restoreStateResponse={restoreStateResponse}
                         stateId={stateId}
                         refetch={refetch}
                         game={props.game}
                />
            </Loading>}

        </>
    );

}

export default ArchiveRT;
