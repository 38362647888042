import api from "./base-api"
import {v4 as uuidv4} from 'uuid';

const rogerThatAnalyticalDataApiV1 = api.injectEndpoints({
    endpoints: (build) => ({
        getRtConsumablesV1: build.query({
            query: (
                data
            ) => {
                return `v1/rogerthat/analytical-data/${data.userId}/consumables?limit=${data.limit}&page=0&minusDays=${data.minusDays}`
            },
            transformResponse: (response) => {
                const rows = response.map((row) => ({id: uuidv4(), ...row}));
                return {rows};
            },
        }),
        getRtEnvironmentsV1: build.query({
            query: (
                userId = "",
                limit = 1,
                page = 0
            ) => `v1/rogerthat/analytical-data/${userId}/environments?limit=${limit}&page=${page}`
        }),
        getRtPaymentsV1: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/rogerthat/analytical-data/${userId}/payments?limit=${limit}&page=${page}`
        }),
        getUserInfoV1RT: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/rogerthat/analytical-data/${userId}/user-info?limit=${limit}&page=${page}`
        }),
    }),
    overrideExisting: false
})

export const {
    useGetRtConsumablesV1Query,
    useGetRtEnvironmentsV1Query,
    useGetRtPaymentsV1Query,
    useGetUserInfoV1RTQuery
} = rogerThatAnalyticalDataApiV1
