import api from "./base-api"

const bermudaChargeApiV1BA = api.injectEndpoints({
    endpoints: (build) => ({
        getChargesV1BA: build.query({
            query: (userId = "") => `v1/bermuda/charges/${userId}`
        }),
        createChargeV1BA: build.mutation({
            query: (userId = "", body = {}) => ({
                url: `v1/bermuda/charges/${userId}`,
                method: "POST",
                body: body
            })
        })
    }),
    overrideExisting: false
})

export const {
    useGetChargesV1BAQuery,
    useCreateChargeV1BAQuery
} = bermudaChargeApiV1BA

const bermudaChargeApiV2BA = api.injectEndpoints({
    endpoints: (build) => ({
        getGuidMap: build.query({
            query: () => `v1/bermuda/states/guid-map`
        }),
        getChargesV2BA: build.query({
            query: (userId = "") => `v2/bermuda/charges/${userId}`,
            providesTags: ['Charges']
        }),
        createChargeV2BA: build.mutation({
            query: (body = {}) => ({
                url: `v2/bermuda/charges`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Charges', 'BaChargerLogsById', 'BaChargerLogs'],
        }),
        createMassChargeV2BA: build.mutation({
            query: (body = new FormData()) => ({
                url: `v2/bermuda/charges`,
                method: "POST",
                body: body
            })
        }),
        dischargeV2BA: build.mutation({
            query: (body = []) => ({
                url: `v2/bermuda/charges/discharge`,
                method: "PUT",
                body: body
            })
        })
    }),
    overrideExisting: false
})

export const selectGuidMap = (state) => api.endpoints.getGuidMap.select()(state).data

export const {
    useGetGuidMapQuery,
    useGetChargesV2BAQuery,
    useCreateChargeV2BAMutation,
    useCreateMassChargeV2BAQuery,
    useDischargeV1BAQuery
} = bermudaChargeApiV2BA
