import {Card, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {CMItems} from "../../../core/utils/constants";

export default function Collections({collections}) {
    const rows = collections ? Object.entries(collections).map((([id, collection]) => ({
        id: id,
        reward: CMItems.Collections[collection.Id] !== undefined ? CMItems.Collections[collection.Id] : collection.Id,
        unlocked: collection.Unlocked.toString(),
        collectedTime: collection.CollectedTimes
    }))) : [];
    return (
        <Card>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Reward</TableCell>
                        <TableCell>Unlocked</TableCell>
                        <TableCell>CollectedTimes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.reward}</TableCell>
                            <TableCell>{row.unlocked}</TableCell>
                            <TableCell>{row.collectedTime}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}