import api from "./base-api"

const solitaireArchivedStateApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getArchivedStateSCV2: build.query({
            query: (userId = "") => `v2/solitaire/states/archive/${userId}`
        }),
        archiveStateSCV2: build.mutation({
            query: (userId = "") => ({
                url: `v2/solitaire/states/archive/${userId}`,
                method: "POST"
            })
        }),
        restoreArchivedStateSCV2: build.mutation({
            query: (data = "") => ({
                url: `v2/solitaire/states/archive/${data.userId}/restore`,
                method: "POST"
            })
        }),
        deleteArchivedStateSCV2: build.mutation({
            query: (userId = "") => ({
                url: `v2/solitaire/states/archive/${userId}`,
                method: "DELETE"
            })
        })
    }),
    overrideExisting: false
})

export const {
    useGetArchivedStateSCV2Query,
    useArchiveStateSCV2Query,
    useRestoreArchivedStateSCV2Mutation,
    useDeleteArchivedStateSCV2Query
} = solitaireArchivedStateApiV2
