const types = {
    MODE_PROD: 'MODE_PROD',
    MODE_STAGING: 'MODE_STAGING'
};

const initialState = {
    isProd: window.localStorage.getItem('isProd') ?? false,
    isStaging: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case types.MODE_PROD:
            return {
                ...state,
                isProd: true,
                isStaging: false,
            };

        case types.MODE_STAGING:
            return {
                ...state,
                isProd: false,
                isStaging: true,
            };


        default:
            return state;
    }
};
