import {useGetPrefabV2CMQuery} from "../../../core/api/clockmaker-prefab-api";
import PrefabsItem from "../PrefabsItem";

function PrefabsRTItem({selectedItem,setSelectedItem}) {

    return (
        <PrefabsItem
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            useQueryHook={useGetPrefabV2CMQuery} // Pass the specific query hook
        />
    );

}

export default PrefabsRTItem;
