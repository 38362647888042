import {Box, Button, Card, Dialog} from "@mui/material";
import {getItemByName} from "../../../core/utils/constants";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import CmChargeBlock from "../../Charger/cm/cmChargeBlock";
import React, {useState} from "react";

export default function Bonuses({bonuses}) {

    const [selectedRows, setSelectedRows] = useState([]);
    const disable = selectedRows.length === 0

    const [chargeItemsDialog, setChargeItemsDialog] = useState(false);
    const [selectedItemList, setSelectedItemList] = useState([{
        id: Math.random(),
        type: null,
        category: null,
        key: null,
        amount: null,
        date: null,
        customFields: null
    }]);

    const columns: GridColumns = [
        {field: 'name', headerName: 'Name', hide: false, width: 700},
        {field: 'data', headerName: 'Data', hide: false, width: 700},
    ]

    let rows = [
        {
            id: "lightning",
            type: "items",
            category: "Bonus",
            name: "Lightning",
            data: getItemByName(bonuses, "lightning").Count
        },
        {id: "blast", type: "items", category: "Bonus", name: "Blast", data: getItemByName(bonuses, "blast").Count},
        {id: "star", type: "items", category: "Bonus", name: "Star", data: getItemByName(bonuses, "star").Count},
    ]

    const handleSelectRows = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = rows.filter((row) =>
            selectedIDs.has(row.id.toString()));
        setSelectedRows(selectedRowData);
        setSelectedItemList(selectedRowData.map(item =>
            ({
                id: Math.random(),
                type: item.type,
                category: item.category,
                key: item.id,
                amount: item.data,
                date: null,
                customFields: null
            })
        ));
    }

    return (
        <Card>
            <Button
                disabled={disable}
                variant={'contained'}
                color={"success"}
                onClick={() => setChargeItemsDialog(!chargeItemsDialog)}>
                Charge items
            </Button>
            <Box sx={{width: '100%', height: 600}}>
                <DataGrid
                    checkboxSelection={true}
                    onSelectionModelChange={(ids) => handleSelectRows(ids)}
                    rows={rows}
                    columns={columns}>
                </DataGrid>

            </Box>
            <Dialog open={chargeItemsDialog} fullWidth={true}>
                <CmChargeBlock
                    massCharge={false}
                    selectedItemList={selectedItemList}
                    setSelectedItemList={setSelectedItemList}
                />

                <Button
                    disabled={disable}
                    variant={'contained'}
                    color={"success"}
                    onClick={() => setChargeItemsDialog(!chargeItemsDialog)}>
                    Cancel
                </Button>

            </Dialog>
        </Card>
    );
}