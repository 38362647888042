import api from "./base-api"

const solitaireChargeApiV1 = api.injectEndpoints({
    endpoints: (build) => ({
        getChargesV1: build.query({
            query: (userId = "") => `v1/solitaire/charges/${userId}`
        }),
        createChargeV1: build.mutation({
            query: (userId = "", body = {}) => ({
                url: `v1/solitaire/charges/${userId}`,
                method: "POST",
                body: body
            })
        })
    }),
    overrideExisting: false
})

export const {
    useGetChargesV1Query,
    useCreateChargeV1Query
} = solitaireChargeApiV1

const solitaireChargeApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getChargesScV2: build.query({
            query: (userId = "") => `v2/solitaire/charges/${userId}`,
            providesTags: ['ScCharges']
        }),
        createChargeScV2: build.mutation({
            query: (data = {}) => {
                return ({
                    url: `v2/solitaire/charges/${data.userId}`,
                    method: "POST",
                    body: data.chargeData
                })
            },
            invalidatesTags: ['ScCharges']
        }),
        createMassChargeV1: build.mutation({
            query: (body = new FormData()) => {
                return ({
                    url: `v1/solitaire/charges`,
                    method: "POST",
                    body: body
                })
            }
        }),
        dischargeV1: build.mutation({
            query: (body = []) => ({
                url: `v1/solitaire/charges/discharge`,
                method: "PUT",
                body: body
            })
        })
    }),
    overrideExisting: false
})

export const {
    useGetChargesScV2Query,
    useCreateChargeScV2Mutation,
    useCreateMassChargeV2Query,
    useDischargeV1Query
} = solitaireChargeApiV2
