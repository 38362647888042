import React, {useEffect, useState} from 'react';
import {createSearchParams, Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import {Box, Card, Grid, Tab, Tabs} from "@mui/material";

import {TabContext, TabPanel} from "@mui/lab";

import {useGetStateV2BAQuery} from "../../core/api/bermuda-state-api";
import PfLocationsTable from "./ba/PfLocationsTable";
import RawDataEdit from "./ba/RawDataEdit";
import ChargesList from "../Charger/chargesList";
import TechInfo from "../TechInfo/techInfo";
import PaymentsBA from "../Payments/ba";
import InGameList from "../InGame/InGameList";
import Loading from "../../components/Common/Loading";
import BaConsumables from "../Consumables/ba";
import {stringify, parse} from "lossless-json";
import {clearIsMassCharge} from "../../core/utils/configureStore";
import {getDeviceIdAbsentWarning} from "../../core/utils/constants";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';



function UserStatesBA(props) {
    const {action, tab: tabParam, game} = useParams()
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId
    const link_prefix = '/' + game + '/' + action + '/' + deviceId + '/'
    const tab = tabParam || 'update'
    const stateId = CommonData.stateId;

    //const state = useState()
    const dispatch = useDispatch();

    const deviceIdIsEmpty = deviceId == null || deviceId == '' || typeof deviceId == 'object';

    const {
        data: obj,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetStateV2BAQuery({deviceId, stateId}, {skip: deviceIdIsEmpty})


    const [tabIndex, setTabIndex] = useState(tab);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    useEffect(() => {
        clearIsMassCharge(dispatch)
    }, [deviceId, dispatch]);

    let status = ""

    let content = ""
    let panel1 = ""
    let panel2 = ""

    let charges = ""

    if (deviceIdIsEmpty) {
        return (
            <>
                    <div>{getDeviceIdAbsentWarning()}</div>
            </>
        );
    }

    if (obj && isSuccess && obj.hasOwnProperty('value')) {

        const data = parse(atob(obj.value))
        const raw = JSON.parse(atob(obj.value))

        if (data.hasOwnProperty('progress'))
            panel2 = <PfLocationsTable locations={data.progress.locations}/>

        content = <TabContext value={tabIndex}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Update" value="update" component={Link} to={link_prefix + "update" +`?${createSearchParams({'stateId': stateId})}`}/>
                    <Tab label="Locations" value="locations" component={Link} to={link_prefix + "locations" +`?${createSearchParams({'stateId': stateId})}`}/>
                    <Tab label="Charges" value="charges" component={Link} to={link_prefix + "charges" +`?${createSearchParams({'stateId': stateId})}`}/>
                    <Tab label="TechInfo" value="techinfo" component={Link} to={link_prefix + "techinfo" +`?${createSearchParams({'stateId': stateId})}`}/>
                    <Tab label="Payments" value="payments" component={Link} to={link_prefix + "payments" +`?${createSearchParams({'stateId': stateId})}`}/>
                    <Tab label="Balance items" value="balanceitems" component={Link} to={link_prefix + "balanceitems" +`?${createSearchParams({'stateId': stateId})}`}/>
                    <Tab label="In game" value="ingame" component={Link} to={link_prefix + "ingame" +`?${createSearchParams({'stateId': stateId})}`}/>
                </Tabs>
            </Box>
            <TabPanel value="balanceitems">
                <BaConsumables deviceId={deviceId}/>
            </TabPanel>
            <TabPanel value="payments">
                <PaymentsBA deviceId={deviceId}/>
            </TabPanel>
            <TabPanel value="techinfo">
                <TechInfo deviceId={deviceId}/>
            </TabPanel>
            <TabPanel value="charges">
                <Card>
                    <ChargesList deviceId={deviceId}/>
                </Card>
            </TabPanel>

            <TabPanel value="locations">
                <Card>
                    {panel2}
                </Card>
            </TabPanel>

            <TabPanel value="update">

                <RawDataEdit data={data}></RawDataEdit>
            </TabPanel>

            <TabPanel value="ingame">
                <Grid item sm={8}>
                    <Card>
                        {raw.hasOwnProperty("progress") &&
                            <InGameList items={raw.progress.consumables.items}/>
                        }
                    </Card>
                </Grid>
            </TabPanel>
        </TabContext>

    } else {
        content = obj?.error
    }

    return (
        <>
            <Loading isError={isError} isLoading={isLoading} error={error}>
                <div>{status}</div>
                <div>{content}</div>
            </Loading>


        </>
    );

}

export default UserStatesBA

//
// const mapStateToProps = (state) => ({
//     items: state.items
// })
// const mapDispatchToProps = dispatch => ({ ...bindActionCreators(dispatch) });
//
//
// export default connect(mapStateToProps, mapDispatchToProps)(UserStates);
