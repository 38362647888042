import React, { useEffect, useState } from 'react';
import { stringify, parse } from "lossless-json";
import Editor from "@monaco-editor/react";
import Loading from "../../components/Common/Loading";

function PrefabsEditor({ handleChange, data }) {


    const raw = stringify(data, null, 2);

    const [rawString, setRawString] = useState()

    useEffect(() => {
        setRawString(raw)
    }, ['raw', raw])

    const handleChangeRaw = (value, event) => {
        setRawString(value)
        handleChange(value)
    }

    return (
        <Editor height="90vh"
                defaultLanguage="json"
                value={rawString}
                onChange={handleChangeRaw}
        />
    );
}

export default PrefabsEditor;