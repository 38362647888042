import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Box, Tab, Tabs,} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import Loading from "../../components/Common/Loading";
import PaymentsCM from "../Payments/cm";
import ConsumablesCM from "../Consumables/cm";
import {stringify, parse} from "lossless-json";
import {getDeviceIdAbsentWarning} from "../../core/utils/constants";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import InAppCM from "../Payments/InAppCM";
import TechInfoCM from "../TechInfo/techInfoCM";
import {useGetChargesScV2Query} from "../../core/api/solitaire-charge-api";
import ClanInfo from "./clanInfo";
import ClanChat from "./clanChat";
import ClanHistory from "./clanHistory";
import ClanJoin from "./clanJoin";
import ClanStatus from "./clanStatus";


function ClansCM(props) {
    const {action, tab: tabParam, subtab: subTabParam, game} = useParams()
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId
    const stateId = CommonData.stateId

    const link_prefix = '/' + game + '/' + action + '/' + deviceId + '/'
    const link_postfix = '?vendorId='+stateId

    const tab = tabParam || 'membership'
    const dispatch = useDispatch();
    const deviceIdIsEmpty = deviceId == null || deviceId == '' || typeof deviceId == 'object';


    const [tabIndex, setTabIndex] = useState(tab);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    let content = ""

    if (deviceIdIsEmpty) {
        return (
            <>
                <div>{getDeviceIdAbsentWarning()}</div>
            </>
        );
    }


    if(!deviceIdIsEmpty) {
        content = <TabContext value={tabIndex}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Membership" value="membership" component={Link} to={link_prefix + "membership" + link_postfix}/>
                    <Tab label="Chat" value="chat" component={Link} to={link_prefix + "chat" + link_postfix}/>
                    <Tab label="Member Status" value="status" component={Link} to={link_prefix + "status" + link_postfix}/>
                    <Tab label="Full history" value="history" component={Link} to={link_prefix + "history" + link_postfix}/>
                    <Tab label="Join" value="join" component={Link} to={link_prefix + "join" + link_postfix}/>

                </Tabs>
            </Box>

            <TabPanel value="membership">
                <ClanInfo userId={deviceId} />
            </TabPanel>

            <TabPanel value="chat">
                <ClanChat userId={deviceId} />
            </TabPanel>

            <TabPanel value="status">
                <ClanStatus userId={deviceId} />
            </TabPanel>

            <TabPanel value="history">
                <ClanHistory userId={deviceId} />
            </TabPanel>

            <TabPanel value="join">
                <ClanJoin userId={deviceId} />
            </TabPanel>

        </TabContext>
    }


    return (
        <>
            <div>{content}</div>
        </>
    );

}

export default ClansCM
