import api from "./base-api"

const rogerthatArchivedStateApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getArchivedStateRTV2: build.query({
            query: (userId = "") => `v2/rogerthat/archive/${userId}`
        }),
        archiveStateRTV2: build.mutation({
            query: (userId = "") => ({
                url: `v2/rogerthat/archive/${userId}`,
                method: "POST"
            })
        }),
        restoreArchivedStateRTV2: build.mutation({
            query: (data) => ({
                url: `v2/rogerthat/archive/${data.userId}/restore`,
                method: "POST"
            })
        }),
        deleteArchivedStateRTV2: build.mutation({
            query: (userId = "") => ({
                url: `v2/rogerthat/archive/${userId}`,
                method: "DELETE"
            })
        })
    }),
    overrideExisting: false
})

export const {
    useGetArchivedStateRTV2Query,
    useArchiveStateRTV2Query,
    useRestoreArchivedStateRTV2Mutation,
    useDeleteArchivedStateRTV2Query
} = rogerthatArchivedStateApiV2
