import React from 'react';

import {NavLink, Route, Routes, useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import UserStates from "../UserStates";
import Charger from "../Charger/sc";

import WhiteListManagement from "../WhiteListManagement";
import {Box, Drawer, MenuItem, MenuList, Toolbar} from "@mui/material";
import SearchBar from "../Search";

import DashboardSC from "../Dashboard/sc";
import UserStatesSC from "../UserStates/sc";
import PrefabsSc from "../Prefabs/sc/PrefabsSc";
import ChargeManagement from "../ChargeManagement/sc";
import ArchiveRT from "../Archive/rt/ArchiveRT";
import ArchiveSC from "../Archive/sc/ArchiveSC";
import SearchBarSC from "../Search/SearchSC";
import AdvancedSearchRT from "../AdvancedSearch/AdvancedSearchRT";
import AdvancedSearchSC from "../AdvancedSearch/AdvancedSearchSC";

export default function MainSC(props) {
    const {game, action} = useParams();

    const CommonData = useSelector((state) => state.data.CommonData)
    let deviceId = CommonData.deviceId ? CommonData.deviceId : '';
    let vendorId = CommonData.stateId ? CommonData.stateId : '';

    const dispatch = useDispatch();

    if (deviceId !== '' && deviceId !== CommonData.deviceId) {
        dispatch({
            type: "SET_DEVICE_ID",
            payload: {deviceId},
        });
    } else {
        if (typeof CommonData.deviceId == 'string') {
            deviceId = CommonData.deviceId
        }
    }


    const main_menu = [
        {
            to: '/' + game + '/dashboard/' + deviceId + '?vendorId=' +vendorId,
            path: 'dashboard/:deviceId?',
            title: 'Dashboard',
            element: <DashboardSC/>
        },
    ];
    const state_menu = [
        {
            to: '/' + game + '/state/' + deviceId + '?vendorId=' +vendorId,
            path: 'state/:deviceId?/:tab?/:subtab?',
            title: 'UserStates',
            element: <UserStatesSC/>,
        },
        {to: '/' + game + '/charger/' + deviceId + '?vendorId=' +vendorId, path: 'charger/:deviceId?', title: 'Charger', element: <Charger/>},

        //{to:'/'+game+'/logs', path:'logs', title:'ChargerLogs',element:<AllCmChargerLogs />},
        //{to:'reports', title:'Reports',component:'<Reports />'},

    ]
    const common_menu = [
        {
            to: '/' + game + '/prefabs/' + deviceId + '?vendorId=' +vendorId,
            path: "prefabs/:deviceId?",
            title: 'Prefabs',
            element: <PrefabsSc game={game}/>
        },
        {
            to: '/' + game + '/archive/' + deviceId + '?vendorId=' +vendorId,
            path: "archive/:deviceId?",
            title: 'Archive',
            element: <ArchiveSC game={game}/>
        },
        {
            to: '/' + game + '/search',
            path: "search",
            title: 'Search',
            element: <AdvancedSearchSC game={game}/>
        },

    ]
    const admin_menu = [
        {
            to: '/' + game + '/chargeManagement',
            path: "chargeManagement",
            title: 'Charge Management',
            element: <ChargeManagement/>
        },
        {
            to: '/' + game + '/whitelistManagement',
            path: "whitelistManagement",
            title: 'White list management',
            element: <WhiteListManagement/>
        },
    ]

    let menu = [
        ...main_menu,
        ...state_menu,
        ...common_menu
    ]


    const routes = (
        <Route>

            {menu.map((object) => (
                <Route path={object.path} element={object.element}/>
            ))}
            {admin_menu.map((object) => (
                <Route path={object.path} element={object.element}/>
            ))}
        </Route>
    );


    return (
        <>
            <div className="wrapper">
                <Drawer
                    sx={{
                        width: 230,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: 230, boxSizing: 'border-box'},

                    }}
                    variant="permanent"
                >
                    <Toolbar/>

                    <MenuList>
                        {menu.map((object) => (
                            <MenuItem
                                component={NavLink}
                                to={object.to}
                                disabled={object.disabled}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                {object.title}
                            </MenuItem>
                        ))}
                        <hr/>
                        {admin_menu.map((object) => (
                            <MenuItem
                                component={NavLink}
                                to={object.to}
                                disabled={object.disabled}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                {object.title}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Drawer>
                <Box component="main"
                     sx={{flexGrow: 1, p: 3}}
                >
                    <Toolbar/>

                    {['chargeManagement', 'whitelistManagement','search'].indexOf(action) == -1 &&
                        <Box display="flex" alignItems="center" sx={{mb: 2}}>
                            <SearchBarSC />
                        </Box>
                    }

                    <Box mb={2}>
                        <Routes>
                            {routes}
                        </Routes>
                    </Box>
                </Box>
            </div>
        </>

    )
}
