import {Card} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {formatDate} from "../../../core/utils/constants";
import {useGetLevelsV1CMQuery} from "../../../core/api/clockmaker-analytical-data-api";
import Loading from "../../../components/Common/Loading";


const columns = [
    {field: 'groupId', headerName: 'Group ID', width: 250},
    {field: 'levelId', headerName: 'Level ID', width: 100},
    {field: 'result', headerName: 'Result', width: 100},
    {field: 'attempts', headerName: 'Attempts', width: 100},
    {field: 'timestamp', headerName: 'Timestamp', width: 200},
    {field: 'version', headerName: 'Version', width: 100}
];

export default function Levels(props) {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        error
    } = useGetLevelsV1CMQuery(props.deviceId)
    //
    // const rows = data!=undefined ? Object.entries(data).map((level => ({
    //     id: level.id.groupId,
    //     groupId: level.id.groupId,
    //     groupName: level.id.groupName,
    //     levelId: level.id.levelId,
    //     result: level.result.toString(),
    //     attempts: level.attempts,
    //     timestamp: formatDate(level.timestamp),
    //     version: level.version
    // }))) : [];

    return (<>
        <Card>
            <Loading isLoading={isLoading||isFetching} error={error} isError={isError}>
                {data!=undefined && <DataGrid
                    getRowId={(row) => row.itemId+row.timestamp}
                    rowHeight={82}
                    autoHeight={true}
                    rows={data}
                    pagination={true}
                    columns={columns}/>
                }
            </Loading>
        </Card>
    </>);
}